import {AbstractContent} from './AbstractContent';
import {
  AUTOPILOT_PRESTART_TIME,
  CLOCK_TICKING,
  Constants,
  FIXED_SECTION_TYPE,
  ICaption,
  ILanguageParams,
  IReleaseVersion,
  SECTION_EVENT_PHASE,
  SMILES_RATING_SETS,
  SMILES_RATING_SETS_NAMES,
  TCaption,
  VIRTUAL_CONFERENCE_EVENT
} from '../../core/constants';
import {FeedbackRating, FollowMeSettings, VirtualConferenceSettings} from '../event-mode/InstantSettings';
import {IMeeting} from '../meeting/Meeting';
import {RowTask} from '../event-mode/RowDatesObject';
import {DependencyQuestionnaire, IDependencyAnswers} from './DependencyQuestionnaire';
import {isEmpty} from 'lodash';
import {UtilsService} from '../../core/utils.service';

export interface ISectionSubject {
  name: string;
  shortName: string;
}

export interface ISectionSubtarget {
  id: string;
  level: number;
  name: string;
  order: string;
  orderNum: number;
}

export interface ISectionSubtargetSummary {
  [targetId: string]: {
    value: number,
    progress: string,
    unprogress: string
  };
}

export class SectionContent extends AbstractContent {
  public static readonly DB_PATH = 'timeline';
  public static readonly DB_NOTES_PATH = 'notes';
  public static readonly SHORTCUT_FIELDS = [
    'id',
    'parentId',
    'orderIndex',
    'education',
    'sectionTimeIsNotActive',
    'duration',
    'durationFixed',
    'durationFixedValue',
    'originId',
    'isPublic',
    'status'
  ];

  type = 'section';
  expand: boolean;
  location: string;
  container: boolean;
  users: SectionUser[];
  multilingualTitle: ICaption;
  description: string;
  multilingualDescription: ICaption;
  subtitle: string;
  multilingualSubtitle: ICaption;
  enabledLanguages: string[];
  showTitle: boolean;
  status = Constants.SECTION_STATUS_OPEN;
  autocounter: boolean;
  requiredRegistration: boolean;
  sectionTypeId: string;
  readonlyHKVBS: number;
  hkvbsIntegrationId: string;
  fixedSectionType: FIXED_SECTION_TYPE;

  plannedTime: number;
  duration: number;

  plannedTimeFixed: boolean;
  plannedTimeFixedValue: number;
  durationFixed: boolean;
  durationFixedValue: number;

  endTime: number;
  endTimeFixed: boolean;
  endTimeFixedValue: number;
  sectionTimeIsNotActive: boolean;
  sectionEventPhase: SECTION_EVENT_PHASE;
  // fields for "Self-learning" mode
  isSelfLearningSection: boolean;
  fixedOrder: boolean;
  excludeFromAutopilot: boolean;
  autopilotEnableCountDown = false;
  autopilotEnableBigCountDown = false;
  autopilotClockTicking: CLOCK_TICKING = CLOCK_TICKING.MIN2;
  autopilotPrestartTime: AUTOPILOT_PRESTART_TIME = AUTOPILOT_PRESTART_TIME.OFF;
  exclusivityGroup: number;
  isInstantGroup: boolean;
  restrictAccessMembers: boolean; // property for isInstantGroup mode enable
  backgroundImage: string;
  followMeSettings: FollowMeSettings = new FollowMeSettings();
  includeSectionInRegistrationForm = true;
  autostartVideoConferencesForParticipantsOnCurrentTime = true;
  prestartSession = false;
  markedAsDone = false;
  /** The main switch that shows whether videoconferencing is enabled or disabled for this section.
   * @see VirtualConferenceSettings */
  specificVideoConferences = false;
  /** @param {VirtualConferenceSettings} virtualConferenceSettings - video conference settings */
  virtualConferenceSettings: VirtualConferenceSettings;
  meeting: IMeeting;
  specificQA: boolean;
  qaModerationReview: boolean;
  feedbackRating: FeedbackRating;
  dependencyQuestionnaire: DependencyQuestionnaire;
  hideSectionSlide: boolean;
  // education fields
  education = false;
  originId: string;
  freeSlotType: boolean;
  deleted: boolean;
  llp: number;
  clazz: number;
  self: number;
  subjects: ISectionSubject[];
  subtargets: ISectionSubtarget[];
  releaseVersion: IReleaseVersion;
  archive: boolean;
  shortcuts: string[];


  constructor(obj: any) {
    super(obj);
    if (!obj) {
      return;
    }

    if (obj.hasOwnProperty('expand')) {
      this.expand = obj.expand;
    }
    if (obj.hasOwnProperty('location')) {
      this.location = obj.location;
    }
    if (obj.hasOwnProperty('users')) {
      this.users = obj.users;
    }
    if (obj.hasOwnProperty('description')) {
      this.description = obj.description;
    }
    if (obj.hasOwnProperty('container')) {
      this.container = obj.container;
    }
    if (obj.hasOwnProperty('showTitle')) {
      this.showTitle = obj.showTitle;
    }
    if (obj.hasOwnProperty('followMeSettings')) {
      this.followMeSettings = new FollowMeSettings(obj.followMeSettings);
    }
    if (obj.status) {
      this.status = obj.status;
    }
    if (obj.hasOwnProperty('autocounter')) {
      this.autocounter = obj.autocounter;
    }
    if (obj.hasOwnProperty('requiredRegistration')) {
      this.requiredRegistration = obj.requiredRegistration;
    }
    if (obj.hasOwnProperty('sectionTypeId')) {
      this.sectionTypeId = obj.sectionTypeId;
    }
    if (obj.hasOwnProperty('readonlyHKVBS')) {
      this.readonlyHKVBS = obj.readonlyHKVBS;
    }
    if (obj.hasOwnProperty('hkvbsIntegrationId')) {
      this.hkvbsIntegrationId = obj.hkvbsIntegrationId;
    }
    if (obj.hasOwnProperty('fixedSectionType')) {
      this.fixedSectionType = obj.fixedSectionType;
    }
    if (obj.hasOwnProperty('plannedTime')) {
      this.plannedTime = obj.plannedTime;
    }
    if (obj.hasOwnProperty('duration')) {
      this.duration = obj.duration;
    }
    if (obj.hasOwnProperty('plannedTimeFixed')) {
      this.plannedTimeFixed = obj.plannedTimeFixed;
    }
    if (obj.hasOwnProperty('plannedTimeFixedValue')) {
      this.plannedTimeFixedValue = obj.plannedTimeFixedValue;
    }
    if (obj.hasOwnProperty('durationFixed')) {
      this.durationFixed = obj.durationFixed;
    }
    if (obj.hasOwnProperty('durationFixedValue')) {
      this.durationFixedValue = obj.durationFixedValue;
    }
    if (obj.hasOwnProperty('endTime')) {
      this.endTime = obj.endTime;
    }
    if (obj.hasOwnProperty('endTimeFixed')) {
      this.endTimeFixed = obj.endTimeFixed;
    }
    if (obj.hasOwnProperty('endTimeFixedValue')) {
      this.endTimeFixedValue = obj.endTimeFixedValue;
    }
    if (obj.hasOwnProperty('sectionTimeIsNotActive')) {
      this.sectionTimeIsNotActive = obj.sectionTimeIsNotActive;
    }
    if (obj.hasOwnProperty('sectionEventPhase')) {
      this.sectionEventPhase = obj.sectionEventPhase;
    }
    if (obj.hasOwnProperty('isSelfLearningSection')) {
      this.isSelfLearningSection = obj.isSelfLearningSection;
    }
    if (obj.hasOwnProperty('fixedOrder')) {
      this.fixedOrder = obj.fixedOrder;
    }
    if (obj.hasOwnProperty('excludeFromAutopilot')) {
      this.excludeFromAutopilot = obj.excludeFromAutopilot;
    }
    if (obj.hasOwnProperty('subtitle')) {
      this.subtitle = obj.subtitle;
    }
    if (obj.hasOwnProperty('exclusivityGroup')) {
      this.exclusivityGroup = obj.exclusivityGroup;
    }
    if (obj.hasOwnProperty('isInstantGroup')) {
      this.isInstantGroup = obj.isInstantGroup;
    }
    if (obj.hasOwnProperty('restrictAccessMembers')) {
      this.restrictAccessMembers = obj.restrictAccessMembers;
    }
    if (obj.hasOwnProperty('meeting')) {
      this.meeting = obj.meeting;
    }
    if (obj.hasOwnProperty('backgroundImage')) {
      this.backgroundImage = obj.backgroundImage;
    }
    if (obj.hasOwnProperty('includeSectionInRegistrationForm')) {
      this.includeSectionInRegistrationForm = obj.includeSectionInRegistrationForm;
    }
    if (obj.hasOwnProperty('specificVideoConferences')) {
      this.specificVideoConferences = obj.specificVideoConferences;
    }
    if (obj.hasOwnProperty('autostartVideoConferencesForParticipantsOnCurrentTime')) {
      this.autostartVideoConferencesForParticipantsOnCurrentTime = obj.autostartVideoConferencesForParticipantsOnCurrentTime;
    }
    if (obj.hasOwnProperty('prestartSession')) {
      this.prestartSession = obj.prestartSession;
    }
    if (obj.hasOwnProperty('virtualConferenceSettings')) {
      this.virtualConferenceSettings = new VirtualConferenceSettings(obj.virtualConferenceSettings);
    }
    if (obj.hasOwnProperty('feedbackRating')) {
      this.feedbackRating = new FeedbackRating(obj.feedbackRating);
    } else if (!obj.hasOwnProperty('feedbackRating')) {
      this.feedbackRating = new FeedbackRating({activateFeedbackRating: true});
    }
    if (obj.hasOwnProperty('autopilotEnableCountDown')) {
      this.autopilotEnableCountDown = obj.autopilotEnableCountDown;
    }
    if (obj.hasOwnProperty('autopilotEnableBigCountDown')) {
      this.autopilotEnableBigCountDown = obj.autopilotEnableBigCountDown;
    }
    if (obj.hasOwnProperty('autopilotClockTicking')) {
      this.autopilotClockTicking = obj.autopilotClockTicking;
    }
    if (obj.hasOwnProperty('autopilotPrestartTime')) {
      this.autopilotPrestartTime = obj.autopilotPrestartTime;
    }
    if (obj.hasOwnProperty('specificQA')) {
      this.specificQA = obj.specificQA;
    }
    if (obj.hasOwnProperty('qaModerationReview')) {
      this.qaModerationReview = obj.qaModerationReview;
    }
    if (obj.hasOwnProperty('education')) {
      this.education = obj.education;
    }
    if (obj.hasOwnProperty('originId')) {
      this.originId = obj.originId;
    }
    if (obj.hasOwnProperty('freeSlotType')) {
      this.freeSlotType = obj.freeSlotType;
    }
    if (obj.hasOwnProperty('deleted')) {
      this.deleted = obj.deleted;
    }
    if (obj.hasOwnProperty('llp')) {
      this.llp = obj.llp;
    }
    if (obj.hasOwnProperty('clazz')) {
      this.clazz = obj.clazz;
    }
    if (obj.hasOwnProperty('self')) {
      this.self = obj.self;
    }
    if (obj.hasOwnProperty('hideSectionSlide')) {
      this.hideSectionSlide = obj.hideSectionSlide;
    }
    if (obj.hasOwnProperty('dependencyQuestionnaire')) {
      this.dependencyQuestionnaire =
        !isEmpty(obj.dependencyQuestionnaire) ? new DependencyQuestionnaire(obj.dependencyQuestionnaire) : null;
    }
    if (obj.hasOwnProperty('multilingualTitle')) {
      this.multilingualTitle = obj.multilingualTitle;
    }
    if (obj.hasOwnProperty('multilingualSubtitle')) {
      this.multilingualSubtitle = obj.multilingualSubtitle;
    }
    if (obj.hasOwnProperty('enabledLanguages')) {
      this.enabledLanguages = obj.enabledLanguages;
    }
    if (obj.hasOwnProperty('multilingualDescription')) {
      this.multilingualDescription = obj.multilingualDescription;
    }
    if (obj.hasOwnProperty('subjects')) {
      this.subjects = obj.subjects;
    }
    if (obj.hasOwnProperty('subtargets')) {
      this.subtargets = obj.subtargets;
    }
    if (obj.hasOwnProperty('releaseVersion')) {
      this.releaseVersion = obj.releaseVersion;
    }
    if (obj.hasOwnProperty('archive')) {
      this.archive = obj.archive;
    }
    if (obj.hasOwnProperty('markedAsDone')) {
      this.markedAsDone = obj.markedAsDone;
    }
    if (obj.hasOwnProperty('shortcuts')) {
      this.shortcuts = obj.shortcuts;
    }
  }

  mergeContent(src: SectionContent, notMergeExpand?) {
    super.mergeContent(src);

    this.users = src.users;

    if (!notMergeExpand && this.expand !== src.expand) {
      this.expand = src.expand;
    }

    if (this.location !== src.location) {
      this.location = src.location;
    }

    if (this.description !== src.description) {
      this.description = src.description;
    }

    if (this.container !== src.container) {
      this.container = src.container;
    }

    if (this.showTitle !== src.showTitle) {
      this.showTitle = src.showTitle;
    }

    if (this.followMeSettings !== src.followMeSettings) {
      this.followMeSettings = src.followMeSettings;
    }

    if (this.status !== src.status) {
      this.status = src.status;
    }

    if (this.autocounter !== src.autocounter) {
      this.autocounter = src.autocounter;
    }

    if (this.requiredRegistration !== src.requiredRegistration) {
      this.requiredRegistration = src.requiredRegistration;
    }

    if (this.sectionTypeId !== src.sectionTypeId) {
      this.sectionTypeId = src.sectionTypeId;
    }

    if (this.plannedTime !== src.plannedTime) {
      this.plannedTime = src.plannedTime;
    }
    if (this.duration !== src.duration) {
      this.duration = src.duration;
    }
    if (this.plannedTimeFixed !== src.plannedTimeFixed) {
      this.plannedTimeFixed = src.plannedTimeFixed;
    }
    if (this.plannedTimeFixedValue !== src.plannedTimeFixedValue) {
      this.plannedTimeFixedValue = src.plannedTimeFixedValue;
    }
    if (this.durationFixed !== src.durationFixed) {
      this.durationFixed = src.durationFixed;
    }
    if (this.durationFixedValue !== src.durationFixedValue) {
      this.durationFixedValue = src.durationFixedValue;
    }
    if (this.endTime !== src.endTime) {
      this.endTime = src.endTime;
    }

    if (this.endTimeFixed !== src.endTimeFixed) {
      this.endTimeFixed = src.endTimeFixed;
    }

    if (this.endTimeFixedValue !== src.endTimeFixedValue) {
      this.endTimeFixedValue = src.endTimeFixedValue;
    }

    if (this.sectionTimeIsNotActive !== src.sectionTimeIsNotActive) {
      this.sectionTimeIsNotActive = src.sectionTimeIsNotActive;
    }

    if (this.sectionEventPhase !== src.sectionEventPhase) {
      this.sectionEventPhase = src.sectionEventPhase;
    }

    if (this.isSelfLearningSection !== src.isSelfLearningSection) {
      this.isSelfLearningSection = src.isSelfLearningSection;
    }

    if (this.fixedOrder !== src.fixedOrder) {
      this.fixedOrder = src.fixedOrder;
    }

    if (this.excludeFromAutopilot !== src.excludeFromAutopilot) {
      this.excludeFromAutopilot = src.excludeFromAutopilot;
    }

    if (this.subtitle !== src.subtitle) {
      this.subtitle = src.subtitle;
    }

    if (this.exclusivityGroup !== src.exclusivityGroup) {
      this.exclusivityGroup = src.exclusivityGroup;
    }

    if (this.isInstantGroup !== src.isInstantGroup) {
      this.isInstantGroup = src.isInstantGroup;
    }

    if (this.restrictAccessMembers !== src.restrictAccessMembers) {
      this.restrictAccessMembers = src.restrictAccessMembers;
    }

    if (this.meeting !== src.meeting) {
      this.meeting = src.meeting;
    }

    if (this.backgroundImage !== src.backgroundImage) {
      this.backgroundImage = src.backgroundImage;
    }

    if (this.includeSectionInRegistrationForm !== src.includeSectionInRegistrationForm) {
      this.includeSectionInRegistrationForm = src.includeSectionInRegistrationForm;
    }

    if (this.specificVideoConferences !== src.specificVideoConferences) {
      this.specificVideoConferences = src.specificVideoConferences;
    }

    if (this.autostartVideoConferencesForParticipantsOnCurrentTime !== src.autostartVideoConferencesForParticipantsOnCurrentTime) {
      this.autostartVideoConferencesForParticipantsOnCurrentTime = src.autostartVideoConferencesForParticipantsOnCurrentTime;
    }

    if (this.prestartSession !== src.prestartSession) {
      this.prestartSession = src.prestartSession;
    }

    if (this.virtualConferenceSettings !== src.virtualConferenceSettings) {
      this.virtualConferenceSettings = src.virtualConferenceSettings;
    }

    if (this.feedbackRating !== src.feedbackRating) {
      this.feedbackRating = src.feedbackRating;
    }

    if (this.autopilotEnableCountDown !== src.autopilotEnableCountDown) {
      this.autopilotEnableCountDown = src.autopilotEnableCountDown;
    }

    if (this.autopilotEnableBigCountDown !== src.autopilotEnableBigCountDown) {
      this.autopilotEnableBigCountDown = src.autopilotEnableBigCountDown;
    }

    if (this.autopilotClockTicking !== src.autopilotClockTicking) {
      this.autopilotClockTicking = src.autopilotClockTicking;
    }

    if (this.autopilotPrestartTime !== src.autopilotPrestartTime) {
      this.autopilotPrestartTime = src.autopilotPrestartTime;
    }

    if (this.specificQA !== src.specificQA) {
      this.specificQA = src.specificQA;
    }

    if (this.qaModerationReview !== src.qaModerationReview) {
      this.qaModerationReview = src.qaModerationReview;
    }

    if (this.education !== src.education) {
      this.education = src.education;
    }

    if (this.originId !== src.originId) {
      this.originId = src.originId;
    }

    if (this.freeSlotType !== src.freeSlotType) {
      this.freeSlotType = src.freeSlotType;
    }

    if (this.deleted !== src.deleted) {
      this.deleted = src.deleted;
    }

    if (this.llp !== src.llp) {
      this.llp = src.llp;
    }

    if (this.clazz !== src.clazz) {
      this.clazz = src.clazz;
    }

    if (this.self !== src.self) {
      this.self = src.self;
    }

    if (this.subjects !== src.subjects) {
      this.subjects = src.subjects;
    }

    if (this.subtargets !== src.subtargets) {
      this.subtargets = src.subtargets;
    }

    if (this.hideSectionSlide !== src.hideSectionSlide) {
      this.hideSectionSlide = src.hideSectionSlide;
    }
    if (this.dependencyQuestionnaire !== src.dependencyQuestionnaire) {
      this.dependencyQuestionnaire = new DependencyQuestionnaire(src.dependencyQuestionnaire);
    }
    if (!(isEmpty(this.releaseVersion) && isEmpty(src.hideSectionSlide))) {
      this.releaseVersion = src.releaseVersion;
    }
    if (!(!this.archive && !src.archive)) {
      this.archive = src.archive;
    }
    this.shortcuts = src.shortcuts;
    this.multilingualTitle = src.multilingualTitle;
    this.multilingualSubtitle = src.multilingualSubtitle;
    this.multilingualDescription = src.multilingualDescription;
    this.enabledLanguages = src.enabledLanguages;
  }

  hasUserAccess(userId): boolean {
    if (this.isPublic) {return true; }
    if (!this.users) {return false; }
    const access = this.users.find(user => user.userId === userId);
    return !!access;
  }

  userAccessSpeaker(userId) {
    if (!this.users) {return false; }
    const access = this.users.find(user => user.userId === userId);
    return access ? access.speaker : false;
  }

  /**
   * Return true if section have speakers.
   * Use for dispaly true align at timeline tree.
   * @param userId
   * @returns {boolean}
   */
  get hasSpeakers() {
    return this.speakerIdList.length > 0;
  }

  get speakerIdList() {
    return this.users ? this.users.filter(user => user.speaker).map(user => user.userId) : [];
  }

  get isRoot() {
    return this.id && (!this.parentId || this.title === 'root');
  }

  get isNull() {
    return !this.id;
  }

  get showSectionTitle() {
    if (this.container && this.showTitle) {
      return true;
    } else if (this.container && !this.showTitle) {
      return false;
    } else {
      return true;
    }
  }

  get isStatusPlanned() {
    return this.status === Constants.SECTION_STATUS_PLANNED;
  }

  get isStatusDraft() {
    return this.status === Constants.SECTION_STATUS_DRAFT;
  }

  get isStatusOpen() {
    return this.status === Constants.SECTION_STATUS_OPEN;
  }

  /**
   * Section duration in milliseconds, always return not null value, if value null - return 0.
   */
  durationTime(): number {
    return this.duration ? this.duration * Constants.ONE_MINUTE_MS : 0;
  }

  isFixed() {
    return !!this.fixedSectionType;
  }

  isInstantGroupSection() {
    return this.isInstantGroup && this.container;
  }

  isInstantGroupRestrictAccessMembers() {
    return this.isInstantGroup && this.container && this.restrictAccessMembers;
  }

  virtualConferenceIsOn() {
    return this.specificVideoConferences && this.virtualConferenceSettings &&
      this.virtualConferenceSettings.mainEventOption !== VIRTUAL_CONFERENCE_EVENT.NO_CONFERENCE;
  }

  toObject() {
    const checkUnsupportedValue = (object) => {
      Object.keys(object)
        .forEach(key => {
          if (key.toLowerCase() === 'dependencyQuestionnaire'.toLowerCase() &&
            object.hasOwnProperty(key) && isEmpty(object[key])) {
            object[key] = null;
          } else if (key.toLowerCase() === 'backgroundImage'.toLowerCase() &&
            object.hasOwnProperty(key) && isEmpty(object[key])) {
            object[key] = null;
          } else if ((object[key] === undefined || object[key] === null || typeof object[key] === 'function')) {
            delete object[key];
          } else if (typeof object[key] === 'object') {
            if (typeof object[key]['toObject'] !== 'undefined') {
              object[key] = object[key].toObject();
            } else {
              checkUnsupportedValue(object[key]);
            }
          }
        });
    };
    const obj = super.toObject();
    obj.type = 'section';
    checkUnsupportedValue(obj);
    return obj;
  }

  toJSONString(): string {
    const flattenObject = (ob) => {
      const toReturn = {};

      for (const i in ob) {
        if (!ob.hasOwnProperty(i)) { continue; }

        if ((typeof ob[i]) === 'object') {
          const flatObject = flattenObject(ob[i]);
          for (const x in flatObject) {
            if (!flatObject.hasOwnProperty(x)) { continue; }

            toReturn[i + '.' + x] = flatObject[x];
          }
        } else {
          toReturn[i] = ob[i];
        }
      }
      return toReturn;
    };

    const object = flattenObject(this);
    return JSON.stringify(object, Object.keys(object).sort());
  }

  plannedDay() {
    if (!this.plannedTime) {
      return null;
    }
    const d = new Date(this.plannedTime);
    return new Date(d.getFullYear(), d.getMonth(), d.getDate()).getTime();
  }

  getTaskForUpdateManageTime(withOrderIndex: boolean): any[] {
    const task: RowTask[] = [];
    // plannedTime
    task.push({
      sectionId: this.id,
      fieldName: 'plannedTime',
      value: this.plannedTime,
      type: 'section'
    });
    task.push({
      sectionId: this.id,
      fieldName: 'plannedTimeFixed',
      value: null,
      valueBoolean: this.plannedTimeFixed,
      type: 'section'
    });
    task.push({
      sectionId: this.id,
      fieldName: 'plannedTimeFixedValue',
      value: this.plannedTime,
      type: 'section'
    });
    // duration
    task.push({
      sectionId: this.id,
      fieldName: 'duration',
      value: this.duration,
      type: 'section'
    });
    task.push({
      sectionId: this.id,
      fieldName: 'durationFixed',
      value: null,
      valueBoolean: this.durationFixed,
      type: 'section'
    });
    task.push({
      sectionId: this.id,
      fieldName: 'durationFixedValue',
      value: this.duration,
      type: 'section'
    });
    // endTime
    task.push({
      sectionId: this.id,
      fieldName: 'endTime',
      value: this.endTime,
      type: 'section'
    });
    task.push({
      sectionId: this.id,
      fieldName: 'endTimeFixed',
      value: null,
      valueBoolean: this.endTimeFixed,
      type: 'section'
    });
    task.push({
      sectionId: this.id,
      fieldName: 'endTimeFixedValue',
      value: this.endTime,
      type: 'section'
    });
    // withOrderIndex
    if (withOrderIndex) {
      task.push({
        sectionId: this.id,
        fieldName: 'orderIndex',
        value: null,
        valueDouble: this.orderIndex,
        type: 'section'
      });
    }
    return task;
  }

  getId() {
    return this.isAttached() ? this.originId : this.id;
  }

  isAttached() {
    return !this.education && this.originId && !isEmpty(this.shortcuts) && this.shortcuts.includes(this.id);
  }

  getDependencyVisibility(dependencyAnswers: IDependencyAnswers) {
    if (this.dependencyQuestionnaire && this.dependencyQuestionnaire.questionnaireId) {
      const questionnaireId = this.dependencyQuestionnaire.contentId ?
        `${this.dependencyQuestionnaire.contentId}-${this.dependencyQuestionnaire.questionnaireId}` :
        `${this.dependencyQuestionnaire.questionnaireId}`;
      const answers = !isEmpty(dependencyAnswers) &&
      !isEmpty(dependencyAnswers[questionnaireId]) &&
      !isEmpty(dependencyAnswers[questionnaireId][this.dependencyQuestionnaire.questionId]) ?
        dependencyAnswers[questionnaireId][this.dependencyQuestionnaire.questionId] : {};
      if (this.dependencyQuestionnaire.dependencyConditionShowIf) {
        return this.dependencyQuestionnaire.dependencyResult(answers);
      } else
      if (this.dependencyQuestionnaire.dependencyConditionHideIf) {
        return !this.dependencyQuestionnaire.dependencyResult(answers);
      }
    }
    return true;
  }

  getTitleByLanguage(languageParams: ILanguageParams) {
    return UtilsService.getByMultilingual(this, 'title', languageParams);
  }

  setTitleByLanguage(value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByMultilingual(this, 'title', value, languageParams);
  }

  getSubtitleByLanguage(languageParams: ILanguageParams) {
    return UtilsService.getByMultilingual(this, 'subtitle', languageParams);
  }

  setSubtitleByLanguage(value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByMultilingual(this, 'subtitle', value, languageParams);
  }

  getDescriptionByLanguage(languageParams: ILanguageParams) {
    return UtilsService.getByMultilingual(this, 'description', languageParams);
  }

  setDescriptionByLanguage(value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByMultilingual(this, 'description', value, languageParams);
  }

  getSubtargetsSummaryAchievedRating(summaryData: {[targetId: string]: {[smile: string]: number}}) {
    const smileCost = (smile: string, smileSet: SMILES_RATING_SETS_NAMES): number => {
      const cost = SMILES_RATING_SETS[smileSet].findIndex(s => s === smile);
      return cost < 0 ? 0 : cost * 10;
    };
    const targetsResult: ISectionSubtargetSummary = {};

    if (isEmpty(this.subtargets) || isEmpty(summaryData)) {
      return targetsResult;
    }
    this.subtargets.forEach(t => {
      targetsResult[t.order] = {
        value: 0,
        progress: '0',
        unprogress: '100%'
      };
    });
    const smilesLenght = SMILES_RATING_SETS[SMILES_RATING_SETS_NAMES.SET5].length;
    for (const targetId of Object.keys(summaryData)) {
      const smilesSummary: {[smile: string]: number} = summaryData[targetId];
      let smilesRating = 0;
      let smilesCount = 0;
      for (const smile of Object.keys(smilesSummary)) {
        smilesCount += smilesSummary[smile];
        smilesRating += smileCost(smile, SMILES_RATING_SETS_NAMES.SET5) * smilesSummary[smile];
      }
      const value = smilesCount > 0 ? Math.round(smilesRating / smilesCount) / 10 : 0;
      targetsResult[targetId].value = value;
      targetsResult[targetId].progress = (value !== 0 ? value * 100 / smilesLenght : 0) + '%';
      targetsResult[targetId].unprogress = (value !== 0 ? (100 - (value * 100 / smilesLenght)) : 100) + '%';
    }
    return targetsResult;
  }
}


export class SectionUser {
  userId: string = null;
  attendee = false;
  speaker = false;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    if (obj.hasOwnProperty('userId')) {
      this.userId = obj.userId;
    }
    if (obj.hasOwnProperty('attendee')) {
      this.attendee = obj.attendee;
    }
    if (obj.hasOwnProperty('speaker')) {
      this.speaker = obj.speaker;
    }
  }

  setValues(obj) {
    this.userId = obj.userId;
    this.speaker = obj.speaker;
    this.attendee = obj.attendee;
    return this;
  }

  compare(src: SectionUser) {
    if (this.attendee !== src.attendee) {
      return true;
    } else
    if (this.speaker !== src.speaker) {
      return true;
    }
    return false;
  }

  toObject() {
    return {...this};
  }
}

