import { AbstractContent } from './AbstractContent';
import { Constants, ICaption, ILanguageParams, TCaption } from '../../core/constants';
import { DependencyQuestionnaire, IDependencyAnswers } from './DependencyQuestionnaire';
import { isEmpty, merge } from 'lodash';
import { UtilsService } from '../../core/utils.service';
import { EventQuestion } from '../EventQuestion';
import { TextNode } from '../../modules/content-container/components/text-note/text-note-model/text-note-model';
import { DEFAULT_SETTINGS, IGallerySettings } from '../../modules/content-container/components/picture-gallery/picture-gallery-model/picture-gallery-model';

export enum CONTAINER_ITEM_TYPE {
  IMAGE = 'image',
  TEXT = 'text',
  VIDEO = 'video',
  PDF = 'pdf',
  QUIZ = 'quiz',
  ZOOMIFY = 'zoomify',
  SKETCHFAB = 'sketchfab',
  EASYZOOM = 'easyzoom',
  TASK = 'task',
  HTML = 'html',
  HIGHLIGHT = 'highlight',
  EMBEDDED_FRAME = 'embedded_frame'
}

export const CONTENT_CONTAINER_COLLECTIONS = ['drawn-metadata', 'pins-metadata'];

export interface IContainerItemRect {
  top: number;
  height: number;
  left: number;
  width: number;
  startColumn: number;
  columnLength: number;
}

export interface ILanguageOptions {
  fontSize: string;
  wordBreak?: string;
}

export interface IContainerItemLanguageOptions {
  [language: string]: ILanguageOptions;
}

export interface IContainerItemOptions {
  backgroundColor?: string;
  hideFromSingleFullscreen?: boolean;
  languageOptions: IContainerItemLanguageOptions;
  animateNumber?: number;
}

const DEFAULT_BORDER_RADIUS = '24px';

export class ContentContainer extends AbstractContent {
  public static readonly DB_PATH = 'containers';

  type = Constants.CONTENT_TYPE_CONTENT_CONTAINER;
  showTitleInTabOnly: boolean;
  description: TCaption;
  items: ContentContainerItem[] = [];
  dependencyQuestionnaire: DependencyQuestionnaire;
  singleMode: boolean;
  animate = false;
  itemsTypes: string[] = []; // array of items types
  // set true after convert content from format v.14 to v.15
  // reset to false after first edit this content.
  verticalAlignByColumns: boolean;
  multilingualTitle: ICaption;
  multilingualDescription: ICaption;
  enabledLanguages: string[];

  // my notes fields
  originalEventId: string;
  originalParentId: string;
  originalId: string;
  generated = false;

  // version control system for modules
  releaseVersion: string;
  dirty: boolean;

  // container position grid format 12/24. if value equal null then 12.
  itemsColumnFormat: number;

  constructor (obj: any) {
    super(obj);
    if (obj.hasOwnProperty('description')) {
      this.description = obj.description;
    }
    if (obj.hasOwnProperty('items')) {
      this.items = (obj.items || []).map(o => new ContentContainerItem(o));
    }
    if (obj.hasOwnProperty('dependencyQuestionnaire')) {
      this.dependencyQuestionnaire =
        !isEmpty(obj.dependencyQuestionnaire) ? new DependencyQuestionnaire(obj.dependencyQuestionnaire) : null;
    }
    if (obj.hasOwnProperty('showTitleInTabOnly')) {
      this.showTitleInTabOnly = obj.showTitleInTabOnly;
    }
    if (obj.hasOwnProperty('singleMode')) {
      this.singleMode = obj.singleMode;
    }
    if (obj.hasOwnProperty('animate')) {
      this.animate = obj.animate;
    }
    if (obj.hasOwnProperty('itemsTypes')) {
      this.itemsTypes = obj.itemsTypes;
    }
    if (obj.hasOwnProperty('verticalAlignByColumns')) {
      this.verticalAlignByColumns = obj.verticalAlignByColumns;
    }
    if (obj.hasOwnProperty('multilingualTitle')) {
      this.multilingualTitle = obj.multilingualTitle;
    }
    if (obj.hasOwnProperty('multilingualDescription')) {
      this.multilingualDescription = obj.multilingualDescription;
    }
    if (obj.hasOwnProperty('enabledLanguages')) {
      this.enabledLanguages = obj.enabledLanguages;
    }
    if (obj.hasOwnProperty('originalEventId')) {
      this.originalEventId = obj.originalEventId;
    }
    if (obj.hasOwnProperty('originalParentId')) {
      this.originalParentId = obj.originalParentId;
    }
    if (obj.hasOwnProperty('originalId')) {
      this.originalId = obj.originalId;
    }
    if (obj.hasOwnProperty('generated')) {
      this.generated = obj.generated;
    }
    if (obj.hasOwnProperty('releaseVersion')) {
      this.releaseVersion = obj.releaseVersion;
    }
    if (obj.hasOwnProperty('dirty')) {
      this.dirty = obj.dirty;
    }
    if (obj.hasOwnProperty('itemsColumnFormat')) {
      this.itemsColumnFormat = obj.itemsColumnFormat;
    }
  }

  mergeContent(src: ContentContainer) {
    super.mergeContent(src);
    if (this.description !== src.description) {
      this.description = src.description;
    }
    if (this.dependencyQuestionnaire !== src.dependencyQuestionnaire) {
      this.dependencyQuestionnaire = new DependencyQuestionnaire(src.dependencyQuestionnaire);
    }
    if (this.showTitleInTabOnly !== src.showTitleInTabOnly) {
      this.showTitleInTabOnly = src.showTitleInTabOnly;
    }
    if (this.singleMode !== src.singleMode) {
      this.singleMode = src.singleMode;
    }
    if (this.animate !== src.animate) {
      this.animate = src.animate;
    }
    if (this.itemsTypes !== src.itemsTypes) {
      this.itemsTypes = src.itemsTypes;
    }
    if (this.verticalAlignByColumns !== src.verticalAlignByColumns) {
      this.verticalAlignByColumns = src.verticalAlignByColumns;
    }
    if (this.originalEventId !== src.originalEventId) {
      this.originalEventId = src.originalEventId;
    }
    if (this.originalParentId !== src.originalParentId) {
      this.originalParentId = src.originalParentId;
    }
    if (this.originalId !== src.originalId) {
      this.originalId = src.originalId;
    }
    if (this.generated !== src.generated) {
      this.generated = src.generated;
    }
    if (this.releaseVersion !== src.releaseVersion) {
      this.releaseVersion = src.releaseVersion;
    }
    if (this.dirty !== src.dirty) {
      this.dirty = src.dirty;
    }
    if (this.itemsColumnFormat !== src.itemsColumnFormat) {
      this.itemsColumnFormat = src.itemsColumnFormat;
    }
    this.items = (src.items || []).map(o => new ContentContainerItem(o));
    this.multilingualTitle = src.multilingualTitle;
    this.multilingualDescription = src.multilingualDescription;
    this.enabledLanguages = src.enabledLanguages;
  }

  getAliveItems(): ContentContainerItem[] {
    return this.items.filter(it => !it.id.startsWith('-'));
  }

  getItemsTypes(): string[] {
    return this.items.filter(it => !it.id.startsWith('-')).filter(it => !!it.type).map(it => it.type);
  }

  getDependencyVisibility(dependencyAnswers: IDependencyAnswers) {
    if (this.dependencyQuestionnaire && this.dependencyQuestionnaire.questionnaireId) {
      const questionnaireId = this.dependencyQuestionnaire.contentId ?
        `${this.dependencyQuestionnaire.contentId}-${this.dependencyQuestionnaire.questionnaireId}` :
        `${this.dependencyQuestionnaire.questionnaireId}`;
      const answers = !isEmpty(dependencyAnswers) &&
        !isEmpty(dependencyAnswers[questionnaireId]) &&
        !isEmpty(dependencyAnswers[questionnaireId][this.dependencyQuestionnaire.questionId]) ?
        dependencyAnswers[questionnaireId][this.dependencyQuestionnaire.questionId] : {};
      if (this.dependencyQuestionnaire.dependencyConditionShowIf) {
        return this.dependencyQuestionnaire.dependencyResult(answers);
      } else
        if (this.dependencyQuestionnaire.dependencyConditionHideIf) {
          return !this.dependencyQuestionnaire.dependencyResult(answers);
        }
    }
    return true;
  }

  getTitleByLanguage(languageParams: ILanguageParams) {
    return UtilsService.getByMultilingual(this, 'title', languageParams);
  }

  setTitleByLanguage(value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByMultilingual(this, 'title', value, languageParams);
  }

  getDescriptionByLanguage(languageParams: ILanguageParams) {
    return UtilsService.getByMultilingual(this, 'description', languageParams);
  }

  setDescriptionByLanguage(value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByMultilingual(this, 'description', value, languageParams);
  }

  toObject() {
    const checkUnsupportedValue = (object) => {
      Object.keys(object)
        .forEach(key => {
          if (key.toLowerCase() === 'dependencyQuestionnaire'.toLowerCase() &&
            object.hasOwnProperty(key) && isEmpty(object[key])) {
            object[key] = null;
          } else if ((object[key] === undefined || object[key] === null || typeof object[key] === 'function')) {
            delete object[key];
          } else if (typeof object[key] === 'object') {
            if (typeof object[key]['toObject'] !== 'undefined') {
              object[key] = object[key].toObject();
            } else {
              checkUnsupportedValue(object[key]);
            }
          }
        });
    };
    const obj = super.toObject();
    checkUnsupportedValue(obj);
    return obj;
  }

}

export class ContentContainerItem {
  id: string = this.genId();
  type: CONTAINER_ITEM_TYPE;
  rect: IContainerItemRect;
  options: IContainerItemOptions;
  data: any;
  // Settings would only exist in CONTAINER_ITEM_TYPE.IMAGE
  settings?: IGallerySettings = DEFAULT_SETTINGS;

  constructor (obj?) {
    if (!obj) {
      return;
    }
    if (obj.id) {
      this.id = obj.id;
    }
    if (obj.type) {
      this.type = obj.type;
    }
    if (obj.rect) {
      this.rect = obj.rect;
    }
    if (obj.options) {
      this.options = obj.options;
    }
    if (obj.data) {
      this.data = obj.data;
    }
    if (obj.settings) {
      this.settings = obj.settings;
    }
  }

  private genId(): string {
    const dict = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let id = '';
    for (let i = 0; i < 3; i++) {
      const rnd = Math.floor(Math.random() * 25);
      id += dict.charAt(rnd);
    }
    return `${id}${(new Date()).getTime().toString()}`;
  }

  setRect(top: number, height: number, left: number, width: number, startColumn: number, columnLength: number) {
    this.rect = {
      top: top,
      height: height,
      left: left,
      width: width,
      startColumn: startColumn,
      columnLength: columnLength
    };
  }

  getRect() {
    return {
      top: this.rect.top,
      height: this.rect.height,
      left: this.rect.left,
      width: this.rect.width
    };
  }

  setAnimateNumber(value: number) {
    this.options = merge(this.options, {
      animateNumber: value
    });
  }

  clearAnimateNumber() {
    this.options = merge(this.options, {
      animateNumber: null
    });
  }

  setBackground(backgroundColor: string) {
    this.options = merge(this.options, {
      backgroundColor: backgroundColor
    });
  }

  isEmptyBackground() {
    return !this.options?.backgroundColor;
  }

  clearBackground() {
    this.options = merge(this.options, {
      backgroundColor: null
    });
  }

  getBackgroundStyleFromOptions() {
    return this.options?.backgroundColor ? `background-color: ${this.options?.backgroundColor ?? ''};
            border-radius: ${DEFAULT_BORDER_RADIUS}` : '';
  }

  setHideFromSingleFullscreen(value: boolean) {
    this.options = merge(this.options, {
      hideFromSingleFullscreen: value
    });
  }

  setLanguageFontSize(language: string, size: number) {
    const fontSize = size !== 1 ? `${size}em` : '';
    this.options = merge(this.options, {
      languageOptions: {
        [language]: { fontSize }
      }
    });
  }

  getLanguageFontSize(language: string) {
    return this.options && this.options.languageOptions &&
      this.options.languageOptions[language] ? (this.options.languageOptions[language].fontSize ?? '') : '';
  }

  setLanguageWordBreak(language: string, breakAll: boolean) {
    const wordBreak = breakAll ? 'break-all' : '';
    this.options = merge(this.options, {
      languageOptions: {
        [language]: { wordBreak }
      }
    });
  }

  getLanguageWordBreak(language: string) {
    return this.options && this.options.languageOptions &&
      this.options.languageOptions[language] ? (this.options.languageOptions[language].wordBreak ?? '') : '';
  }

  getTranslatedLanguages(itemData?) {
    const data = itemData ?? this.data;

    const notObject = (value) => typeof value === 'string' || typeof value === 'undefined' || value === null;

    const checkQuestion = (q: EventQuestion, lang: string) => {
      const checkName = lang === 'default' ? notObject(q.caption) : !!(q.caption || {})[lang];
      if (!isEmpty(q.items) && isEmpty(q.matchingItems)) {
        return checkName && (q.items || []).every(it => lang === 'default' ? notObject(it.answer) : !!(it.answer || {})[lang]);
      }
      if (!isEmpty(q.items) && !isEmpty(q.matchingItems)) {
        return checkName && (q.items || []).every(it => lang === 'default' ? notObject(it.answer) : !!(it.answer || {})[lang]) &&
          (q.matchingItems || []).every(it => lang === 'default' ? notObject(it.answerMatching) : !!(it.answerMatching || {})[lang]);
      }
      return checkName;
    };

    const quizLang = () => {
      const langs = Object.values((data || {}).questions || {}).reduce((lacc, q: EventQuestion) => {
        const c = notObject(q.caption) ? { default: true } : Object.keys(q.caption).reduce((r, l) => { r[l] = true; return r; }, {});
        const a = (q.items || []).reduce((rv, it) => {
          (notObject(it.answer) ? ['default'] : Object.keys(it.answer)).forEach(l => rv[l] = true);
          return rv;
        }, {});
        const mta = (q.matchingItems || []).reduce((rv, it) => {
          (notObject(it.answerMatching) ? ['default'] : Object.keys(it.answerMatching)).forEach(l => rv[l] = true);
          return rv;
        }, {});
        lacc = merge(lacc, c, a, mta);
        return lacc;
      }, []);
      return Object.keys(langs).reduce((acc, lang) => {
        const questions = Object.values((data || {}).questions || {}) as EventQuestion[];
        if (questions.every(q => checkQuestion(q, lang))) {
          acc.push(lang);
        }
        return acc;
      }, []);
    };

    const highlightLang = () => {
      const langs = (data || []).reduce((acc, it: { id, title: TCaption, description: TCaption; }) => {
        const t = notObject(it.title) ? ['default'] : Object.keys(it.title);
        const d = notObject(it.description) ? ['default'] : Object.keys(it.title);
        t.forEach(l => acc[l] = true);
        d.forEach(l => acc[l] = true);
        return acc;
      }, {});
      return Object.keys(langs).reduce((acc, lang) => {
        if (!isEmpty(data) || data.every(r => langs === 'default' ?
          notObject(r.title) && notObject(r.description) : !!(r.title || {})[lang] && !!(r.description || {})[lang])) {
          acc.push(lang);
        }
        return acc;
      }, []);
    };
    switch (this.type) {
      case CONTAINER_ITEM_TYPE.TEXT:
        const text = TextNode.getText(data);
        return Object.keys((notObject(text) ? { default: true } : text) || {});
      case CONTAINER_ITEM_TYPE.IMAGE:
        return Object.keys((data?.pictures || [])
          .filter(p => !p.id.startsWith('-'))
          .reduce((acc, p) => {
            acc[p.lang ?? 'default'] = true;
            return acc;
          }, {}));
      case CONTAINER_ITEM_TYPE.VIDEO:
        return Object.keys((notObject(data?.url) ? { default: true } : data?.url) || {});
      case CONTAINER_ITEM_TYPE.QUIZ:
        return quizLang();
      case CONTAINER_ITEM_TYPE.HIGHLIGHT:
        return highlightLang();
      default:
        return [];
    }
  }

  toObject() {
    const checkUnsupportedValue = (object) => {
      Object.keys(object)
        .forEach(key => {
          if ((object[key] === undefined || object[key] === null || typeof object[key] === 'function')) {
            delete object[key];
          } else if (typeof object[key] === 'object') {
            if (typeof object[key]['toObject'] !== 'undefined') {
              object[key] = object[key].toObject();
            } else {
              if (Array.isArray(object[key])) {
                if (!isEmpty(object[key]) && object[key].some(v => v === undefined || v === null)) {
                  object[key] = object[key].filter(v => v !== undefined && v !== null);
                }
              }
              checkUnsupportedValue(object[key]);
            }
          }
        });
    };
    const obj = { ...this };
    checkUnsupportedValue(obj);
    return obj;
  }

}
