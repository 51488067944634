import {
  AFTER_RUNNING,
  ANONYMOUS_OPTION,
  ASSIGN_FREE_SLOT_MODE,
  AVAILABLE_MEETING_VIEW_MODE,
  BEFORE_CHANGE_TICKING,
  BEFORE_RUNNING,
  CLOCK_TICKING,
  CONTENT_FROM_AUDIENCE_MODE,
  COUNTDOWN_TIMER,
  FOLLOW_ME_MODE,
  ICaption,
  ILanguageParams,
  MEETING_VIEW_MODE,
  TCaption,
  VIRTUAL_CONFERENCE_EVENT,
  VIRTUAL_CONFERENCE_TYPE
} from '../../core/constants';
import {SocialSettings} from '../content/SocialSettings';
import {ChatSettings} from '../content/ChatSettings';
import {IRoom} from '../../modules/meeting-frame/services/daily-co.constants';
import {UtilsService} from '../../core/utils.service';
import {isEmpty} from 'lodash';

export class EasyContentSettings {
  public anonymously: ANONYMOUS_OPTION = ANONYMOUS_OPTION.ANONYMOUS_ON_CHOICE;

  constructor(obj?) {
    if (!obj) {
      return;
    }
    if (obj.anonymously) {
      this.anonymously = obj.anonymously;
    }
  }

  toObject() {
    return {...this};
  }
}

export class AutopilotSettings {
  autopilotEnable = false;
  countdownTimer: COUNTDOWN_TIMER = COUNTDOWN_TIMER.VISUAL;
  clockTicking: CLOCK_TICKING = CLOCK_TICKING.MIN2;
  beforeChangeTicking: BEFORE_CHANGE_TICKING = BEFORE_CHANGE_TICKING.SEC10;
  playGong = true;
  playBeforeCountdown = true;
  tickingBeforeCountdownEnd = true;
  countdownAvailabilityPresenters = true;
  countdownAvailabilityProjectors = true;
  countdownAvailabilityAudience = false;
  soundAvailabilityPresenters = true;
  soundAvailabilityProjectors = true;
  soundAvailabilityAudience = false;
  statusControl = false;
  beforeRunning: BEFORE_RUNNING = BEFORE_RUNNING.NO_ACTION;
  afterRunning: AFTER_RUNNING = AFTER_RUNNING.NO_ACTION;
  automaticTransitionLevel = 0;

  constructor(obj?) {
    if (!obj) {
      return;
    }
    if (obj.hasOwnProperty('autopilotEnable')) {
      this.autopilotEnable = obj.autopilotEnable;
    }
    if (obj.countdownTimer) {
      this.countdownTimer = obj.countdownTimer;
    }
    if (obj.hasOwnProperty('clockTicking')) {
      this.clockTicking = obj.clockTicking;
    }
    if (obj.hasOwnProperty('playGong')) {
      this.playGong = obj.playGong;
    }
    if (obj.hasOwnProperty('countdownAvailabilityPresenters')) {
      this.countdownAvailabilityPresenters = obj.countdownAvailabilityPresenters;
    }
    if (obj.hasOwnProperty('countdownAvailabilityProjectors')) {
      this.countdownAvailabilityProjectors = obj.countdownAvailabilityProjectors;
    }
    if (obj.hasOwnProperty('countdownAvailabilityAudience')) {
      this.countdownAvailabilityAudience = obj.countdownAvailabilityAudience;
    }
    if (obj.hasOwnProperty('soundAvailabilityPresenters')) {
      this.soundAvailabilityPresenters = obj.soundAvailabilityPresenters;
    }
    if (obj.hasOwnProperty('soundAvailabilityProjectors')) {
      this.soundAvailabilityProjectors = obj.soundAvailabilityProjectors;
    }
    if (obj.hasOwnProperty('soundAvailabilityAudience')) {
      this.soundAvailabilityAudience = obj.soundAvailabilityAudience;
    }
    if (obj.hasOwnProperty('playBeforeCountdown')) {
      this.playBeforeCountdown = obj.playBeforeCountdown;
    }
    if (obj.hasOwnProperty('beforeChangeTicking')) {
      this.beforeChangeTicking = obj.beforeChangeTicking;
    }
    if (obj.hasOwnProperty('tickingBeforeCountdownEnd')) {
      this.tickingBeforeCountdownEnd = obj.tickingBeforeCountdownEnd;
    }
    if (obj.hasOwnProperty('statusControl')) {
      this.statusControl = obj.statusControl;
    }
    if (obj.hasOwnProperty('automaticTransitionLevel')) {
      this.automaticTransitionLevel = obj.automaticTransitionLevel;
    }
    if (obj.beforeRunning) {
      this.beforeRunning = obj.beforeRunning;
    }
    if (obj.afterRunning) {
      this.afterRunning = obj.afterRunning;
    }

  }

  toObject() {
    return {...this};
  }
}

export class AdvancedSettings {
  externalEventFileStore = false;
  linkToFileStore: string = null;
  useAuthenticationCode = false;
  includeInIframe = false;

  constructor(obj?) {
    if (!obj) {
      return;
    }

    if (obj.hasOwnProperty('externalEventFileStore')) {
      this.externalEventFileStore = obj.externalEventFileStore;
    }
    if (obj.linkToFileStore) {
      this.linkToFileStore = obj.linkToFileStore;
    }
    if (obj.hasOwnProperty('useAuthenticationCode')) {
      this.useAuthenticationCode = obj.useAuthenticationCode;
    }
    if (obj.hasOwnProperty('includeInIframe')) {
      this.includeInIframe = obj.includeInIframe;
    }
  }
  toObject() {
    return {...this};
  }
}

export class FollowMeSettings {
  followMeMode: FOLLOW_ME_MODE = FOLLOW_ME_MODE.DONT_FOLLOW;
  restrictParticipantsOnPage = false;
  followAlsoFullScreenChange = true;
  unhideHiddenPagesWhileFollowing = false;
  followQa = false;
  autoAssignPresenterToFollowMe = true;

  constructor(obj?) {
    if (!obj) {
      return;
    }
    if (obj.hasOwnProperty('followMeMode')) {
      this.followMeMode = obj.followMeMode;
    }
    if (obj.hasOwnProperty('restrictParticipantsOnPage')) {
      this.restrictParticipantsOnPage = obj.restrictParticipantsOnPage;
    }
    if (obj.hasOwnProperty('followAlsoFullScreenChange')) {
      this.followAlsoFullScreenChange = obj.followAlsoFullScreenChange;
    }
    if (obj.hasOwnProperty('unhideHiddenPagesWhileFollowing')) {
      this.unhideHiddenPagesWhileFollowing = obj.unhideHiddenPagesWhileFollowing;
    }
    if (obj.hasOwnProperty('followQa')) {
      this.followQa = obj.followQa;
    }
    if (obj.hasOwnProperty('autoAssignPresenterToFollowMe')) {
      this.autoAssignPresenterToFollowMe = obj.autoAssignPresenterToFollowMe;
    }
  }
  toObject() {
    return {...this};
  }
}

export class DailyCoSettings {
  startVideoOff = false;
  startAudioOff = false;
  screenSharing = true;
  liveStreaming = false;
  interactiveLiveStreaming = false;
  rtmpUrl: string;
  streamKey: string;
  playbackUrl: string;
  usePermanentRooms = false;
  permanentRooms: IRoom[];
  availableMetingViewMode: AVAILABLE_MEETING_VIEW_MODE = AVAILABLE_MEETING_VIEW_MODE.CONFERENCE_AND_ROOMS;
  betaFeatures = false;
  enableRecording = false;
  autoRecord = false;

  constructor(obj?) {
    if (!obj) {
      return;
    }
    if (obj.hasOwnProperty('startVideoOff')) {
      this.startVideoOff = obj.startVideoOff;
    }
    if (obj.hasOwnProperty('startAudioOff')) {
      this.startAudioOff = obj.startAudioOff;
    }
    if (obj.hasOwnProperty('screenSharing')) {
      this.screenSharing = obj.screenSharing;
    }
    if (obj.hasOwnProperty('liveStreaming')) {
      this.liveStreaming = obj.liveStreaming;
    }
    if (obj.hasOwnProperty('interactiveLiveStreaming')) {
      this.interactiveLiveStreaming = obj.interactiveLiveStreaming;
    }
    if (obj.hasOwnProperty('rtmpUrl')) {
      this.rtmpUrl = obj.rtmpUrl;
    }
    if (obj.hasOwnProperty('streamKey')) {
      this.streamKey = obj.streamKey;
    }
    if (obj.hasOwnProperty('playbackUrl')) {
      this.playbackUrl = obj.playbackUrl;
    }
    if (obj.hasOwnProperty('usePermanentRooms')) {
      this.usePermanentRooms = obj.usePermanentRooms;
    }
    if (obj.hasOwnProperty('permanentRooms')) {
      this.permanentRooms = obj.permanentRooms;
    }
    if (obj.hasOwnProperty('availableMetingViewMode')) {
      this.availableMetingViewMode = obj.availableMetingViewMode;
    }
    if (obj.hasOwnProperty('betaFeatures')) {
      this.betaFeatures = obj.betaFeatures;
    }
    if (obj.hasOwnProperty('enableRecording')) {
      this.enableRecording = obj.enableRecording;
    }
    if (obj.hasOwnProperty('autoRecord')) {
      this.autoRecord = obj.autoRecord;
    }
  }

  get startVideoOn() {
    return !this.startVideoOff;
  }

  set startVideoOn(value: boolean) {
    this.startVideoOff = !value;
  }

  get startAudioOn() {
    return !this.startAudioOff;
  }

  set startAudioOn(value: boolean) {
    this.startAudioOff = !value;
  }

  get screenSharingOn() {
    return this.screenSharing;
  }

  set screenSharingOn(value: boolean) {
    this.screenSharing = value;
  }

  get liveStreamingOn() {
    return this.liveStreaming;
  }

  set liveStreamingOn(value: boolean) {
    this.liveStreaming = value;
  }

  getMeetingDefaultViewMode() {
    switch (this.availableMetingViewMode) {
      case AVAILABLE_MEETING_VIEW_MODE.BREAKOUT_ROOMS_ONLY:
        return MEETING_VIEW_MODE.BREAKOUT_ROOMS;
      default:
        return MEETING_VIEW_MODE.CONFERENCE_GRID;
    }
  }

  toObject() {
    const checkUnsupportedValue = (object) => {
      Object.keys(object)
        .forEach(key => {
          if ((object[key] === undefined || object[key] === null || typeof object[key] === 'function')) {
            delete object[key];
          } else if (typeof object[key] === 'object') {
            checkUnsupportedValue(object[key]);
          }
        });
    };
    const obj = {...this};
    checkUnsupportedValue(obj);
    return obj;
  }
}

export class RecorderSettings {
  enableSpecificSnippets = false;

  constructor(obj?: RecorderSettings) {
    if (obj) {
      this.enableSpecificSnippets = !!obj.enableSpecificSnippets;
    }
  }
  toObject() {
    return {...this};
  }
}

/**
 * Video conference settings
 * @param {VIRTUAL_CONFERENCE_EVENT} mainEventOption - type of conference {@link VIRTUAL_CONFERENCE_EVENT}
 * @param {VIRTUAL_CONFERENCE_TYPE} typeOfConference - work when mainEventOption = INTEGRATED_LIVE_CONFERENCE {@link VIRTUAL_CONFERENCE_TYPE}
 */
export class VirtualConferenceSettings {
  mainEventOption: VIRTUAL_CONFERENCE_EVENT = VIRTUAL_CONFERENCE_EVENT.INTEGRATED_LIVE_CONFERENCE;
  linkToLiveStreaming: string = null;
  embedVariables: string = null;
  defaultSize: MEETING_VIEW_MODE = MEETING_VIEW_MODE.INTERNAL;
  autoSizeControl = true;
  allowManualSizeChange = true;
  allowOpeningInSeparateTab = true;
  typeOfConference: VIRTUAL_CONFERENCE_TYPE = VIRTUAL_CONFERENCE_TYPE.DAILY_CO;
  license: string = null;
  // options for mainEventOption = RECORDED_VIDEO_STREAMING;
  autoplay = true;
  positionSynchronization = false;

  kalturaPartnerId: string;
  kalturaPlayerId: string;

  // options for typeOfConference = VIRTUAL_CONFERENCE_TYPE.DAILY_CO;
  dailyCoSettings: DailyCoSettings = new DailyCoSettings();

  public static getDefaultSectionVirtualConferenceSettings(isInInstantGroup: boolean) {
    if (isInInstantGroup) {
      return new VirtualConferenceSettings({
        defaultSize: MEETING_VIEW_MODE.INTERNAL,
        mainEventOption: VIRTUAL_CONFERENCE_EVENT.INTEGRATED_LIVE_CONFERENCE,
        typeOfConference: VIRTUAL_CONFERENCE_TYPE.DAILY_CO
      });
    } else {
      return new VirtualConferenceSettings({
        defaultSize: MEETING_VIEW_MODE.MINIMIZED,
        mainEventOption: VIRTUAL_CONFERENCE_EVENT.EXTERNAL_VIDEO_STREAMING,
        typeOfConference: VIRTUAL_CONFERENCE_TYPE.DAILY_CO
      });
    }
  }

  constructor(obj?) {
    if (!obj) {
      return;
    }
    if (obj.hasOwnProperty('mainEventOption')) {
      this.mainEventOption = obj.mainEventOption;
    }
    if (obj.hasOwnProperty('linkToLiveStreaming')) {
      this.linkToLiveStreaming = obj.linkToLiveStreaming;
    }
    if (obj.hasOwnProperty('embedVariables')) {
      this.embedVariables = obj.embedVariables;
    }
    if (obj.hasOwnProperty('defaultSize')) {
      this.defaultSize = obj.defaultSize;
    }
    if (obj.hasOwnProperty('autoSizeControl')) {
      this.autoSizeControl = obj.autoSizeControl;
    }
    if (obj.hasOwnProperty('allowManualSizeChange')) {
      this.allowManualSizeChange = obj.allowManualSizeChange;
    }
    if (obj.hasOwnProperty('allowOpeningInSeparateTab')) {
      this.allowOpeningInSeparateTab = obj.allowOpeningInSeparateTab;
    }
    if (obj.hasOwnProperty('typeOfConference')) {
      this.typeOfConference = obj.typeOfConference;
    }
    if (obj.hasOwnProperty('license')) {
      this.license = obj.license;
    }
    if (obj.hasOwnProperty('autoplay')) {
      this.autoplay = obj.autoplay;
    }
    if (obj.hasOwnProperty('positionSynchronization')) {
      this.positionSynchronization = obj.positionSynchronization;
    }
    if (obj.hasOwnProperty('kalturaPartnerId')) {
      this.kalturaPartnerId = obj.kalturaPartnerId;
    }
    if (obj.hasOwnProperty('kalturaPlayerId')) {
      this.kalturaPlayerId = obj.kalturaPlayerId;
    }
    if (obj.hasOwnProperty('dailyCoSettings')) {
      this.dailyCoSettings = new DailyCoSettings(obj.dailyCoSettings);
    }
  }

  setDefaultByMainEventOptions() {
    if (this.mainEventOption === VIRTUAL_CONFERENCE_EVENT.EXTERNAL_VIDEO_STREAMING) {
      this.defaultSize = MEETING_VIEW_MODE.MINIMIZED;
      this.allowOpeningInSeparateTab = true;
    } else if (this.defaultSize === MEETING_VIEW_MODE.MINIMIZED) {
      this.defaultSize = MEETING_VIEW_MODE.INTERNAL;
    }
  }

  isIntegratedLiveConference() {
    return this.mainEventOption === VIRTUAL_CONFERENCE_EVENT.INTEGRATED_LIVE_CONFERENCE;
  }

  toObject() {
    const obj = {...this};
    if (obj.dailyCoSettings && obj.dailyCoSettings instanceof DailyCoSettings) {
      obj.dailyCoSettings = obj.dailyCoSettings.toObject();
    }
    return obj;
  }
}

export class FeedbackRating {
  activateFeedbackRating = false;
  acceptFeedback = true;
  allowTextNotes = true;
  popUpAutomaticallyOneMinBeforeSectionEnd = true;
  constructor(obj?) {
    if (!obj) {
      return;
    }
    if (obj.hasOwnProperty('activateFeedbackRating')) {
      this.activateFeedbackRating = obj.activateFeedbackRating;
    }
    if (obj.hasOwnProperty('acceptFeedback')) {
      this.acceptFeedback = obj.acceptFeedback;
    }
    if (obj.hasOwnProperty('allowTextNotes')) {
      this.allowTextNotes = obj.allowTextNotes;
    }
    if (obj.hasOwnProperty('popUpAutomaticallyOneMinBeforeSectionEnd')) {
      this.popUpAutomaticallyOneMinBeforeSectionEnd = obj.popUpAutomaticallyOneMinBeforeSectionEnd;
    }
  }

  toObject() {
    return {...this};
  }
}

export class SlotModeSettings {
  assignFreeSlotMode: ASSIGN_FREE_SLOT_MODE = ASSIGN_FREE_SLOT_MODE.REFERENCE;
  allowPresentersModifySlots: boolean; // Allow presenters to create, delete and edit slots

  constructor(obj?) {
    if (!obj) {
      return;
    }
    if (obj.hasOwnProperty('assignFreeSlotMode')) {
      this.assignFreeSlotMode = obj.assignFreeSlotMode;
    }
    if (obj.hasOwnProperty('allowPresentersModifySlots')) {
      this.allowPresentersModifySlots = obj.allowPresentersModifySlots;
    }
  }

  toObject() {
    return {...this};
  }
}

export enum PHASE_CAPTION_FIELDS {
  PREP = 'eventPhasePrepCaption',
  START = 'eventPhaseStartCaption',
  WRAP_UP = 'eventPhaseWrapUpCaption'
}

export class InstantSettings {
  public static readonly DB_PATH = 'instant_settings';

  public attendeesRegistration = false;
  public contentFromAudienceMode: CONTENT_FROM_AUDIENCE_MODE = CONTENT_FROM_AUDIENCE_MODE.NONE;
  public qaModerationReview = false;
  public qaAllowAnswersFromParticipants = false;
  public qaAllowSoundAlert = false;
  public easyContentSettings = new EasyContentSettings();
  public manageTimeRound5Min = true;
  public eventPhasePrepCaption: string;
  public multilingualEventPhasePrepCaption: ICaption;
  public eventPhaseStartCaption: string;
  public multilingualEventPhaseStartCaption: ICaption;
  public eventPhaseWrapUpCaption: string;
  public multilingualEventPhaseWrapUpCaption: ICaption;
  public autopilot = new AutopilotSettings();
  public canShowVirtualConferences = false;
  public advancedSettings = new AdvancedSettings();
  public socialSettings: SocialSettings = new SocialSettings();
  public chatSettings: ChatSettings = new ChatSettings();
  public followMeSettings: FollowMeSettings = new FollowMeSettings();
  public virtualConferenceSettings: VirtualConferenceSettings = new VirtualConferenceSettings();
  public feedbackRating: FeedbackRating = new FeedbackRating();
  public slotModeSettings: SlotModeSettings = new SlotModeSettings();
  public recorderSettings: RecorderSettings = new RecorderSettings();
  public myKnowledge: boolean;
  public allowMarkAsDone = false;


  constructor(obj?) {
    if (!obj) {
      this.qaAllowSoundAlert = true;
      return;
    }
    if (obj.hasOwnProperty('attendeesRegistration')) {
      this.attendeesRegistration = obj.attendeesRegistration;
    }
    if (obj.hasOwnProperty('contentFromAudienceMode')) {
      this.contentFromAudienceMode = obj.contentFromAudienceMode;
    }
    if (obj.easyContentSettings) {
      this.easyContentSettings = new EasyContentSettings(obj.easyContentSettings);
    }
    if (obj.hasOwnProperty('manageTimeRound5Min')) {
      this.manageTimeRound5Min = obj.manageTimeRound5Min;
    } else {
      this.manageTimeRound5Min = true;
    }
    if (obj.hasOwnProperty('eventPhasePrepCaption')) {
      this.eventPhasePrepCaption = obj.eventPhasePrepCaption;
    }
    if (obj.hasOwnProperty('multilingualEventPhasePrepCaption')) {
      this.multilingualEventPhasePrepCaption = obj.multilingualEventPhasePrepCaption;
    }
    if (obj.hasOwnProperty('eventPhaseStartCaption')) {
      this.eventPhaseStartCaption = obj.eventPhaseStartCaption;
    }
    if (obj.hasOwnProperty('multilingualEventPhaseStartCaption')) {
      this.multilingualEventPhaseStartCaption = obj.multilingualEventPhaseStartCaption;
    }
    if (obj.hasOwnProperty('eventPhaseWrapUpCaption')) {
      this.eventPhaseWrapUpCaption = obj.eventPhaseWrapUpCaption;
    }
    if (obj.hasOwnProperty('multilingualEventPhaseWrapUpCaption')) {
      this.multilingualEventPhaseWrapUpCaption = obj.multilingualEventPhaseWrapUpCaption;
    }
    if (obj.autopilot) {
      this.autopilot = new AutopilotSettings(obj.autopilot);
    }
    if (obj.hasOwnProperty('canShowVirtualConferences')) {
      this.canShowVirtualConferences = obj.canShowVirtualConferences;
    }
    if (obj.advancedSettings) {
      this.advancedSettings = new AdvancedSettings(obj.advancedSettings);
    }
    if (obj.hasOwnProperty('socialSettings')) {
      this.socialSettings = new SocialSettings(obj.socialSettings);
    }
    if (obj.hasOwnProperty('chatSettings')) {
      this.chatSettings = new ChatSettings(obj.chatSettings);
    }
    if (obj.hasOwnProperty('followMeSettings')) {
      this.followMeSettings = new FollowMeSettings(obj.followMeSettings);
    }
    if (obj.hasOwnProperty('virtualConferenceSettings')) {
      this.virtualConferenceSettings = new VirtualConferenceSettings(obj.virtualConferenceSettings);
    }
    if (obj.hasOwnProperty('feedbackRating')) {
      this.feedbackRating = new FeedbackRating(obj.feedbackRating);
    }
    if (obj.hasOwnProperty('slotModeSettings')) {
      this.slotModeSettings = new SlotModeSettings(obj.slotModeSettings);
    }
    if (obj.hasOwnProperty('qaModerationReview')) {
      this.qaModerationReview = obj.qaModerationReview;
    }
    if (obj.hasOwnProperty('qaAllowAnswersFromParticipants')) {
      this.qaAllowAnswersFromParticipants = obj.qaAllowAnswersFromParticipants;
    }
    if (obj.hasOwnProperty('qaAllowSoundAlert')) {
      this.qaAllowSoundAlert = obj.qaAllowSoundAlert;
    }
    if (obj.hasOwnProperty('myKnowledge')) {
      this.myKnowledge = obj.myKnowledge;
    }
    if (obj.hasOwnProperty('recorderSettings')) {
      this.recorderSettings = new RecorderSettings(obj.recorderSettings);
    }
    if (obj.hasOwnProperty('allowMarkAsDone')) {
      this.allowMarkAsDone = obj.allowMarkAsDone;
    }
  }

  modeNone() {
    return this.contentFromAudienceMode === CONTENT_FROM_AUDIENCE_MODE.NONE;
  }

  modeEasy() {
    return !this.modeNone();
  }

  getStartCaptionForMessage(languageParams: ILanguageParams) {
    return isEmpty(this.eventPhaseStartCaption) ? 'common.main.event' :
      this.getPhasesCaptionByLanguage(PHASE_CAPTION_FIELDS.START, languageParams);
  }

  isPhasesCaptionChange(instantSettings: InstantSettings) {
    const json = (obj) => JSON.stringify(obj, (_, v) => v?.constructor === Object ? Object.entries(v).sort() : v);
    const before = {};
    const after = {};
    for (const fieldName of Object.keys(PHASE_CAPTION_FIELDS)) {
      const field: string = PHASE_CAPTION_FIELDS[fieldName];
      const mlField = `multilingual${field[0].toUpperCase()}${field.substring(1)}`;
      before[fieldName] = {
        single: this[field] ?? null,
        multilingual: this[mlField] ?? null
      };
      after[fieldName] = {
        single: instantSettings[field] ?? null,
        multilingual: instantSettings[mlField] ?? null
      };
    }
    return json(before) !== json(after);
  }

  getPhasesCaptionByLanguage(phaseName: PHASE_CAPTION_FIELDS, languageParams: ILanguageParams) {
    const defaultName = (phase) => {
      switch (phase) {
        case PHASE_CAPTION_FIELDS.PREP:
          return 'common.prep';
        case PHASE_CAPTION_FIELDS.START:
          return 'common.start';
        case PHASE_CAPTION_FIELDS.WRAP_UP:
          return 'common.wrap.up';
      }
    };
    return UtilsService.getByMultilingual(this, phaseName, languageParams) ?? defaultName(phaseName);
  }

  setPhasesCaptionByLanguage(phaseName: string, value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByMultilingual(this, phaseName, value, languageParams);
  }


  toObject() {
    const obj = {...this};
    obj.easyContentSettings = obj.easyContentSettings.toObject();
    obj.autopilot = obj.autopilot.toObject();
    obj.advancedSettings = obj.advancedSettings.toObject();
    obj.socialSettings = obj.socialSettings.toObject();
    obj.chatSettings = obj.chatSettings.toObject();
    obj.followMeSettings = obj.followMeSettings.toObject();
    obj.virtualConferenceSettings = obj.virtualConferenceSettings.toObject();
    obj.feedbackRating = obj.feedbackRating.toObject();
    obj.slotModeSettings = obj.slotModeSettings.toObject();
    obj.recorderSettings = obj.recorderSettings.toObject();
    return obj;
  }
}
