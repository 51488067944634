import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {EventsDataService} from '../../services/events-data.service';
import {BehaviorSubject, first, firstValueFrom, take} from 'rxjs';
import {
  ASSIGN_FREE_SLOT_MODE,
  Constants,
  DATE_FORMAT,
  ENTITY_LINK_TYPE,
  EVENT_DATE_MODE,
  IEventEntityLink,
  LOAD_STATE
} from '../../core/constants';
import {UtilsService} from '../../core/utils.service';
import {ContentService} from '../../services/content.service';
import {HKVBSApiService} from '../../services/hkvbs-api.service';
import {LoginService} from '../../login/login.service';
import {AppUser} from '../../model/AppUser';
import {Event, IManager} from '../../model/Event';
import {cloneDeep, isEmpty, isEqual, max, merge, pick, union, unionBy, uniq} from 'lodash';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {UsersDataService} from '../../services/users-data.service';
import {AngularFirestore, DocumentReference, QueryFn, SetOptions} from '@angular/fire/compat/firestore';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {ModularContent, Module} from '../../model/content/ModularContent';
import {QuestionnaireContent} from '../../model/content/QuestionnaireContent';
import {EventQuestion} from '../../model/EventQuestion';
import {StdComponent} from '../../core/std-component';
import firebase from 'firebase/compat/app';
import {EventApiService} from '../../services/event-api.service';
import {ISectionSubject, SectionContent} from '../../model/content/SectionContent';
import {UserApiService} from '../../services/user-api.service';
import {CommonService} from '../../core/common.service';
import {ClientApiService} from '../../services/client-api.service';
import {ClientConfig} from '../../model/ClientConfig';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {
  clientDataCollectionName,
  competencyCollectionName,
  CompetencyFlat,
  Curriculum,
  Curriculum as EduCurriculum,
  curriculumCollectionName,
  CurriculumLevel,
  CurriculumSubject,
  CurriculumTimeBlock,
  industriesCollectionName,
  LearningPath as EduLearninPath,
  LearningPathItem,
  learningPathItemsCollectionName,
  learningPathsCollectionName,
  Module as EduModule,
  ModuleCurriculums,
  modulesCollectionName,
  timeBlockCollectionName
} from '@ninescopesoft/core';
import {IUploadFileLink, TaskDocument} from '../../model/content/TaskDocument';
import {EventModeApiService, IDocumentPathParams} from '../../services/event-mode-api.service';
import {CONTAINER_ITEM_TYPE, ContentContainer} from '../../model/content/ContentContainer';
import {FeaturesService} from '../../core/features.service';
import {Quiz} from '../../modules/content-container/components/quiz/quiz-model/quiz';
import {AbstractContent, CONTENT_PATH_TYPE, IContentEntityLink} from '../../model/content/AbstractContent';
import {ConferenceUser} from '../../model/event-mode/ConferenceUser';
import {StorageDataService} from '../../services/storage-data.service';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {TPdfData} from '../../modules/content-container/components/pdf-document/pdf-document.service';
import {MatDialog} from '@angular/material/dialog';
import {EditDialogComponent} from '../../dialog-components/edit-dialog/edit-dialog.component';
import {InstantSettings} from '../../model/event-mode/InstantSettings';
import {StatisticsApiServiceService} from '../../services/statistics-api-service.service';
import {TimeLineService} from '../../services/time-line.service';
import {
  createEventsLastActivityStatistics,
  createEventsSelfLearningModulesActivityStatistics,
  createEventsSelfLearningModulesStatistics
} from './tasks/001-update-statistics';
import {updateOnlineStatistics, updateRegisteredStatistics} from './tasks/002-online-statistics';

interface IWriteCache {
  ref: DocumentReference;
  obj: any;
  options?: SetOptions;
}

interface IObject {
  id: string;
  name: string;
}

interface IChild {
  sectionId: string;
  parentId: string;
  orderIndex: number;
  level?: number;
  title: string;
}

interface IIndexTest {
  sectionId: string;
  parentId: string;
  title: string;
  minIndex: number;
  maxIndex: number;
  level: number;
  isLost: boolean;
  childrenOutOfIndex: IChild[];
}

@Component({
  selector: 'app-service-tasks',
  templateUrl: './service-tasks.component.html',
  styleUrls: ['./service-tasks.component.scss']
})

export class ServiceTasksComponent extends StdComponent implements OnInit {
  currentUser: AppUser;

  batch: firebase.firestore.WriteBatch;
  batchCounter = 0;
  logEven = [];
  logEvenId = {};
  contentCounter = 0;
  load = false;
  progress: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  currentClientId;
  status: string[];
  status1: string[];
  status2: string[];
  status3: string[];
  clientIdsList: string[];
  clientList: ClientConfig[];
  prevChoice: any;
  isProduction = this.commonService.getEnv().production;
  @ViewChild('date') el: ElementRef;

  constructor(protected injector: Injector,
              public eventDataService: EventsDataService,
              public commonService: CommonService,
              public utils: UtilsService,
              public contentService: ContentService,
              public loginService: LoginService,
              public dataService: EventsDataService,
              public hkvbsApiService: HKVBSApiService,
              public userDataService: UsersDataService,
              public eventApiService: EventApiService,
              public clientApiService: ClientApiService,
              public featuresService: FeaturesService,
              public store: Store<fromRoot.State>,
              public afs: AngularFirestore,
              public aff: AngularFireFunctions,
              public afDB: AngularFireDatabase,
              public angularFireStorage: AngularFireStorage,
              public http: HttpClient,
              public userApi: UserApiService,
              public eventModeApi: EventModeApiService,
              public dialog: MatDialog,
              public storageDataService: StorageDataService,
              public statisticsApiServiceService: StatisticsApiServiceService,
              public timeLineService: TimeLineService) {
    super(injector);
    this.currentClientId = this.loginService.client_id$.getValue();
  }

  ngOnInit(): void {
    firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then(snapshot => {
        this.clientIdsList = snapshot.docs.map(d => d.id);
        this.clientList = snapshot.docs.map(d => new ClientConfig(Object.assign({id: d.id}, d.data())));
      });
  }

  confirmationBeforeRun(title: string, prevChoice?: any) {
    const fields = this.clientList.map(v => new Object({
      id: v.id,
      type: 'checkbox',
      placeholder: `${v.id} (${v.name})`
    }));
    const results = this.clientList.reduce((acc, v) => Object.assign(acc, {[v.id]: prevChoice?.[v.id] ?? false}), {});
    const dialogData = {title: `Select client for task [${title}]`
      , fields: fields
      , result: results
      , disableConfirmDialog: true
    };
    const dialogRef = this.dialog.open(EditDialogComponent, {
      disableClose: true,
      width: '600px',
      data: dialogData
    });
    dialogRef.afterOpened().pipe(take(1))
      .subscribe(() => {
        const chBoxList: HTMLCollection = dialogRef.componentRef.location.nativeElement.getElementsByTagName('mat-checkbox');
        for (const chBox of Array.from(chBoxList)) {
          (chBox as HTMLElement).style.marginBottom = '5px';
        }
        if (dialogRef.componentInstance.actionPanel) {
          const selectAll = document.createElement('button');
          selectAll.innerHTML = 'Select all';
          selectAll.className = 'mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base';
          selectAll.setAttribute('mat-button', '');
          selectAll.setAttribute('timeline', '');
          selectAll.style.marginRight = 'auto';
          selectAll.style.marginLeft = '0';
          selectAll.style.order = '1';
          selectAll.onclick = (ev: any) => {
            for (const client of this.clientList) {
              const value = dialogRef.componentRef.instance.fieldsPanel.result[client.id];
              dialogRef.componentRef.instance.fieldsPanel.result[client.id] = !value;
            }
          };
          dialogRef.componentInstance.actionPanel.nativeElement.appendChild(selectAll);
        }
      });
    return firstValueFrom(dialogRef.afterClosed()).then(dialogResult => {
      return isEmpty(dialogResult) ? null : Object.keys(dialogResult).filter(id => !!dialogResult[id]);
    });

  }

  updateInvitedUsers(allUsers: AppUser[]) {
    let promises = Promise.resolve();
    const obj = {};
    let updateCount = 0;
    promises = promises.then(() =>
      this.afs.collection('events')
        .get()
        .toPromise()
        .then((snapshot) => {
          let secPromise = Promise.resolve();
          const events = snapshot.docs;
          for (const doc of events) {
            updateCount++;
            secPromise = secPromise.then(() =>
              this.afs.collection('conference')
                .doc(doc.id)
                .collection('users_invited')
                .get()
                .toPromise()
                .then((snapshotUsers) => {
                  let cntPromise = Promise.resolve();
                  const users = snapshotUsers.docs;
                  for (const userDoc of users) {
                    const user = userDoc.data();
                    const appUser = allUsers.find(u => u.email === user['email']);
                    if (appUser) {
                      cntPromise = cntPromise.then(() => {
                        console.log('eventId', doc.id, 'user', user['email']);
                        return this.afs.collection('conference').doc(doc.id)
                          .collection('users_invited').doc(userDoc.id).set({
                            userId: appUser.userId,
                            fullName: !isEmpty(appUser.fullName) ? appUser.fullName : null,
                            picture: !isEmpty(appUser.picture) ? appUser.picture : null,
                            userName: !isEmpty(appUser.userName) ? appUser.userName : null
                          }, { merge: true });
                      });
                    }
                  }
                  cntPromise = Promise.resolve();
                  return cntPromise;
                })
            );
          }
          return secPromise;
        })
    );
    promises
      .then(() => {
        console.log('Update count ', updateCount);
      });
  }

  resignModularContent(clientId, event: { eventId, startDate, shortLink, eventName; }, sectionId, content: ModularContent) {
    const vm = this;
    return new Promise<any>((resolve, reject) => {
      const allPromise = [];
      for (const item of content.getItems()) {
        if (item.type === Constants.MODULE_TYPE_IMAGE || item.type === Constants.MODULE_TYPE_PDF) {
          allPromise.push(vm.resignContentItem(clientId, event, sectionId, content.id, item as Module));
        }
      }
      Promise.all(allPromise).then(function (rList) {
        if (rList.every(o => o === Constants.SKIP)) {
          return resolve(Constants.SUCCESS);
        }
        for (const obj of rList) {
          if (obj !== Constants.ERROR) {
            const itemIndex = content.getItems().findIndex(o => o.id === obj.id);
            if (itemIndex > -1) {
              content.items[itemIndex] = obj;
            }
          } else {
            return resolve(Constants.ERROR);
          }
        }
        const resignContent = new ModularContent(content);
        resignContent.items = content.getItems();
        vm.contentService.deleteNotSavedFields(resignContent);
        vm.eventDataService.editModularContent(event.eventId, content.id, resignContent).then(function (r) {
          console.log('update eventId: ' + event.eventId + ' contentId: ' + content.id);
          vm.contentCounter = vm.contentCounter + 1;
          resolve(Constants.SUCCESS);
        }).catch(e => {
          console.log(e, 'eventId: ' + event.eventId + ' name: ' + event.eventName + ' shortLink: ' + event.shortLink +
            ' sectionId: ' + sectionId + ' contentId: ' + content.id + ' ERROR: resignModularContent');
          vm.logEven.push('eventId: ' + event.eventId + ' name: ' + event.eventName + ' shortLink: ' + event.shortLink +
            ' sectionId: ' + sectionId + ' contentId: ' + content.id + ' ERROR: resignModularContent/editModularContent');
          vm.logEvenId[event.eventId] = event.eventName;
          resolve(Constants.ERROR);
        });
      }).catch(e => {
        console.log(e, 'eventId: ' + event.eventId + ' name: ' + event.eventName + ' shortLink: ' + event.shortLink +
          ' sectionId: ' + sectionId + ' contentId: ' + content.id + ' ERROR: resignModularContent');
        vm.logEven.push('eventId: ' + event.eventId + ' name: ' + event.eventName + ' shortLink: ' + event.shortLink +
          ' sectionId: ' + sectionId + ' contentId: ' + content.id + ' ERROR: resignModularContent');
        vm.logEvenId[event.eventId] = event.eventName;
        resolve(Constants.ERROR);
      });
    });
  }

  resignContentItem(clientId, event: { eventId, startDate, shortLink, eventName; }, sectionId, contentId, module: Module) {
    const vm = this;
    return new Promise<any>((resolve, reject) => {
      let moduleData: { id, src, upload; }[] = [];
      try {
        moduleData = JSON.parse(module.getModuleContent());
      } catch (e) {
        console.log(e, 'eventId: ' + event.eventId + ' name: ' + event.eventName + ' shortLink: ' + event.shortLink +
          ' sectionId: ' + sectionId + ' contentId: ' + contentId + ' ERROR: resignContentItem: Can\'t parse module data.');
        vm.logEven.push('eventId: ' + event.eventId + ' name: ' + event.eventName + ' shortLink: ' + event.shortLink +
          ' sectionId: ' + sectionId + ' contentId: ' + contentId + ' ERROR: resignContentItem: Can\'t parse module data.');
        vm.logEvenId[event.eventId] = event.eventName;
        return resolve(Constants.ERROR);
      }
      const allPromise = [];
      for (const row of moduleData) {
        if (isEmpty(row.src) || vm.utils.isFBUrl(row.src as string, event.eventId)) {
          allPromise.push(this.updateSignUrl(clientId, event, sectionId, contentId, module.type, row));
        }
      }
      if (allPromise.length === 0) {
        return resolve(Constants.SKIP);
      }
      Promise.all(allPromise).then(function (rList) {
        for (const obj of rList) {
          if (obj !== Constants.ERROR) {
            const rowIndex = moduleData.findIndex(o => o.id === obj.id);
            if (rowIndex > -1) {
              moduleData[rowIndex] = obj;
            }
          } else {
            return resolve(Constants.ERROR);
          }
        }
        const resignModule = new Module(module);
        try {
          resignModule.moduleContent = JSON.stringify(moduleData);
          resolve(resignModule);
        } catch (e) {
          console.log(e, 'eventId: ' + event.eventId + ' name: ' + event.eventName + ' shortLink: ' + event.shortLink +
            'sectionId: ' + sectionId + 'contentId: ' + contentId + ' ERROR: Can\'t parse module data.');
          vm.logEven.push('eventId: ' + event.eventId + ' name: ' + event.eventName + ' shortLink: ' + event.shortLink +
            'sectionId: ' + sectionId + 'contentId: ' + contentId + ' ERROR: resignContentItem.');
          vm.logEvenId[event.eventId] = event.eventName;
          return resolve(Constants.ERROR);
        }
      }).catch(e => {
        console.log(e, 'eventId: ' + event.eventId + ' name: ' + event.eventName + ' shortLink: ' + event.shortLink +
          ' sectionId: ' + sectionId + ' contentId: ' + contentId + ' ERROR: resignContentItem');
        vm.logEven.push('eventId: ' + event.eventId + ' name: ' + event.eventName + ' shortLink: ' + event.shortLink +
          ' sectionId: ' + sectionId + ' contentId: ' + contentId + ' ERROR: resignContentItem');
        vm.logEvenId[event.eventId] = event.eventName;
        resolve(Constants.ERROR);
      });
    });
  }

  updateSignUrl(clientId, event: { eventId, startDate, shortLink, eventName; },
                sectionId, contentId, moduleType, row: { id, src, upload }) {
    const vm = this;
    const ext = moduleType === Constants.MODULE_TYPE_PDF ? '.pdf' : '.png';
    return new Promise<any>((resolve, reject) => {
      vm.eventDataService
        .getSignedUrl(`client_data/${clientId}/conference/${event.eventId}/${moduleType}/${contentId}/${row.id}${ext}`)
        .then(function (url) {
          resolve({ id: row.id, src: url, upload: row.upload });
        }).catch(e => {
          console.log(e, 'eventId: ' + event.eventId + ' name: ' + event.eventName + ' shortLink: ' + event.shortLink +
            ' sectionId: ' + sectionId + ' contentId: ' + contentId + ' ERROR: getDownloadUrl');
          vm.logEven.push('eventId: ' + event.eventId + ' name: ' + event.eventName + ' shortLink: ' + event.shortLink +
            ' sectionId: ' + sectionId + ' contentId: ' + contentId + ' ERROR: getDownloadUrl');
          vm.logEvenId[event.eventId] = event.eventName;
          resolve(Constants.ERROR);
        });
    });
  }

  updateDocSignUrl(clientId, event: { eventId, startDate, shortLink, eventName; }, sectionId, contentId, row: IUploadFileLink) {
    const vm = this;
    return new Promise<IUploadFileLink | 'error'>((resolve, reject) => {
      vm.eventDataService
        .getSignedUrl(`client_data/${clientId}/conference/${event.eventId}/content/${contentId}/presenter/${row.id}-${row.name}`)
        .then(function (url) {
          resolve({ id: row.id, name: row.name, metaType: row.metaType, src: url, upload: row.upload });
        }).catch(e => {
          console.log(e, 'eventId: ' + event.eventId + ' name: ' + event.eventName + ' shortLink: ' + event.shortLink +
            ' sectionId: ' + sectionId + ' contentId: ' + contentId + ' ERROR: updateDocSignUrl');
          vm.logEven.push('eventId: ' + event.eventId + ' name: ' + event.eventName + ' shortLink: ' + event.shortLink +
            ' sectionId: ' + sectionId + ' contentId: ' + contentId + ' ERROR: updateDocSignUrl/getSignedUrl');
          vm.logEvenId[event.eventId] = event.eventName;
          resolve('error');
        });
    });
  }

  resignTaskDocumentContent(clientId, event: { eventId, startDate, shortLink, eventName; }, sectionId, content: TaskDocument) {
    const vm = this;
    return new Promise<any>((resolve, reject) => {
      const allPromise = [];
      for (const item of content.uploadFileLinks) {
        allPromise.push(vm.updateDocSignUrl(clientId, event, sectionId, content.id, item));
      }
      Promise.all(allPromise).then(function (rList) {
        for (const obj of rList) {
          if (obj !== Constants.ERROR) {
            const link = content.uploadFileLinks.find(l => l.id === obj.id);
            if (link) {
              link.src = obj.src;
            }
          } else {
            return resolve(Constants.ERROR);
          }
        }
        const resignContent = new TaskDocument(content);
        resignContent.uploadFileLinks = content.uploadFileLinks;
        vm.eventDataService.editTaskDocumentContent(event.eventId, content.id, resignContent).then(function (r) {
          console.log('update eventId: ' + event.eventId + ' contentId: ' + content.id);
          vm.contentCounter = vm.contentCounter + 1;
          resolve(Constants.SUCCESS);
        }).catch(e => {
          console.log(e, 'eventId: ' + event.eventId + ' name: ' + event.eventName + ' shortLink: ' + event.shortLink +
            ' sectionId: ' + sectionId + ' contentId: ' + content.id + ' ERROR: resignTaskDocumentContent');
          vm.logEven.push('eventId: ' + event.eventId + ' name: ' + event.eventName + ' shortLink: ' + event.shortLink +
            ' sectionId: ' + sectionId + ' contentId: ' + content.id + ' ERROR: resignModularContent/editTaskDocumentContent');
          vm.logEvenId[event.eventId] = event.eventName;
          resolve(Constants.ERROR);
        });
      }).catch(e => {
        console.log(e, 'eventId: ' + event.eventId + ' name: ' + event.eventName + ' shortLink: ' + event.shortLink +
          ' sectionId: ' + sectionId + ' contentId: ' + content.id + ' ERROR: resignTaskDocumentContent');
        vm.logEven.push('eventId: ' + event.eventId + ' name: ' + event.eventName + ' shortLink: ' + event.shortLink +
          ' sectionId: ' + sectionId + ' contentId: ' + content.id + ' ERROR: resignTaskDocumentContent');
        vm.logEvenId[event.eventId] = event.eventName;
        resolve(Constants.ERROR);
      });
    });
  }

  updateManagersLogo(allUsers: AppUser[]) {
    let promises = Promise.resolve();
    let counter = 0;
    promises = promises.then(() =>
      this.afs.collection('events')
        .get()
        .toPromise()
        .then((snapshot) => {
          let secPromise = Promise.resolve();
          const events: any = snapshot.docs.map(d => new Event({ eventId: d.id, ...(d.data() as object) })).filter(e => e.deleted === 0);
          for (const event of events) {
            const obj = {
              managers: null,
              presenters: null,
              concierges: null,
              speakers: null
            };
            if (!isEmpty(event.presenters)) {
              obj.presenters = {};
              for (const userId of Object.keys(event.presenters)) {
                const user = allUsers.find(u => u.userId === userId);
                if (user) {
                  obj.presenters[userId] = { picture: user.picture };
                }
              }
            }
            if (!isEmpty(event.concierges)) {
              obj.concierges = {};
              for (const userId of Object.keys(event.concierges)) {
                const user = allUsers.find(u => u.userId === userId);
                if (user) {
                  obj.concierges[userId] = { picture: user.picture };
                }
              }
            }
            if (!isEmpty(event.managers)) {
              obj.managers = {};
              for (const userId of Object.keys(event.managers)) {
                const user = allUsers.find(u => u.userId === userId);
                if (user) {
                  obj.managers[userId] = { picture: user.picture };
                }
              }
            }
            if (!isEmpty(event.speakers)) {
              obj.speakers = {};
              for (const userId of Object.keys(event.speakers)) {
                const user = allUsers.find(u => u.userId === userId);
                if (user) {
                  obj.speakers[userId] = { picture: user.picture };
                }
              }
            }
            secPromise = secPromise.then(() => {
              counter++;
              console.log(counter + ' of ' + events.length);
              return this.afs.collection('events').doc(event.eventId).set(obj, { merge: true });
            });
          }
          return secPromise;
        })
    );
    promises
      .then(() => {
        console.log('Finish. Update count: ', counter);
      });
  }

  duplicateUsersFinder() {
    let counter = 0;
    const duplicate = {};
    this.afs.collection('app_users')
      .get()
      .toPromise()
      .then((snapshot) => {
        let secPromise = Promise.resolve();
        const users = snapshot.docs;
        for (const doc of users) {
          const user: any = doc.data();
          secPromise = secPromise.then(() => {
            counter++;
            console.log(counter + ' of ' + users.length);
            if (user.email) {
              if (!duplicate[user.email]) {
                duplicate[user.email] = [];
              }
              duplicate[user.email].push({ userId: doc.id, ...(user as object) });
            }
          });
        }
        return secPromise.then(() => Promise.resolve());
      })
      .then(() => {
        Object.keys(duplicate).forEach(key => {
          if (duplicate[key].length <= 1) {
            delete duplicate[key];
          }
        });
        console.log('finished!', duplicate);
      });
  }

  async removeOldSectionFields() {
    const clients = await this.afs
      .collection('client_data')
      .get()
      .toPromise()
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const clientId of clients) {
      console.log(`Client ${clientId} started`);
      await this.removeOldSectionFieldsByClient(clientId);
      console.log(`Client ${clientId} finished`);
    }

    console.log('Finish!');
  }

  removeOldSectionFieldsByClient(clientId: string) {
    // const clientId = this.loginService.client_id$.getValue();

    const cache: IWriteCache[] = [];
    let promises = Promise.resolve();
    let updateCount = 0;
    promises = promises.then(() =>
      this.afs.collection('client_data').doc(clientId)
        .collection('events')
        .get()
        .toPromise()
        .then((snapshot) => {
          let secPromise = Promise.resolve();
          const events = snapshot.docs;
          let counter = 0;
          for (const doc of events) {
            const event: any = doc.data();
            const obj = {};
            if (event.hasOwnProperty('education')) {
              obj['education'] = firebase.firestore.FieldValue.delete();
            }
            secPromise = secPromise.then(() => {
              counter++;
              console.log(counter + ' of ' + events.length);
            });
            if (!isEmpty(obj)) {
              secPromise = secPromise.then(() => {
                updateCount++;
                const ref = this.afs.collection('client_data').doc(clientId)
                  .collection('events').doc(doc.id)
                  .ref;
                cache.push({ ref: ref, obj: obj });
              });
            }
            secPromise = secPromise.then(() => {
              return this.afs.collection('client_data').doc(clientId)
                .collection('conference').doc(doc.id)
                .collection('timeline')
                .get()
                .toPromise()
                .then((querySnapshot) => {
                  for (const sDoc of querySnapshot.docs) {
                    const section: any = sDoc.data();
                    const sObj = {};
                    if (section.hasOwnProperty('start')) {
                      sObj['start'] = firebase.firestore.FieldValue.delete();
                    }
                    if (section.hasOwnProperty('stop')) {
                      sObj['stop'] = firebase.firestore.FieldValue.delete();
                    }
                    if (section.hasOwnProperty('time')) {
                      sObj['time'] = firebase.firestore.FieldValue.delete();
                    }
                    if (section.hasOwnProperty('views')) {
                      sObj['views'] = firebase.firestore.FieldValue.delete();
                    }
                    if (section.hasOwnProperty('copies')) {
                      sObj['copies'] = firebase.firestore.FieldValue.delete();
                    }
                    if (section.hasOwnProperty('subtype')) {
                      sObj['subtype'] = firebase.firestore.FieldValue.delete();
                    }
                    if (section.hasOwnProperty('instantSettings')) {
                      sObj['instantSettings'] = firebase.firestore.FieldValue.delete();
                    }
                    if (!isEmpty(sObj)) {
                      updateCount++;
                      const ref = this.afs.collection('client_data').doc(clientId)
                        .collection('conference').doc(doc.id)
                        .collection('timeline').doc(sDoc.id)
                        .ref;
                      cache.push({ ref: ref, obj: sObj });
                    }
                  }
                });
            });
          }
          return secPromise;
        })
    );
    promises = promises.then(() => {
      return this.commitCache(cache);
    });
    return promises
      .then(() => {
        console.log('Update count ', updateCount);
      });
  }

  moveSectionUsers() {
    const cache: IWriteCache[] = [];
    let promises = Promise.resolve();
    let updateCount = 0;
    promises = promises.then(() =>
      this.afs.collection('events')
        .get()
        .toPromise()
        .then((snapshot) => {
          let secPromise = Promise.resolve();
          const events = snapshot.docs;
          let counter = 0;
          for (const doc of events) {
            const event: any = doc.data();
            secPromise = secPromise.then(() => {
              counter++;
              console.log(counter + ' of ' + events.length);
              return this.afs.collection('conference')
                .doc(doc.id)
                .collection('timeline')
                .get()
                .toPromise()
                .then((querySnapshot) => {
                  for (const sDoc of querySnapshot.docs) {
                    const section: any = sDoc.data();
                    if (section.hasOwnProperty('users') && section.users instanceof Array) {
                      for (const user of section.users) {
                        if (user.attendee) {
                          updateCount++;
                          const obj = {
                            sections: firebase.firestore.FieldValue.arrayUnion(sDoc.id)
                          };
                          const ref = this.afs.collection('conference')
                            .doc(doc.id)
                            .collection('user_data')
                            .doc(user.userId).ref;
                          cache.push({ ref: ref, obj: obj });
                        }
                      }
                    }
                  }
                });
            });
          }
          return secPromise;
        })
    );
    promises = promises.then(() => {
      return this.commitCache(cache);
    });
    promises
      .then(() => {
        console.log('Update count ', updateCount);
      });
  }

  addQuestionCount() {
    const cache: IWriteCache[] = [];
    let promises = Promise.resolve();
    let updateCount = 0;
    promises = promises.then(() =>
      this.afs.collection('events')
        .get()
        .toPromise()
        .then((snapshot) => {
          let secPromise = Promise.resolve();
          const events = snapshot.docs;
          let counter = 0;
          for (const doc of events) {
            const event: any = doc.data();
            secPromise = secPromise.then(() => {
              counter++;
              console.log(counter + ' of ' + events.length);
              return this.afs.collection('conference')
                .doc(doc.id)
                .collection('registration_questionnaire')
                .get()
                .toPromise()
                .then((querySnapshot) => {
                  for (const sDoc of querySnapshot.docs) {
                    const section: any = sDoc.data();
                    updateCount++;
                    const obj = {
                      questionsCount: !isEmpty(section.questions) ? Object.keys(section.questions).length : 0
                    };
                    const ref = this.afs.collection('conference')
                      .doc(doc.id)
                      .collection('registration_questionnaire')
                      .doc(sDoc.id).ref;
                    cache.push({ ref: ref, obj: obj });
                  }
                });
            });
          }
          return secPromise;
        })
    );
    promises = promises.then(() => {
      return this.commitCache(cache);
    });
    promises
      .then(() => {
        console.log('Update count ', updateCount);
      });
  }

  fillSectionRegisteredUsers() {
    const cache: IWriteCache[] = [];
    let promises = Promise.resolve();
    let updateCount = 0;
    promises = promises.then(() =>
      this.afs.collection('events')
        .get()
        .toPromise()
        .then((snapshot) => {
          let secPromise = Promise.resolve();
          const events = snapshot.docs;
          let counter = 0;
          for (const doc of events) {
            const event = doc.data();
            secPromise = secPromise.then(() => {
              counter++;
              console.log(counter + ' of ' + events.length);
              return this.afs.collection('conference')
                .doc(doc.id)
                .collection('user_registration')
                .get()
                .toPromise()
                .then((querySnapshot) => {
                  for (const sDoc of querySnapshot.docs) {
                    const user = sDoc.data();
                    if (user && !isEmpty(user.sections)) {
                      for (const sectionId of Object.keys(user.sections)) {
                        const regDataBySection = user.sections[sectionId];
                        if (!Constants.UNREGISTERED_STATUS[regDataBySection.status]) {
                          updateCount++;
                          const obj = {
                            email: regDataBySection.email,
                            status: regDataBySection.status,
                            userId: regDataBySection.userId
                          };
                          const ref = this.afs.collection('conference')
                            .doc(doc.id)
                            .collection('timeline')
                            .doc(sectionId)
                            .collection('registered_users')
                            .doc(sDoc.id).ref;
                          cache.push({ ref: ref, obj: obj });
                        }
                      }
                    }
                  }
                });
            });
          }
          return secPromise;
        })
    );
    promises = promises.then(() => {
      return this.commitCache(cache);
    });
    promises
      .then(() => {
        console.log('Update count ', updateCount);
      });
  }

  fillContentCreator() {
    const cache: IWriteCache[] = [];
    let promises = Promise.resolve(null);
    let updateCount = 0;
    promises = promises.then(() => {
      return this.afs.collection('app_users')
        .get()
        .toPromise()
        .then(users => {
          return users.docs.reduce((accum, it) => {
            accum[it.id] = it.data();
            return accum;
          }, {});
        });
    });
    promises = promises.then((users) =>
      this.afs.collection('events')
        .get()
        .toPromise()
        .then((snapshot) => {
          let secPromise = Promise.resolve();
          const events = snapshot.docs;
          let counter = 0;
          for (const doc of events) {
            secPromise = secPromise.then(() => {
              counter++;
              console.log(counter + ' of ' + events.length);
            });
            secPromise = secPromise.then(() => {
              return this.afs.collection('conference')
                .doc(doc.id)
                .collection('timeline')
                .get()
                .toPromise()
                .then((querySnapshot) => {
                  let cntPromise = Promise.resolve();
                  for (const sDoc of querySnapshot.docs) {
                    const section = sDoc.data();
                    const sObj = {};
                    if (section.hasOwnProperty('userId') && users[section.userId]) {
                      sObj['creator'] = {
                        userId: section.userId,
                        picture: !isEmpty(users[section.userId].picture) ? users[section.userId].picture : null,
                        fullName: !isEmpty(users[section.userId].fullName) ? users[section.userId].fullName : null,
                        department: !isEmpty(users[section.userId].department) ? users[section.userId].department : null
                      };
                    }
                    if (!isEmpty(sObj)) {
                      updateCount++;
                      const ref = this.afs.collection('conference')
                        .doc(doc.id)
                        .collection('timeline')
                        .doc(sDoc.id).ref;
                      cache.push({ ref: ref, obj: sObj });
                    }
                    cntPromise = cntPromise.then(() => this.afs.collection('conference')
                      .doc(doc.id)
                      .collection('timeline')
                      .doc(sDoc.id)
                      .collection('contents')
                      .get()
                      .toPromise()
                      .then((cntSnapshot) => {
                        for (const cntDoc of cntSnapshot.docs) {
                          const content = cntDoc.data();
                          const cObj = {};
                          if (content.hasOwnProperty('userId') && users[content.userId]) {
                            cObj['creator'] = {
                              userId: content.userId,
                              picture: !isEmpty(users[content.userId].picture) ? users[content.userId].picture : null,
                              fullName: !isEmpty(users[content.userId].fullName) ? users[content.userId].fullName : null,
                              department: !isEmpty(users[content.userId].department) ? users[content.userId].department : null
                            };
                          }
                          if (!isEmpty(cObj)) {
                            updateCount++;
                            const cRef = this.afs.collection('conference')
                              .doc(doc.id)
                              .collection('timeline')
                              .doc(sDoc.id)
                              .collection('contents')
                              .doc(cntDoc.id).ref;
                            cache.push({ ref: cRef, obj: cObj });
                          }
                        }
                      }));
                  }
                });
            });
          }
          return secPromise;
        })
    );
    promises = promises.then(() => {
      return this.commitCache(cache);
    });
    promises
      .then(() => {
        console.log('Update count ', updateCount);
      });
  }

  usersToCSV() {
    const cache: IWriteCache[] = [];
    let updateCount = 0;
    let promises = Promise.resolve(null);
    const data = [];
    promises = promises.then(() => {
      return this.afs.collection('app_users')
        .get()
        .toPromise()
        .then(users => {
          for (const doc of users.docs) {
            const user: any = doc.data();
            data.push({
              id: doc.id,
              email: user.email,
              userName: user.userName,
              fullName: user.fullName,
              picture: user.picture
            });
            const obj = {};
            if (!isEmpty(user.email) && isEmpty(user.userName)) {
              obj['userName'] = this.utils.extractDefaultDisplayNameFromEmail(user.email);
            }
            if (!isEmpty(user.email) && isEmpty(user.fullName)) {
              obj['fullName'] = this.utils.extractDefaultDisplayNameFromEmail(user.email);
            }
            if (!isEmpty(obj)) {
              updateCount++;
              const ref = this.afs.collection('app_users')
                .doc(doc.id).ref;
              cache.push({ ref: ref, obj: obj });
            }
          }
        });
    });
    promises = promises.then(() => {
      const headers = ['id', 'email', 'userName', 'fullName', 'picture'];
      const fields = ['id', 'email', 'userName', 'fullName', 'picture'];
      this.utils.exportToCsvAndDownload(headers, fields, data);
      return this.commitCache(cache);
    });
    promises
      .then(() => {
        console.log('Finished');
      });
  }

  fillUserRegistrationNames() {
    const cache: IWriteCache[] = [];
    let promises = Promise.resolve(null);
    let updateCount = 0;
    promises = promises.then(() => {
      return this.afs.collection('app_users')
        .get()
        .toPromise()
        .then(users => {
          return users.docs.reduce((accum, it) => {
            accum[(it.data() as any).email] = it.data();
            return accum;
          }, {});
        });
    });
    promises = promises.then((users) =>
      this.afs.collection('events')
        .get()
        .toPromise()
        .then((snapshot) => {
          let secPromise = Promise.resolve();
          const events = snapshot.docs;
          let counter = 0;
          for (const doc of events) {
            secPromise = secPromise.then(() => {
              counter++;
              console.log(counter + ' of ' + events.length);
            });
            secPromise = secPromise.then(() => {
              return this.afs.collection('conference')
                .doc(doc.id)
                .collection('user_registration')
                .get()
                .toPromise()
                .then((querySnapshot) => {
                  for (const sDoc of querySnapshot.docs) {
                    const userReg: any = sDoc.data();
                    const sObj = {};
                    if (userReg.hasOwnProperty('sections')) {
                      for (const key of Object.keys(userReg.sections)) {
                        const section = userReg.sections[key];
                        if (!isEmpty(users[section.email]) && section.hasOwnProperty('firstName')) {
                          sObj['firstName'] = firebase.firestore.FieldValue.delete();
                        }
                        if (!isEmpty(users[section.email]) && section.hasOwnProperty('lastName')) {
                          sObj['lastName'] = firebase.firestore.FieldValue.delete();
                        }
                      }
                    }
                    if (!isEmpty(sObj)) {
                      updateCount++;
                      const ref = this.afs.collection('conference')
                        .doc(doc.id)
                        .collection('timeline')
                        .doc(sDoc.id).ref;
                      cache.push({ ref: ref, obj: sObj });
                    }
                  }
                });
            });
          }
          return secPromise;
        })
    );
    promises = promises.then(() => {
      return this.commitCache(cache);
    });
    promises
      .then(() => {
        console.log('Update count ', updateCount);
      });
  }

  commitCache(cache: IWriteCache[], options: SetOptions = { merge: true }) {
    const COMMIT_COUNT = 250;
    this.batch = this.afs.firestore.batch();
    let promise = Promise.resolve();
    let counter = 0;
    let commitCounter = 0;
    for (const task of cache) {
      promise = promise.then(() => {
        counter++;
        commitCounter++;
        this.batch.set(task.ref, task.obj, task.options ? task.options : options);
        if (counter >= COMMIT_COUNT) {
          return this.batch.commit().then(() => {
            console.log('Committed ' + commitCounter + ' of ' + cache.length);
            this.progress.next('Committed ' + commitCounter + ' of ' + cache.length);
            counter = 0;
            this.batch = this.afs.firestore.batch();
            return new Promise(resolve => setTimeout(resolve, 1000));
          });
        }
        return Promise.resolve();
      });
    }
    promise = promise.then(() => {
      if (counter > 0) {
        return this.batch.commit().then(() => {
          counter = 0;
          console.log('Committed ' + cache.length + ' of ' + cache.length);
          this.progress.next('Committed ' + cache.length + ' of ' + cache.length);
          this.batch = this.afs.firestore.batch();
        });
      }
      return Promise.resolve();
    });
    promise.then(() => {
      console.log('Commit finished.');
      this.progress.next('Commit finished.');
    });
    return promise;
  }

  commitCacheSafe(cache: IWriteCache[], options: SetOptions = { merge: true }) {
    const COMMIT_COUNT = 100;
    this.batch = this.afs.firestore.batch();
    let promise = Promise.resolve();
    let counter = 0;
    let commitCounter = 0;
    for (const task of cache) {
      promise = promise.then(() => {
        counter++;
        commitCounter++;
        this.batch.set(task.ref, task.obj, task.options ? task.options : options);
        if (counter >= COMMIT_COUNT) {
          return this.batch.commit().then(() => {
            console.log('Committed ' + commitCounter + ' of ' + cache.length);
            this.progress.next('Committed ' + commitCounter + ' of ' + cache.length);
            counter = 0;
            this.batch = this.afs.firestore.batch();
            return new Promise(resolve => setTimeout(resolve, 30000));
          });
        }
        return Promise.resolve();
      });
    }
    promise = promise.then(() => {
      if (counter > 0) {
        return this.batch.commit().then(() => {
          counter = 0;
          console.log('Committed ' + cache.length + ' of ' + cache.length);
          this.progress.next('Committed ' + cache.length + ' of ' + cache.length);
          this.batch = this.afs.firestore.batch();
        });
      }
      return Promise.resolve();
    });
    promise.then(() => {
      console.log('Commit finished.');
      this.progress.next('Commit finished.');
    });
    return promise;
  }

  private commitConfirmDialog(cache: IWriteCache[]) {
    this.commonService.confirm(null, 'Commit data?' + ' (' + cache.length + ')')
      .pipe(take(1))
      .subscribe(res => {
        if (res) {
          this.commitCache(cache).then(() => console.log('End process'))
            .then(() => console.log('End update cache length', cache.length));
        } else {
          console.log('Commit canceled');
        }
      });
  }

  replaceFeatureLine() {
    const setFeatureLine = (eventId: string, timelineId: string) => {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection('actions')
        .doc('featureline')
        .set({ featureline: timelineId }, { merge: true });
    };

    let promises = Promise.resolve();
    let allCount = 0;
    let updateCount = 0;
    let skipCount = 0;
    promises = promises.then(() =>
      this.afs.collection('conference')
        .get()
        .toPromise()
        .then((snapshot) => {
          let secPromise = Promise.resolve(true);
          const conferences = snapshot.docs;
          allCount = conferences.length;
          let counter = 0;
          for (const doc of conferences) {
            const conference: any = doc.data();
            const featureLine = conference.current_action ? conference.current_action.featureline : null;
            if (featureLine) {
              secPromise = secPromise.then(() => setFeatureLine(doc.id, featureLine)
                .then(() => {
                  const obj = {
                    current_action: {
                      featureline: firebase.firestore.FieldValue.delete()
                    }
                  };
                  return this.afs.collection('conference')
                    .doc(doc.id)
                    .set(obj, { merge: true })
                    .then(() => {
                      ++updateCount;
                      console.log('Update ', ++counter, ' of ', allCount);
                      return Promise.resolve(true);
                    });
                }));
            } else {
              secPromise = secPromise.then(() => {
                console.log('Skip ', ++counter, ' of ', allCount);
                ++skipCount;
                return Promise.resolve(true);
              });
            }
          }
          secPromise.then(() => console.log('End update', ' updated', updateCount, ' skipped', skipCount));
        })
    );
  }

  changeOldQuestionTypeCorrectToCheck() {
    let allCount = 0;
    let updateCount = 0;
    let eventCount = 0;
    this.afs.collection('events')
      .get()
      .pipe(take(1))
      .toPromise()
      .then((snapshot) => {
        let secPromise = Promise.resolve();
        const events = snapshot.docs;
        allCount = events.length;
        for (const doc of events) {
          secPromise = secPromise.then(() =>
            this.afs.collection('conference')
              .doc(doc.id)
              .collection('timeline')
              .get()
              .pipe(take(1))
              .toPromise()
              .then(snapshotSections => {
                let cntPromise = Promise.resolve();
                const sections = snapshotSections.docs;
                if (sections.length > 0) {
                  for (const section of sections) {
                    cntPromise = cntPromise.then(() =>
                      this.afs.collection('conference')
                        .doc(doc.id)
                        .collection('timeline')
                        .doc(section.id)
                        .collection('contents')
                        .get()
                        .pipe(take(1))
                        .toPromise()
                        .then((contentsSnapshot) => {
                          const contents = contentsSnapshot.docs;
                          let ctPromise = Promise.resolve();
                          if (contents.length > 0) {
                            for (const contentObj of contents) {
                              const content: any = contentObj.data();
                              if (content.type === Constants.CONTENT_TYPE_QUESTIONNAIRE) {
                                const qContent = new QuestionnaireContent(content);
                                const questions = qContent.questions;
                                let edited = false;
                                for (const qId of Object.keys(questions)) {
                                  if (questions[qId].storypoint === 2) {
                                    questions[qId].storypoint = Constants.QTYPE_CHECK;
                                    questions[qId].useCorrectAnswers = true;
                                    edited = true;
                                  }
                                }
                                if (edited) {
                                  ctPromise = ctPromise.then(() => {
                                    return contentObj.ref.update(qContent.toObject()).then(() => {
                                      console.log('Update content count', ++updateCount);
                                      return Promise.resolve();
                                    });
                                  });
                                }
                              }
                            }
                          }
                          return ctPromise;
                        })
                    );
                  }
                  return cntPromise;
                }
              })
              .then(() => {
                console.log('Event count', ++eventCount, ' of ', allCount);
                return Promise.resolve();
              })
          );
        }
        return secPromise;
      }).then(() => {
        console.log('End update');
      });
  }


  /*
    timeZoneTest() {
      const rows = TimeZoneCSV.csv.split('\n');
      const listObjects: {timeName: string, idList: string[], utc: string}[] = [];
      for (const row of rows) {
        const ra = row.split(';');
        let obj = listObjects.find(o => o.timeName === ra[2]);
        if (isEmpty(obj)) {
          let utc = '';
          if (ra[1].startsWith('-')) {
            const usp = ra[1].replace(/\s/g, '');
            const u = usp.split(':');
            if (u[0].startsWith('-') && u[0].length === 2) {
              u[0] = 'UTC -0' + u[0][1];
              utc = u[0] + ':' + u[1];
            } else {
              utc = usp;
            }
          } else {
            utc = 'UTC +' + ra[1];
          }
          obj = {timeName: ra[2], idList: [ra[0]], utc: utc};
          listObjects.push(obj);
        } else {
          obj.idList.push(ra[0]);
        }
      }
      console.log(JSON.stringify(listObjects));
    }
  */

  printUserVersionList() {
    const offset = new Date().getTimezoneOffset();
    const valStart = this.el.nativeElement.valueAsNumber + offset * 60 * 1000;
    const valEnd = valStart + (24 * 60 * 60 * 1000);
    const clientId = this.loginService.client_id$.getValue();
    console.log('clientId', clientId);
    this.afDB.list(`client_data/${clientId}/user_log`, q => q.orderByChild('date').startAt(valStart).endAt(valEnd))
      .snapshotChanges()
      .pipe(first())
      .toPromise()
      .then((values) => {
        const filtered = values
          .map(it => {
            const val: any = new Object(it.payload.val());
            return { id: it.key, dateValue: this.utils.formatDate(val.date, DATE_FORMAT.DD_MM_YYYY_HH_mm), ...val };
          })
          .sort(this.utils.comparator('date'));
        console.log('filtered', filtered);
      });
  }

  getUser(id: string, logs = true) {
    const clientId = this.loginService.client_id$.getValue();
    return firstValueFrom(this.afs.collection('client_data').doc(clientId)
      .collection('app_users').doc(id)
      .get())
      .then((user) => {
        if (logs) {
          console.log(id, user.data());
        }
        return user.data();
      });
  }

  startEventInfoBeginFromCurrentDate() {
    const clientId = this.loginService.client_id$.getValue();
    console.log('clientId', clientId);
    console.log('clientId', clientId);
    const currentTime = new Date().getTime();
    const result: {
      [day: string]: { startDate: string, duration: number, eventId: string, hkvbs_id: string, eventName: string, shortLink: string; }[];
    } = {};
    let promises = Promise.resolve();
    let updateCount = 0;
    promises = promises.then(() =>
      this.afs
        .collection('client_data').doc(clientId)
        .collection('events')
        .get()
        .toPromise()
        .then((snapshot) => {
          let secPromise = Promise.resolve();
          const events = snapshot.docs;
          for (const doc of events) {
            const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
            if (!!event.deleted) {
              continue;
            }
            secPromise = secPromise.then(() =>
              this.afs
                .collection('client_data').doc(clientId)
                .collection('conference').doc(doc.id)
                .collection('timeline')
                .get()
                .toPromise()
                .then((snapshotSections) => {
                  updateCount++;
                  console.log(updateCount + ' of ' + events.length);
                  let sections: SectionContent[] = snapshotSections.docs.map(d => new SectionContent(d.data()));
                  sections = sections.sort((a, b) => a.plannedTime >= b.plannedTime ? 1 : -1);
                  for (const section of sections) {
                    if (section.plannedTime && currentTime < section.plannedTime) {
                      const day = this.utils.getDateDay(section.plannedTime);
                      if (isEmpty(result[day])) {
                        result[day] = [];
                        result[day].push({
                          startDate: this.utils.formatDate(section.plannedTime, DATE_FORMAT.DD_MM_YYYY_HH_mm),
                          duration: section.duration,
                          eventId: event.eventId, hkvbs_id: event.hkvbsIntegrationId, eventName: event.name, shortLink: event.shortLink
                        });
                      } else if (!result[day].find(o => o.eventId === event.eventId)) {
                        result[day].push({
                          startDate: this.utils.formatDate(section.plannedTime, DATE_FORMAT.DD_MM_YYYY_HH_mm),
                          duration: section.duration,
                          eventId: event.eventId, hkvbs_id: event.hkvbsIntegrationId, eventName: event.name, shortLink: event.shortLink
                        });
                      }
                    }
                  }
                  return Promise.resolve();
                })
            );
          }
          return secPromise;
        })
    );
    promises
      .then(() => {
        console.log('count ', updateCount);
        for (const day of Object.keys(result).sort((a, b) => a > b ? 1 : -1)) {
          console.log(this.utils.formatDate(Number(day), DATE_FORMAT.DD_MM_YYYY),
            result[day].sort((a, b) => a.startDate >= b.startDate ? 1 : -1));
        }
      });
  }

  checkAppUsersWithoutName(update = false) {
    const checkResult = (uMain: AppUser, uClient: AppUser): string => {
      let res = '';
      if (uMain.userName !== uClient.userName) {
        res = 'USER_NAME';
      }
      if (uMain.fullName !== uClient.fullName) {
        res = res ? res + ', FULL_NAME' : 'FULL_NAME';
      }
      if (uMain.picture !== uClient.picture) {
        res = res ? res + ', PICTURE' : 'PICTURE';
      }
      return res;
    };
    const clientId = this.loginService.client_id$.getValue();
    console.log('ClientId', clientId);
    const result: any[] = [];
    const cache: IWriteCache[] = [];
    console.log('Get main app_users...');
    return this.afs.collection('app_users')
      .get()
      .toPromise()
      .then((snap) => {
        const mainUsers = snap.docs.map(it => new AppUser({ userId: it.id, ...(it.data() as any) }));
        console.log('Get client app_users...');
        return this.afs.collection('client_data').doc(clientId)
          .collection('app_users')
          .get()
          .toPromise()
          .then((snap2) => {
            const clientUsers = snap2.docs.map(it => new AppUser({ userId: it.id, ...(it.data() as any) }));
            console.log('Start check...');
            let counter = 0;
            for (const clientUser of clientUsers) {
              counter++;
              console.log('Check ', counter, ' of ', clientUsers.length);
              const mainUser = mainUsers.find(it => it.userId === clientUser.userId);
              if (mainUser && !mainUser.guest && (mainUser.userName !== clientUser.userName || mainUser.fullName !== clientUser.fullName ||
                mainUser.picture !== clientUser.picture)) {
                result.push({
                  check_result: checkResult(mainUser, clientUser),
                  id: mainUser.userId,
                  email: mainUser.email,
                  main: mainUser,
                  client: clientUser
                });
                if (update) {
                  const ref = this.afs.collection('client_data').doc(clientId).collection('app_users').doc(clientUser.userId).ref;
                  const obj = { userName: mainUser.userName, fullName: mainUser.fullName, picture: mainUser.picture };
                  cache.push({ ref: ref, obj: obj });
                }
              }
              if (!mainUser) {
                console.log('Main user ', clientUser, 'not found.');
              }
            }
            console.log(result);
            console.log('Count client app_users without name', result.length);
            if (update) {
              return this.commitCache(cache).then(() => {
                console.log('End update');
                return Promise.resolve(result);
              });
            }
            return Promise.resolve(result);
          });
      });
  }

  createDefaultClient() {
    const client = new ClientConfig({
      name: 'Timeline Click'
    });
    this.clientApiService.createDefaultClient(client);
  }

  checkEventWithOldContentType(clientId?: string, markAsDelete?: boolean) {
    if (!clientId) {
      clientId = this.loginService.client_id$.getValue();
    }
    const result: { eventId: string, eventName: string, shortLink: string, createDate: string, statistic: string; }[] = [];
    let updateCount = 0;
    if (!clientId) {
      console.log('clientId is null.');
      return;
    }
    clientId = clientId.trim();
    console.log('clientId: ', clientId);
    let eventsLength = 0;
    const eventsRef = {};
    this.afs.collection('client_data').doc(clientId)
      .collection('events')
      .get()
      .toPromise()
      .then((snapshot) => {
        let secPromise = Promise.resolve();
        const events = snapshot.docs;
        eventsLength = events.length;
        for (const doc of events) {
          const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
          if (!!event.deleted) {
            eventsLength--;
            continue;
          }
          eventsRef[doc.id] = doc.ref;
          secPromise = secPromise.then(() =>
            this.afs.collection('client_data').doc(clientId)
              .collection('conference')
              .doc(doc.id)
              .collection('timeline')
              .get()
              .toPromise()
              .then((snapshotSections) => {
                updateCount++;
                console.log(updateCount + ' of ' + eventsLength);
                const sections: SectionContent[] = snapshotSections.docs.map(d => new SectionContent({ id: d.id, ...(d.data() as any) }));
                let contentsPromise = Promise.resolve(null);
                for (const section of sections) {
                  contentsPromise = contentsPromise.then(() => {
                    if (result.findIndex(r => r.eventId === event.eventId) > -1) {
                      return Promise.resolve();
                    }
                    return this.afs.collection('client_data').doc(clientId)
                      .collection('conference')
                      .doc(doc.id)
                      .collection('timeline')
                      .doc(section.id)
                      .collection('contents')
                      .get()
                      .toPromise().then((snapContents) => {
                        if (snapContents.docs.some(cd =>
                          [Constants.CONTENT_TYPE_TEXT,
                          Constants.CONTENT_TYPE_SLIDE,
                          Constants.CONTENT_TYPE_CLIPBOARD,
                          Constants.CONTENT_TYPE_LINK].includes(cd.data()['type']))) {
                          if (result.findIndex(r => r.eventId === event.eventId) < 0) {
                            const cStat: { cText: number, cSlide: number, cClipboard: number, cLink: number; } = snapContents.docs
                              .reduce((acc, it) => {
                                const type = it.data()['type'];
                                switch (type) {
                                  case Constants.CONTENT_TYPE_TEXT:
                                    acc.cText++;
                                    break;
                                  case Constants.CONTENT_TYPE_SLIDE:
                                    acc.cSlide++;
                                    break;
                                  case Constants.CONTENT_TYPE_CLIPBOARD:
                                    acc.cClipboard++;
                                    break;
                                  case Constants.CONTENT_TYPE_LINK:
                                    acc.cLink++;
                                    break;
                                  default:
                                    break;
                                }
                                return acc;
                              }, { cText: 0, cSlide: 0, cClipboard: 0, cLink: 0 });
                            result.push({
                              eventId: event.eventId,
                              eventName: event.name,
                              shortLink: 'https://timeline-p10.firebaseapp.com/event/' + event.shortLink,
                              createDate: this.utils.formatDate(event.createDate, DATE_FORMAT.DD_MM_YYYY),
                              statistic: JSON.stringify(cStat)
                            });
                          }
                        }
                        return Promise.resolve();
                      });
                  });
                }
                return contentsPromise.then(() => Promise.resolve());
              })
          );
        }
        return secPromise;
      }).then(() => {
        console.log(result);
        console.log('Count event with old contents: ', result.length);
        if (!markAsDelete) {
          this.utils.exportToCsvAndDownload(
            ['createDate', 'eventName', 'Link', 'eventId', 'statistic'],
            ['createDate', 'eventName', 'shortLink', 'eventId', 'statistic'], result, 'old_event_list');
        } else {
          /*
                  const cache: IWriteCache[] = [];
                  for (const obj of result) {
                    const eventId = obj.eventId;
                    const ref = eventsRef[eventId];
                    if (ref) {
                      cache.push({ref: ref, obj: {deleted: 3}});
                    }
                  }
                  this.commitCache(cache);
          */
        }
      });
  }

  private fixedUsersPin(allUsers: { [userId: string]: AppUser; }) {
    const clientId = this.loginService.client_id$.getValue();
    const cache: IWriteCache[] = [];
    let updateCount = 0;
    console.log('clientId: ', clientId);
    let eventsLength = 0;
    this.afs.collection('client_data').doc(clientId)
      .collection('events')
      .get()
      .toPromise()
      .then((snapshot) => {
        let secPromise = Promise.resolve();
        const events = snapshot.docs;
        eventsLength = events.length;
        for (const doc of events) {
          const event = new Event({ eventId: doc.id, ...doc.data() });
          if (!!event.deleted) {
            eventsLength--;
            continue;
          }
          secPromise = secPromise.then(() =>
            this.afs.collection('client_data').doc(clientId)
              .collection('conference')
              .doc(event.eventId)
              .collection('timeline')
              .get()
              .toPromise()
              .then((snapshotSections) => {
                updateCount++;
                console.log(updateCount + ' of ' + eventsLength);
                const sections: SectionContent[] = snapshotSections.docs.map(d => new SectionContent({ id: d.id, ...d.data() }));
                let contentsPromise = Promise.resolve(null);
                let sIndex = 0;
                for (const section of sections) {
                  contentsPromise = contentsPromise.then(() => {
                    return this.afs.collection('client_data').doc(clientId)
                      .collection('conference')
                      .doc(event.eventId)
                      .collection('timeline')
                      .doc(section.id)
                      .collection('contents')
                      .get()
                      .toPromise().then((snapContents) => {
                        let cPromise = Promise.resolve();
                        for (const cDoc of snapContents.docs) {
                          cPromise = cPromise.then(() =>
                            this.fixedContentPinMetadata(clientId, event.eventId, section.id, cDoc.id, cache, allUsers)
                              .then(() =>
                                this.fixedContentPersonalPinMetadata(clientId, event.eventId, section.id, cDoc.id, cache, allUsers)));
                        }
                        return cPromise.then(() => {
                          console.log('Event shortLink ', event.shortLink, ' section', ++sIndex, ' of ', sections.length);
                          return Promise.resolve();
                        });
                      });
                  });
                }
                return contentsPromise.then(() => {
                  console.log('Prep update event ', event.name, ' shortLink', event.shortLink);
                  return Promise.resolve();
                });
              })
          );
        }
        return secPromise;
      }).then(() => {
        this.commitCache(cache).then(() => console.log('End update'));
      });
  }

  private fixedContentPinMetadata(clientId, eventId, sectionId, contentId, cache: IWriteCache[], allUsers: { [userId: string]: AppUser; }) {
    return this.afs.collection('client_data').doc(clientId)
      .collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('contents')
      .doc(contentId)
      .collection('pin_metadata')
      .get().toPromise().then((snapData) => {
        for (const mDoc of snapData.docs) {
          const mData = mDoc.data();
          if (mData['userId'] === mData['userName']) {
            const user = allUsers[mData['userId']];
            if (user) {
              const obj = { userName: user.fullName };
              const upRef = this.afs.collection('client_data').doc(clientId).collection('conference').doc(eventId).collection('timeline')
                .doc(sectionId).collection('contents').doc(contentId).collection('pin_metadata').doc(mDoc.id).ref;
              cache.push({ ref: upRef, obj: obj });
            }
          }
        }
      });
  }

  private fixedContentPersonalPinMetadata(clientId, eventId, sectionId, contentId, cache: IWriteCache[],
    allUsers: { [userId: string]: AppUser; }) {
    return this.afs.collection('client_data').doc(clientId)
      .collection('conference')
      .doc(eventId)
      .collection('timeline')
      .doc(sectionId)
      .collection('contents')
      .doc(contentId)
      .collection('pin_metadata_personal')
      .get().toPromise().then((snapData) => {
        for (const mDoc of snapData.docs) {
          const mData = mDoc.data();
          if (mData['userId'] === mData['userName']) {
            const user = allUsers[mData['userId']];
            if (user) {
              const obj = { userName: user.fullName };
              const upRef = this.afs.collection('client_data').doc(clientId).collection('conference').doc(eventId).collection('timeline')
                .doc(sectionId).collection('contents').doc(contentId).collection('pin_metadata_personal').doc(mDoc.id).ref;
              cache.push({ ref: upRef, obj: obj });
            }
          }
        }
      });
  }


  private fixedUsersNameInEventChat(allUsers: { [userId: string]: AppUser; }) {
    const clientId = this.loginService.client_id$.getValue();
    const cache: IWriteCache[] = [];
    let updateCount = 0;
    console.log('clientId: ', clientId);
    let eventsLength = 0;
    this.afs.collection('client_data').doc(clientId)
      .collection('events')
      .get()
      .toPromise()
      .then((snapshot) => {
        let secPromise = Promise.resolve();
        const events = snapshot.docs;
        eventsLength = events.length;
        for (const doc of events) {
          const event = new Event({ eventId: doc.id, ...doc.data() });
          if (!!event.deleted) {
            eventsLength--;
            continue;
          }
          secPromise = secPromise.then(() => {
            return this.fixedChantUsersName(clientId, event.eventId, cache, allUsers)
              .then(() => console.log((++updateCount) + ' of ' + eventsLength));
          });
        }
        secPromise.then(() => this.commitCache(cache).then(() => console.log('End update')));
      });
  }

  private fixedChantUsersName(clientId, eventId, cache: IWriteCache[], allUsers: { [userId: string]: AppUser; }) {
    return this.afs.collection('client_data').doc(clientId)
      .collection('conference')
      .doc(eventId)
      .collection('chat_messages')
      .get().toPromise().then((msgSnap) => {
        for (const mDoc of msgSnap.docs) {
          const msg = mDoc.data();
          if (!msg['userName']) {
            const user = allUsers[msg['userId']];
            if (user) {
              const obj = { userName: user.fullName, picture: user.picture };
              const ref = this.afs.collection('client_data').doc(clientId).collection('conference').doc(eventId)
                .collection('chat_messages').doc(mDoc.id).ref;
              cache.push({ ref: ref, obj: obj });
            }
          }
        }
      });
  }

  fixStorageObjects() {
    const signLogo = (event: Event) => {
      if (!isEmpty(event.logo)) {
        if (this.utils.isFBUrl(event.logo, event.eventId)) {
          return this.eventDataService
            .getSignedUrl(`client_data/${clientId}/conference/${event.eventId}/logo/${event.eventId}.png`).then(url => {
              const obj = {
                logo: url ? url : null
              };
              return this.afs.collection('client_data').doc(clientId).collection('events')
                .doc(event.eventId)
                .set(obj, { merge: true });
            });
        }
        return Promise.resolve();
      }
      return Promise.resolve();
    };
    const signHorizontalBanner = (event: Event) => {
      if (!isEmpty(event.horizontalBanner)) {
        if (this.utils.isFBUrl(event.horizontalBanner, event.eventId)) {
          return this.eventDataService
            .getSignedUrl(`client_data/${clientId}/conference/${event.eventId}/horizontalBanner/horizontalBanner.png`).then(url => {
              const obj = {
                horizontalBanner: url ? url : null
              };
              return this.afs.collection('client_data').doc(clientId).collection('events')
                .doc(event.eventId)
                .set(obj, { merge: true });
            });
        }
        return Promise.resolve();
      }
      return Promise.resolve();
    };
    const signVerticalBanner = (event: Event) => {
      if (!isEmpty(event.verticalBanner)) {
        if (this.utils.isFBUrl(event.verticalBanner, event.eventId)) {
          return this.eventDataService
            .getSignedUrl(`client_data/${clientId}/conference/${event.eventId}/verticalBanner/verticalBanner.png`).then(url => {
              const obj = {
                verticalBanner: url ? url : null
              };
              return this.afs.collection('client_data').doc(clientId).collection('events')
                .doc(event.eventId)
                .set(obj, { merge: true });
            });
        }
        return Promise.resolve();
      }
      return Promise.resolve();
    };
    const signEventDesktopBackground = (event: Event) => {
      if (!isEmpty(event.background)) {
        if (this.utils.isFBUrl(event.background, event.eventId)) {
          return this.eventDataService
            .getSignedUrl(`client_data/${clientId}/conference/${event.eventId}/background/background.png`).then(url => {
              const obj = {
                background: url ? url : null
              };
              return this.afs.collection('client_data').doc(clientId).collection('events')
                .doc(event.eventId)
                .set(obj, { merge: true });
            });
        }
        return Promise.resolve();
      }
      return Promise.resolve();
    };
    const signEventMobileBackground = (event: Event) => {
      if (!isEmpty(event.mobileBackground)) {
        if (this.utils.isFBUrl(event.background, event.eventId)) {
          return this.eventDataService
            .getSignedUrl(`client_data/${clientId}/conference/${event.eventId}/mobileBackground/mobileBackground.png`).then(url => {
              const obj = {
                mobileBackground: url ? url : null
              };
              return this.afs.collection('client_data').doc(clientId).collection('events')
                .doc(event.eventId)
                .set(obj, { merge: true });
            });
        }
        return Promise.resolve();
      }
      return Promise.resolve();
    };
    const signSectionBackground = (event: Event, section: SectionContent) => {
      if (!isEmpty(section.backgroundImage)) {
        if (this.utils.isFBUrl(section.backgroundImage, event.eventId)) {
          return this.eventDataService
            .getSignedUrl(`client_data/${clientId}/conference/${event.eventId}/image/${section.id}/${section.id}.png`).then(url => {
              const obj = {
                backgroundImage: url ? url : null
              };
              return this.afs.collection('client_data').doc(clientId)
                .collection('conference')
                .doc(event.eventId)
                .collection('timeline')
                .doc(section.id)
                .set(obj, { merge: true })
                .then(() => console.log('update eventId: ' + event.eventId + ' sectionId: ' + section.id))
                .catch(error => console.log('signSectionBackground:', error));
            });
        }
        return Promise.resolve();
      }
      return Promise.resolve();
    };

    const clientId = this.loginService.client_id$.getValue();
    console.log('clientId: ', clientId);
    let updateCount = 0;
    let eventsLength = 0;
    this.afs.collection('client_data').doc(clientId)
      .collection('events')
      .get()
      .toPromise()
      .then((snapshot) => {
        const events = snapshot.docs;
        let secPromise = Promise.resolve();
        eventsLength = events.length;
        for (const doc of events) {
          const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
          secPromise = secPromise.then(() => {
            return this.afs
              .collection('client_data').doc(clientId)
              .collection('conference').doc(doc.id)
              .collection('timeline')
              .get()
              .toPromise()
              .then((snapshotSections) => {
                updateCount++;
                console.log(updateCount + ' of ' + eventsLength);
                const sections: SectionContent[] = snapshotSections.docs.map(d => new SectionContent({ id: d.id, ...d.data() }));
                let contentsPromise = Promise.resolve();
                for (const section of sections) {
                  contentsPromise = contentsPromise.then(() => {
                    return this.afs
                      .collection('client_data').doc(clientId)
                      .collection('conference').doc(doc.id)
                      .collection('timeline')
                      .doc(section.id)
                      .collection('contents')
                      .get().toPromise().then(contentsSnap => {
                        let contentPromise = signSectionBackground(event, section);
                        const contentsDocs = contentsSnap.docs;
                        for (const contentDoc of contentsDocs) {
                          const content = new Object({ id: contentDoc.id, ...contentDoc.data() });
                          if (content['type'] === Constants.CONTENT_TYPE_MODULAR ||
                            content['type'] === Constants.CONTENT_TYPE_TASK_DOCUMENT ||
                            content['type'] === Constants.CONTENT_TYPE_QUESTIONNAIRE) {
                            contentPromise = contentPromise.then(() => {
                              if (content['type'] === Constants.CONTENT_TYPE_TASK_DOCUMENT) {
                                return this.resignTaskDocumentContent(clientId,
                                  { eventId: event.eventId, shortLink: event.shortLink, eventName: event.name, startDate: event.startDate },
                                  section.id, new TaskDocument(content));
                              }
                              if (content['type'] === Constants.CONTENT_TYPE_MODULAR) {
                                return this.resignModularContent(clientId,
                                  { eventId: event.eventId, shortLink: event.shortLink, eventName: event.name, startDate: event.startDate },
                                  section.id, new ModularContent(content));
                              }
                            });
                          }
                        }
                        return contentPromise;
                      });
                  });
                }
                return contentsPromise;
              });
          }).then(() => signLogo(event))
            .then(() => signHorizontalBanner(event))
            .then(() => signVerticalBanner(event))
            .then(() => signEventDesktopBackground(event))
            .then(() => signEventMobileBackground(event))
            .then(() => console.log('Event ' + event.eventId + ' updated.'));
        }
        return secPromise;
      }).then(() => {
        console.log('End process');
      });
  }

  checkEventsWithUnsupportedContents() {
    const clientId = this.loginService.client_id$.getValue();
    let updateCount = 0;
    let eventsLength = 0;
    console.log('clientId: ', clientId);
    const log: { [eventId: string]: { eventStartDateStr, eventCreateDate, eventName, eventShortLink, eventId, startDate; }; } = {};
    const eventsLogged = {};
    this.afs.collection('client_data').doc(clientId)
      .collection('events')
      .get()
      .toPromise()
      .then((snapshot) => {
        const events = snapshot.docs;
        let secPromise = Promise.resolve();
        eventsLength = events.length;
        for (const doc of events) {
          const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
          if (event.deleted) {
            continue;
          }
          secPromise = secPromise.then(() => {
            return this.afs
              .collection('client_data').doc(clientId)
              .collection('conference').doc(doc.id)
              .collection('timeline')
              .get()
              .toPromise()
              .then(async (snapshotSections) => {
                updateCount++;
                console.log(updateCount + ' of ' + eventsLength);
                const sections: SectionContent[] = snapshotSections.docs.map(d => new SectionContent({ id: d.id, ...d.data() }));
                for (const section of sections) {
                  if (log[event.eventId]) {
                    break;
                  }
                  await this.afs
                    .collection('client_data').doc(clientId)
                    .collection('conference').doc(event.eventId)
                    .collection('timeline')
                    .doc(section.id)
                    .collection('contents')
                    .get().toPromise().then(contentsSnap => {
                      const contentsDocs = contentsSnap.docs;
                      for (const contentDoc of contentsDocs) {
                        const content = new Object({ id: contentDoc.id, ...contentDoc.data() });
                        if (content['type'] === Constants.CONTENT_TYPE_LINK ||
                          content['type'] === Constants.CONTENT_TYPE_CLIPBOARD ||
                          content['type'] === Constants.CONTENT_TYPE_COMPETITION ||
                          content['type'] === Constants.CONTENT_TYPE_SEPARATOR ||
                          content['type'] === Constants.CONTENT_TYPE_SLIDE ||
                          content['type'] === Constants.CONTENT_TYPE_TEXT) {
                          log[event.eventId] = {
                            eventStartDateStr: this.utils.formatDate(event.startDate, DATE_FORMAT.DD_MM_YYYY),
                            eventCreateDate: this.utils.formatDate(event.createDate, DATE_FORMAT.DD_MM_YYYY),
                            eventName: event.name,
                            eventShortLink: event.shortLink,
                            eventId: event.eventId,
                            startDate: event.startDate
                          };
                          break;
                        }
                      }
                    });
                }
              });
          });
        }
        return secPromise;
      }).then(() => {
        console.log('End process');
        const aLog = Object.keys(log).map(k => log[k]).sort(this.utils.comparator('startDate'));
        console.log(aLog);
      });
  }

  /*
    checkEventsWithUnsupportedContents() {
      const clientId = this.loginService.client_id$.getValue();
      let updateCount = 0;
      let eventsLength = 0;
      console.log('clientId: ', clientId);
      const log:  {[eventId: string]: {eventStartDateStr, eventName, eventShortLink, eventId, startDate}} = {};
      const eventsLogged = {};
      this.afs.collection('client_data').doc(clientId)
        .collection('events')
        .get()
        .toPromise()
        .then((snapshot) => {
          const events = snapshot.docs;
          let secPromise = Promise.resolve();
          eventsLength = events.length;
          for (const doc of events) {
            const event = new Event({eventId: doc.id, ...(doc.data() as any)});
            if (event.eventId !== '4787110217252864') {
              continue;
            }
            secPromise = secPromise.then(() => {
              return this.afs
                .collection('client_data').doc(clientId)
                .collection('conference').doc(doc.id)
                .collection('timeline')
                .get()
                .toPromise()
                .then((snapshotSections) => {
                  updateCount++;
                  console.log(updateCount + ' of ' + eventsLength);
                  const sections: SectionContent[] = snapshotSections.docs.map(d => new SectionContent({id: d.id, ...d.data()}));
                  let contentsPromise = Promise.resolve();
                  for (const section of sections) {
                    contentsPromise = contentsPromise.then(() => {
                      return this.afs
                        .collection('client_data').doc(clientId)
                        .collection('conference').doc(doc.id)
                        .collection('timeline')
                        .doc(section.id)
                        .collection('contents')
                        .get().toPromise().then(contentsSnap => {
                          const contentsDocs = contentsSnap.docs;
                          for (const contentDoc of contentsDocs) {
                            const content = new Object({id: contentDoc.id, ...contentDoc.data()});
                            if (content['type'] === Constants.CONTENT_TYPE_LINK ||
                              content['type'] === Constants.CONTENT_TYPE_CLIPBOARD ||
                              content['type'] === Constants.CONTENT_TYPE_COMPETITION ||
                              content['type'] === Constants.CONTENT_TYPE_SEPARATOR ||
                              content['type'] === Constants.CONTENT_TYPE_SLIDE ||
                              content['type'] === Constants.CONTENT_TYPE_TEXT) {
                              log[event.eventId] = {
                                eventStartDateStr: this.utils.formatDate(event.startDate, DATE_FORMAT.DD_MM_YYYY),
                                eventName: event.name,
                                eventShortLink: event.shortLink,
                                eventId: event.eventId,
                                startDate: event.startDate
                              };
                              break;
                            }
                          }
                        });
                    });
                  }
                  return contentsPromise;
                });
            });
          }
          return secPromise;
        }).then(() => {
        console.log('End process');
        console.log(log);
      });
    }
  */

  requestUsers(clientId: string, size: number, startAt) {
    let queryFn = q => q.orderBy('email').limit(size);
    if (startAt) {
      queryFn = q => q.orderBy('email').limit(size).startAfter(startAt);
    }
    if (clientId) {
      return this.afs
        .collection('client_data').doc(clientId)
        .collection('app_users', queryFn)
        .get()
        .toPromise();
    }
    return this.afs
      .collection('app_users', queryFn)
      .get()
      .toPromise();
  }

  async addIndexToUser(reset = false) {
    const compareArrays = (a1: string[], a2: string[]): boolean => {
      return a1 && a1.length === a2.length && a1.every((v, i) => a2.includes(v));
    };
    const splitBySeparator = (value: string) => {
      return value.split(/[\s,.@_]+/);
    };
    const createIndex = (list: string[], value: string) => {
      if (value) {
        for (let i = 0; i < value.length; i++) {
          const substring = value.slice(0, i + 1);
          if (!list.includes(substring)) {
            list.push(substring);
          }
        }
      }
    };

    const updateClient = async (clientId) => {
      let updateCount = 0;
      const chunkSize = 250;
      let loading = true;
      let users_ = await this.requestUsers(clientId, chunkSize, null);
      while (loading) {
        const users = users_.docs;
        for (const doc of users) {
          const user = <AppUser>doc.data();
          const newIndex = [];
          if (user.fullName) {
            const keywords = splitBySeparator(user.fullName.toLowerCase());

            for (let i = 0; i < keywords.length; i++) {
              const splice = keywords.slice(i, keywords.length);
              const join = splice.join(' ');
              createIndex(newIndex, join);
            }
          }
          if (user.email) {
            createIndex(newIndex, user.email.toLowerCase());
          }
          if (reset) {
            newIndex.length = 0;
          }
          if (!compareArrays(user.searchIndex, newIndex)) {
            updateCount++;
            cache.push({ ref: doc.ref, obj: { searchIndex: newIndex } });
          }
        }
        console.log(updateCount);
        await this.commitCache(cache).then(() => {
          cache.length = 0;
        });
        if (users_.size === chunkSize) {
          users_ = await this.requestUsers(clientId, chunkSize, users_.docs[users_.docs.length - 1]);
        } else {
          loading = false;
        }
      }
    };

    const cache: IWriteCache[] = [];

    console.log(`Main started`);
    await updateClient(null);
    console.log(`Main finished`);

    const clients = await this.afs
      .collection('client_data')
      .get()
      .toPromise()
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const clientId of clients) {
      console.log(`Client ${clientId} started`);
      await updateClient(clientId);
      console.log(`Client ${clientId} finished`);
    }

    console.log('Finish!');
    this.progress.next('All tasks finished');

    return;
  }

  async updateModules() {
    const getPermissions = (m: EduModule) => {
      const permissions = [m.visibility];
      for (const owner of m.owners) {
        if (!permissions.includes(owner.id)) {
          permissions.push(owner.id);
        }
      }
      for (const manager of m.managers) {
        if (!permissions.includes(manager.id)) {
          permissions.push(manager.id);
        }
      }
      for (const viewer of m.viewers) {
        if (!permissions.includes(viewer.id)) {
          permissions.push(viewer.id);
        }
      }
      return permissions;
    };

    const users: AppUser[] = [];

    const clientId = this.loginService.client_id$.getValue();
    return firstValueFrom(this.afs
      .collection('client_data').doc(clientId)
      .collection(modulesCollectionName)
      .get())
      .then(async (snapshot) => {
        console.log('length: ', snapshot.docs.length);
        let count = 1;
        for (const doc of snapshot.docs) {
          const update: any = {};

          const module = <EduModule>doc.data();

          if (!module.owners) {
            module.owners = [];
            update.owners = [];
          }
          if (!module.managers) {
            module.managers = [];
            update.managers = [];
          }
          if (!module.viewers) {
            module.viewers = [];
            update.viewers = [];
          }
          if (module.createdByID && !module.owners.find(u => u.id === module.createdByID)) {
            let user = users.find(u => u.userId === module.createdByID);
            if (!user) {
              user = await firstValueFrom(this.afs
                .collection('app_users').doc(module.createdByID)
                .get())
                .then((dsnapshot) => {
                  return <AppUser>{ userId: dsnapshot.id, ...<any>dsnapshot.data() };
                });
              users.push(user);
            }

            if (user) {
              module.owners.push({
                id: user.userId,
                email: user.email,
                displayName: user.fullName,
                photoURL: user.picture
              });
              update.owners = module.owners;
              if (isEmpty(module.visibility)) {
                module.visibility = 'link';
                update.visibility = 'link';
              }
            }
          }
          if (isEmpty(module.visibility)) {
            module.visibility = 'everybody';
            update.visibility = 'everybody';
          }
          if (!module.permissions) {
            update.permissions = getPermissions(module);
          }
          console.log('update', count, module.id, module.name, module.createdByID, update);
          if (!isEmpty(update)) {
            await doc.ref.update(update);
          }
          count++;
        }
      });
  }

  async updateCurriculums() {
    const getPermissions = (curriculum: EduCurriculum) => {
      const permissions = [curriculum.visibility];
      for (const owner of curriculum.owners) {
        if (!permissions.includes(owner.id)) {
          permissions.push(owner.id);
        }
      }
      for (const manager of curriculum.managers) {
        if (!permissions.includes(manager.id)) {
          permissions.push(manager.id);
        }
      }
      for (const viewer of curriculum.viewers) {
        if (!permissions.includes(viewer.id)) {
          permissions.push(viewer.id);
        }
      }
      return permissions;
    };

    const users: AppUser[] = [];

    const updateByClient = (clientId: string) => {
      return firstValueFrom(this.afs
        .collection('client_data').doc(clientId)
        .collection(curriculumCollectionName)
        .get())
        .then(async (snapshot) => {
          console.log('length: ', snapshot.docs.length);
          let count = 1;
          for (const doc of snapshot.docs) {
            const update: any = {};

            const curriculum = <EduCurriculum>doc.data();

            if (!curriculum.owners) {
              curriculum.owners = [];
              update.owners = [];
            }
            if (!curriculum.managers) {
              curriculum.managers = [];
              update.managers = [];
            }
            if (!curriculum.viewers) {
              curriculum.viewers = [];
              update.viewers = [];
            }
            if (curriculum.createdByID && !curriculum.owners.find(u => u.id === curriculum.createdByID)) {
              let user = users.find(u => u.userId === curriculum.createdByID);
              if (!user) {
                user = await firstValueFrom(this.afs
                  .collection('app_users').doc(curriculum.createdByID)
                  .get())
                  .then((dsnapshot) => {
                    return <AppUser>{ userId: dsnapshot.id, ...<any>dsnapshot.data() };
                  });
                users.push(user);
              }

              if (user) {
                curriculum.owners.push({
                  id: user.userId,
                  email: user.email,
                  displayName: user.fullName,
                  photoURL: user.picture
                });
                update.owners = curriculum.owners;
                if (isEmpty(curriculum.visibility)) {
                  curriculum.visibility = 'link';
                  update.visibility = 'link';
                }
              }
            }
            if (isEmpty(curriculum.visibility)) {
              curriculum.visibility = 'everybody';
              update.visibility = 'everybody';
            }
            if (!curriculum.permissions) {
              update.permissions = getPermissions(curriculum);
            }
            console.log('update', count, curriculum.id, curriculum.name, curriculum.createdByID, update);
            if (!isEmpty(update)) {
              await doc.ref.update(update);
            }
            count++;
          }
        });
    };

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const client_id of clients) {
      console.log(`Client ${client_id} started`);
      await updateByClient(client_id);
      console.log(`Client ${client_id} finished`);
    }

    console.log('Finish!');
  }

  async changeOrdenationLabel() {

    const updateByClient = (clientId: string) => {
      return firstValueFrom(this.afs
        .collection('client_data').doc(clientId)
        .collection(curriculumCollectionName)
        .get())
        .then(async (snapshot) => {
          console.log('length: ', snapshot.docs.length);
          for (const doc of snapshot.docs) {
            const curriculum = <EduCurriculum>doc.data();
            for (const level of curriculum.levels as CurriculumLevel[]) {
              if (level.type === 'abc') { level.type = 'ABC'; }
            }

            console.log('Curriculum Update', curriculum.id, curriculum.name);
            await doc.ref.update(curriculum);
          }
        });
    };

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const client_id of clients) {
      console.log(`Client ${client_id} started`);
      await updateByClient(client_id);
      console.log(`Client ${client_id} finished`);
    }

    console.log('Finish!');
  }

  async updateLearningFields() {
    const getPermissions = (learningPath: EduLearninPath) => {
      const permissions = [learningPath.visibility];
      for (const owner of learningPath.owners) {
        if (!permissions.includes(owner.id)) {
          permissions.push(owner.id);
        }
      }
      for (const manager of learningPath.managers) {
        if (!permissions.includes(manager.id)) {
          permissions.push(manager.id);
        }
      }
      for (const viewer of learningPath.viewers) {
        if (!permissions.includes(viewer.id)) {
          permissions.push(viewer.id);
        }
      }
      return permissions;
    };

    const users: AppUser[] = [];

    const updateByClient = (clientId: string) => {
      return firstValueFrom(this.afs
        .collection('client_data').doc(clientId)
        .collection(learningPathsCollectionName)
        .get())
        .then(async (snapshot) => {
          console.log('length: ', snapshot.docs.length);
          let count = 1;
          for (const doc of snapshot.docs) {
            const update: any = {};

            const learningPath = <EduLearninPath>doc.data();

            if (!learningPath.owners) {
              learningPath.owners = [];
              update.owners = [];
            }
            if (!learningPath.managers) {
              learningPath.managers = [];
              update.managers = [];
            }
            if (!learningPath.viewers) {
              learningPath.viewers = [];
              update.viewers = [];
            }
            if (learningPath.createdByID && !learningPath.owners.find(u => u.id === learningPath.createdByID)) {
              let user = users.find(u => u.userId === learningPath.createdByID);
              if (!user) {
                user = await firstValueFrom(this.afs
                  .collection('app_users').doc(learningPath.createdByID)
                  .get())
                  .then((dsnapshot) => {
                    return <AppUser>{ userId: dsnapshot.id, ...<any>dsnapshot.data() };
                  });
                users.push(user);
              }

              if (user) {
                learningPath.owners.push({
                  id: user.userId,
                  email: user.email,
                  displayName: user.fullName,
                  photoURL: user.picture
                });
                update.owners = learningPath.owners;
                if (isEmpty(learningPath.visibility)) {
                  learningPath.visibility = 'link';
                  update.visibility = 'link';
                }
              }
            }
            if (isEmpty(learningPath.visibility)) {
              learningPath.visibility = 'everybody';
              update.visibility = 'everybody';
            }
            if (!learningPath.permissions) {
              update.permissions = getPermissions(learningPath);
            }
            console.log('update', count, learningPath.id, learningPath.name, learningPath.createdByID, update);
            if (!isEmpty(update)) {
              await doc.ref.update(update);
            }
            count++;
          }
        });
    };
    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const client_id of clients) {
      console.log(`Client ${client_id} started`);
      await updateByClient(client_id);
      console.log(`Client ${client_id} finished`);
    }

    console.log('Finish!');
  }

  async recreateAndUpdateUrl() {
    if (!await this.commonService.confirmation('Run: Recreate and update url for image and etc.')) {
      return;
    }
    this.fixStorageObjects();
  }

  async recreateAndUpdateMSUsersLogoUrl() {
    const signAndUpdateUserLogo = (user: AppUser) => {
      return this.eventDataService.getSignedUrl(`client_data/${clientId}/profile/${user.userId}/profilePhoto.jpg`).then(newUrl => {
        console.log('update user ', 'userId: ' + user.userId, 'user name: ' + user.userName, 'url from: ' + user.picture, 'to :' + newUrl);
        // update user, event, modules
        const userRef = this.afs.collection('client_data').doc(clientId).collection('app_users').doc(user.userId).ref;
        cache.push({ ref: userRef, obj: { picture: newUrl } });

        for (const event of eventList) {
          if ((event.presenters || {})[user.userId]) {
            const pObj = { presenters: { [user.userId]: { picture: newUrl } } };
            const mObj = { managers: { [user.userId]: { picture: newUrl } } };
            const ref = this.afs.collection('client_data').doc(clientId).collection('events').doc(event.eventId).ref;
            cache.push({ ref: ref, obj: pObj });
            cache.push({ ref: ref, obj: mObj });
          }
          if ((event.concierges || {})[user.userId]) {
            const cObj = { concierges: { [user.userId]: { picture: newUrl } } };
            const mObj = { managers: { [user.userId]: { picture: newUrl } } };
            const ref = this.afs.collection('client_data').doc(clientId).collection('events').doc(event.eventId).ref;
            cache.push({ ref: ref, obj: cObj });
            cache.push({ ref: ref, obj: mObj });
          }
          if ((event.speakers || {})[user.userId]) {
            const sObj = { speakers: { [user.userId]: { picture: newUrl } } };
            const ref = this.afs.collection('client_data').doc(clientId).collection('events').doc(event.eventId).ref;
            cache.push({ ref: ref, obj: sObj });
          }
        }

        for (const module of modulesList) {
          if ((module.managers || []).find(u => u.id === user.userId)) {
            const index = module.managers.findIndex(u => u.id === user.userId);
            module.managers[index].photoURL = newUrl;
            const ref = this.afs.collection('client_data').doc(clientId).collection('modules').doc(module.id).ref;
            cache.push({ ref: ref, obj: { managers: module.managers } });
          }
          if ((module.owners || []).find(u => u.id === user.userId)) {
            const index = module.owners.findIndex(u => u.id === user.userId);
            module.owners[index].photoURL = newUrl;
            const ref = this.afs.collection('client_data').doc(clientId).collection('modules').doc(module.id).ref;
            cache.push({ ref: ref, obj: { owners: module.owners } });
          }
          if ((module.viewers || []).find(u => u.id === user.userId)) {
            const index = module.viewers.findIndex(u => u.id === user.userId);
            module.viewers[index].photoURL = newUrl;
            const ref = this.afs.collection('client_data').doc(clientId).collection('modules').doc(module.id).ref;
            cache.push({ ref: ref, obj: { viewers: module.viewers } });
          }
        }

        return Promise.resolve();
      }).catch(err => {
        throw new Error('Error get profile url for user: ' + user.userId + ' error:' + err);
      });
    };

    const cache: IWriteCache[] = [];
    const clientId = this.loginService.client_id$.getValue();
    const clientUsers: AppUser[] = [];
    const eventList: Event[] = [];
    const modulesList: EduModule[] = [];
    if (!await this.commonService.confirmation('Run: Recreate and update microsoft users logo url')) {
      return;
    }
    console.log('Load client ' + clientId + ' users...');
    return this.afs
      .collection('client_data').doc(clientId)
      .collection('app_users')
      .get().toPromise().then(clientUsersSnapshot => {
        console.log('Loaded ' + clientUsersSnapshot.docs.length + ' users.');
        for (const userDoc of clientUsersSnapshot.docs) {
          const appUser = new AppUser({ userId: userDoc.id, ...userDoc.data() });
          if (appUser?.picture?.includes('profilePhoto.jpg')) {
            clientUsers.push(appUser);
          }
        }
        console.log('Loaded ' + clientUsers.length + ' microsoft users.');
        console.log('Load client ' + clientId + ' events...');
        return this.afs
          .collection('client_data').doc(clientId)
          .collection('events')
          .get()
          .toPromise()
          .then(eventsSnapshot => {
            for (const eventDoc of eventsSnapshot.docs) {
              eventList.push(new Event({ ...eventDoc.data(), eventId: eventDoc.id }));
            }
            console.log('Loaded ' + eventsSnapshot.docs.length + ' events.');
            console.log('Load client ' + clientId + ' modules...');
            return this.afs
              .collection('client_data').doc(clientId)
              .collection('modules')
              .get()
              .toPromise()
              .then(async modulesSnapshot => {
                for (const moduleDoc of modulesSnapshot.docs) {
                  modulesList.push(new Object({ ...moduleDoc.data(), id: moduleDoc.id }) as EduModule);
                }
                console.log('Loaded ' + modulesSnapshot.docs.length + ' modules.');
                console.log('Start update logo.');
                let count = 0;
                for (const user of clientUsers) {
                  await signAndUpdateUserLogo(user);
                  console.log('Updated ' + (++count) + ' of ' + clientUsers.length);
                }
                return this.commitCache(cache)
                  .then(() => console.log('End update.'));
              });
          });
      });
  }

  async updateCompetenciesFromLF() {
    const updateByClient = (clientId: string) => {
      return firstValueFrom(this.afs
        .collection('client_data').doc(clientId)
        .collection(learningPathsCollectionName)
        .get())
        .then(async (snapshot) => {
          const update: any = {};
          console.log('length: ', snapshot.docs.length);
          for (const doc of snapshot.docs) {
            const learningPath = <EduLearninPath>doc.data();
            await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection(learningPathsCollectionName)
              .doc(doc.id)
              .collection(learningPathItemsCollectionName)
              .get())
              .then(async (itemSnapshopt) => {
                console.log('length items: ', itemSnapshopt.docs.length);

                if (itemSnapshopt.size > 0) {
                  let selectedCompetenciesId: any[] = [];
                  for (const learningPathItem of itemSnapshopt.docs) {
                    const learningPathItemData = <LearningPathItem>learningPathItem.data();
                    selectedCompetenciesId = selectedCompetenciesId.concat(learningPathItemData.competencyIds);
                  }
                  // Remove duplicated competencies
                  const uniqueCompetencies = [...new Set(selectedCompetenciesId)];
                  learningPath.competencies = uniqueCompetencies;
                  update.competencies = uniqueCompetencies;
                }
              });
            if (!isEmpty(update)) {
              await doc.ref.update(learningPath);
            }
          }
        });
    };

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const client_id of clients) {
      console.log(`Client ${client_id} started`);
      await updateByClient(client_id);
      console.log(`Client ${client_id} finished`);
    }

    console.log('Finish!');
  }

  checkEventWithNotMultiClientUrlInDocuments(clientId?: string) {
    const isFBUrl = (url: string, eventId) => {
      if (url && url.length > 0 &&
        (url.indexOf('firebasestorage.googleapis.com') > -1 ||
          url.indexOf('storage.googleapis.com') > -1) &&
        url.indexOf(eventId) > -1) {
        return true;
      } else {
        return false;
      }
    };

    const checkAndLog = (url: string, event: Event, typeUrl: string, log: {}) => {
      if (url && isFBUrl(url, event.eventId) && !url.includes('client_data')) {
        log[event.eventId] = log[event.eventId] ?? {
          eventName: event.name,
          createDate: this.utils.formatDate(new Date(event.createDate).getTime(), DATE_FORMAT.DD_MM_YYYY),
          orderCreateDate: new Date(event.createDate).getTime(),
          startDate: this.utils.formatDate(new Date(event.startDate).getTime(), DATE_FORMAT.DD_MM_YYYY),
          orderStartDate: new Date(event.startDate).getTime(),
          endDate: this.utils.formatDate(new Date(event.endDate).getTime(), DATE_FORMAT.DD_MM_YYYY),
          eventId: event.eventId,
          shortLink: event.shortLink
        };
        log[event.eventId][typeUrl] = log[event.eventId][typeUrl] ?? 0;
        log[event.eventId][typeUrl]++;
      }
    };

    if (!clientId) {
      clientId = this.loginService.client_id$.getValue();
    }
    console.log('Check event with not MultiClient url in documents. clientId: ', clientId);
    let eventsLength = 0;
    const logEvent = {};
    let counter = 0;
    let deletedCounter = 0;
    this.afs.collection('client_data').doc(clientId)
      .collection('events')
      .get()
      .toPromise()
      .then((snapshot) => {
        const events = snapshot.docs;
        let secPromise = Promise.resolve();
        eventsLength = events.length;
        for (const doc of events) {
          const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
          if (event.deleted) {
            deletedCounter++;
            continue;
          }
          secPromise = secPromise.then(() => {
            return this.afs
              .collection('client_data').doc(clientId)
              .collection('conference').doc(doc.id)
              .collection('timeline')
              .get()
              .toPromise()
              .then((snapshotSections) => {
                const sections: SectionContent[] = snapshotSections.docs.map(d => new SectionContent({ id: d.id, ...d.data() }));
                let contentsPromise = Promise.resolve();
                for (const section of sections) {
                  contentsPromise = contentsPromise.then(() => {
                    return this.afs
                      .collection('client_data').doc(clientId)
                      .collection('conference').doc(doc.id)
                      .collection('timeline')
                      .doc(section.id)
                      .collection('contents')
                      .get().toPromise().then(contentsSnap => {
                        checkAndLog(section.backgroundImage, event, 'section-background', logEvent);
                        const contentsDocs = contentsSnap.docs;
                        for (const contentDoc of contentsDocs) {
                          const content = new Object({ id: contentDoc.id, ...contentDoc.data() });
                          if (content['type'] && (content['type'] === Constants.CONTENT_TYPE_MODULAR ||
                            content['type'] === Constants.CONTENT_TYPE_TASK_DOCUMENT ||
                            content['type'] === Constants.CONTENT_TYPE_QUESTIONNAIRE)) {
                            if (content['type'] === Constants.CONTENT_TYPE_TASK_DOCUMENT) {
                              const tdc = new TaskDocument(content);
                              (tdc.uploadFileLinks || []).forEach(link => checkAndLog(link.src, event, tdc.type, logEvent));
                            }
                            if (content['type'] === Constants.CONTENT_TYPE_MODULAR) {
                              const mdc = new ModularContent(content);
                              mdc.getItems()
                                .filter(it => it.type === Constants.MODULE_TYPE_IMAGE || it.type === Constants.MODULE_TYPE_PDF)
                                .forEach(m => (JSON.parse(m.getModuleContent())
                                  .forEach(row => checkAndLog(row.src, event, mdc.type, logEvent))));
                            }
                            if (content['type'] === Constants.CONTENT_TYPE_QUESTIONNAIRE) {
                              const qc = new QuestionnaireContent(content);
                              Object.values(qc.questions).forEach(q => checkAndLog(q.files[0], event, qc.type, logEvent));
                            }
                          }
                        }
                        return Promise.resolve();
                      });
                  });
                }
                return contentsPromise;
              });
          }).then(() => {
            checkAndLog(event.logo, event, 'even-logo', logEvent);
            checkAndLog(event.horizontalBanner, event, 'even-horizontalBanner', logEvent);
            checkAndLog(event.verticalBanner, event, 'even-verticalBanner', logEvent);
            checkAndLog(event.background, event, 'even-background', logEvent);
            checkAndLog(event.mobileBackground, event, 'even-mobileBackground', logEvent);
            console.log('check ' + (++counter) + ' of ' + (eventsLength - deletedCounter));
            return Promise.resolve();
          });
        }
        return secPromise;
      }).then(() => {
        console.log('End process.' +
          ' total: ' + eventsLength +
          ' checked: ' + (eventsLength - deletedCounter) +
          ' not correct: ' + Object.keys(logEvent).length +
          ' deleted: ' + deletedCounter);
        console.log('---------------- Result ----------------');
        for (const row of Object.keys(logEvent).map(k => logEvent[k]).sort(this.utils.comparator('orderStartDate'))) {
          console.log(row);
        }
      });
  }

  async createUserEvents() {

    const createByClient = (clientId: string) => {
      const eventIds: { [key: string]: string[]; } = {};
      return firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('user_events')
        .get())
        .then((ue_snapshot) => {
          for (const user_events of ue_snapshot.docs) {
            const data = user_events.data();
            if (!eventIds[user_events.id]) {
              eventIds[user_events.id] = [];
            }
            eventIds[user_events.id] = Object.keys(data);
          }
          console.log('user_events', eventIds);
        })
        .then(() => {
          return firstValueFrom(this.afs.collection('client_data').doc(clientId)
            .collection('events')
            .get())
            .then(async (events) => {
              Object.keys(eventIds).forEach(userId => {
                const userEvents = eventIds[userId];
                events.forEach(event => {
                  if (userEvents.includes(event.id)) {
                    const ref = this.afs.collection('client_data').doc(clientId)
                      .collection('app_users').doc(userId)
                      .collection('events').doc(event.id)
                      .ref;
                    cache.push({ ref: ref, obj: event.data() });
                  }
                });
              });
              events.forEach(event => {
                if (event.data().ownerKey) {
                  const ref = this.afs.collection('client_data').doc(clientId)
                    .collection('app_users').doc(event.data().ownerKey)
                    .collection('events').doc(event.id)
                    .ref;
                  cache.push({ ref: ref, obj: event.data() });
                }
              });
            });
        })
        .then(async () => {
          await this.commitCache(cache).then(() => {
            cache.length = 0;
          });
          console.log('Finished!!!');
        });
    };

    const cache: IWriteCache[] = [];

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const client_id of clients) {
      console.log(`Client ${client_id} started`);
      await createByClient(client_id);
      console.log(`Client ${client_id} finished`);
    }

    console.log('Finish!');
  }

  async updateEventState() {
    const updateEventState = (clientId: string) => {
      firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events')
        .get())
        .then((snapshot) => {
          for (const doc of snapshot.docs) {
            const event = doc.data() as Event;
            if (!event.name) {
              console.log('Empty event', doc.id, doc.data());
              continue;
            }
            const obj = {};
            if (event.dopState === 0 && !event.isPublic && !event.published) {
              obj['state'] = 'draft';
            } else if (event.dopState === 0 && !event.isPublic && event.published) {
              obj['state'] = 'private';
            } else if (event.dopState === 0 && event.isPublic && event.published) {
              obj['state'] = 'public';
            } else if (event.dopState === 1 && event.isPublic && event.published) {
              obj['state'] = 'link';
            }
            if (!isEmpty(obj)) {
              const ref = this.afs.collection('client_data').doc(clientId)
                .collection('events').doc(doc.id)
                .ref;
              cache.push({ ref: ref, obj: obj });
            }
          }
        })
        .then(() => this.commitCache(cache).then(() => cache.length = 0))
        .then(() => console.log('Finished!!!'));
    };

    const cache: IWriteCache[] = [];

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const client_id of clients) {
      console.log(`Client ${client_id} started`);
      await updateEventState(client_id);
      console.log(`Client ${client_id} finished`);
    }

    console.log('Finish!');

  }

  async checkEmptyEvents() {
    const updateEventState = (clientId: string) => {
      firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events')
        .get())
        .then(async (snapshot) => {
          for (const doc of snapshot.docs) {
            const event = doc.data() as Event;
            if (event.deleted === 0 && (!event.name || !event.shortLink)) {
              const size = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
                .collection('conference').doc(doc.id)
                .collection('timeline')
                .get())
                .then((sections) => {
                  return sections.size;
                });
              results.push({
                id: doc.id,
                link: event.shortLink,
                name: event.name,
                date: event.startDate,
                sections: size,
                end: new Date(event.endDate)
              });
            }
          }
        })
        .then(() => {
          console.log('Results', clientId, results);
        })
        .then(() => console.log('Finished', clientId));
    };

    const results = [];

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const client_id of clients) {
      console.log(`Client ${client_id} started`);
      await updateEventState(client_id);
      console.log(`Client ${client_id} finished`);
    }

    console.log('Finish!');
  }

  async removeTaxonomyFromModule() {
    const removeTaxonomy = async (clientId: string) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('modules')
        .get())
        .then(async (snapshot) => {
          let count = 0;
          for (const doc of snapshot.docs) {
            count++;
            await doc.ref.update({ tax: firebase.firestore.FieldValue.delete() });
            console.log(`Module ${doc.id} updated. (${count} of ${snapshot.docs})`);
          }
        })
        .then(() => {
          console.log('Results', clientId);
        });
    };

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const client_id of clients) {
      console.log(`Client ${client_id} started`);
      await removeTaxonomy(client_id);
      console.log(`Client ${client_id} finished`);
    }

    console.log('All clients finished!');
  }

  fillContentContainerFieldTypes(clientId: string, location: 'conference' | 'modules') {
    if (!clientId) {
      clientId = this.loginService.client_id$.getValue();
    }
    let eventsLength = 0;
    let counter = 0;
    const cache: IWriteCache[] = [];
    let deletedCounter = 0;
    console.log('Fill field \'itemsTypes\'. clientId: ', clientId);
    this.afs.collection('client_data').doc(clientId)
      .collection(location === 'conference' ? 'events' : 'modules')
      .get()
      .toPromise()
      .then((snapshot) => {
        const events = snapshot.docs;
        let secPromise = Promise.resolve();
        eventsLength = events.length;
        for (const doc of events) {
          const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
          if (event.deleted) {
            deletedCounter++;
            continue;
          }
          secPromise = secPromise.then(() => {
            return this.afs
              .collection('client_data').doc(clientId)
              .collection(location).doc(doc.id)
              .collection('timeline')
              .get()
              .toPromise()
              .then((snapshotSections) => {
                const sections: SectionContent[] = snapshotSections.docs.map(d => new SectionContent({ id: d.id, ...d.data() }));
                let contentsPromise = Promise.resolve();
                for (const section of sections) {
                  contentsPromise = contentsPromise.then(() => {
                    return this.afs
                      .collection('client_data').doc(clientId)
                      .collection(location).doc(doc.id)
                      .collection('timeline')
                      .doc(section.id)
                      .collection('contents')
                      .get().toPromise().then(contentsSnap => {
                        const contentsDocs = contentsSnap.docs;
                        for (const contentDoc of contentsDocs) {
                          const content = new Object({ id: contentDoc.id, ...contentDoc.data() });
                          if (content['type'] && content['type'] === Constants.CONTENT_TYPE_CONTENT_CONTAINER) {
                            const cc = new ContentContainer(content);
                            const obj = {
                              itemsTypes: cc.getItemsTypes()
                            };
                            cache.push({ ref: contentDoc.ref, obj: obj });
                          }
                        }
                        return Promise.resolve();
                      });
                  });
                }
                return contentsPromise;
              });
          }).then(() => {
            console.log('processed ' + (++counter) + ' of ' + (eventsLength - deletedCounter));
            return Promise.resolve();
          });
        }
        return secPromise;
      }).then(() => {
        console.log('Commit', cache.length);
        return this.commitCache(cache)
          .then(() => console.log('End process.', cache.length));
      });
  }

  private async getClientList() {
    return await firstValueFrom(this.afs.collection('client_data').get()).then(value => value.docs.map(d => d.id));
  }

  async checkLostParent() {
    const check = (clientId, location: 'conference' | 'modules'): Promise<boolean> => {
      let eventsLength = 0;
      let counter = 0;
      let deletedCounter = 0;
      console.log(`~~~~~ Check (${location}) clientId: ${clientId}`);
      const log: { id, parentId, eventId; }[] = [];
      return this.afs.collection('client_data').doc(clientId)
        .collection(location === 'conference' ? 'events' : 'modules')
        .get()
        .toPromise()
        .then((snapshot) => {
          const events = snapshot.docs;
          let secPromise = Promise.resolve();
          eventsLength = events.length;
          for (const doc of events) {
            const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
            if (event.deleted) {
              deletedCounter++;
              continue;
            }
            secPromise = secPromise.then(() => {
              return this.afs
                .collection('client_data').doc(clientId)
                .collection(location).doc(doc.id)
                .collection('timeline')
                .get()
                .toPromise()
                .then((snapshotSections) => {
                  const sections: { id, parentId, eventId; }[] = snapshotSections.docs
                    .map(d => new Object({
                      id: d.id,
                      parentId: d.data().parentId,
                      eventId: d.data().eventId
                    }) as { id, parentId, eventId; });
                  for (const s of sections) {
                    if (!s.parentId) {
                      continue;
                    }
                    if (!sections.find(o => o.id === s.parentId)) {
                      log.push(s);
                    }
                  }
                  return Promise.resolve();
                });
            }).then(() => {
              console.log('processed ' + (++counter) + ' of ' + (eventsLength - deletedCounter));
              return Promise.resolve();
            });
          }
          return secPromise;
        }).then(() => {
          console.log(`~~~~~ End process (${location}) clientId: ${clientId}`, log);
          return Promise.resolve(true);
        });
    };
    const clientIds = await this.getClientList();
    for (const clientId of clientIds) {
      await check(clientId, 'conference');
      await check(clientId, 'modules');
    }
  }

  async removeOldCompetenciesFromCurriculum() {
    console.log('-- Started to Remove Competencies --');
    const removeByClient = async (clientId: string) => {
      const competenciesRef = this.afs.collectionGroup(competencyCollectionName);
      const competencySnap = await firstValueFrom(competenciesRef.get());

      const docParentIdsToDelete = [];

      const docParentIdsTreated = [];
      const promisesParentDocs = [];
      competencySnap.forEach((doc) => {
        if (doc.ref.path.includes(`client_data/${clientId}`)) {
          const curriculumRef = doc.ref.parent.parent;
          const curriculumId = curriculumRef.id;
          if (docParentIdsTreated.indexOf(curriculumId) < 0) {
            // We need to check if the parent exists
            promisesParentDocs.push(curriculumRef.get());
            docParentIdsTreated.push(curriculumId);
            console.log(`-- Curriculum Treated -- ${curriculumId}`);
          }
        }
      });

      const parentsSnapshotsArray = await Promise.all(promisesParentDocs);

      parentsSnapshotsArray.forEach(snap => {
        if (!snap.exists) {
          // The parent team doc DOES NOT exist. It is shown in italic in the Firebase console.
          // => We need to delete the child docs
          docParentIdsToDelete.push(snap.id);
          console.log(`-- Curriculum does not Exist -- ${snap.id}`);
        } else { console.log(`-- Curriculum does exist -- ${snap.id}`); }
      });

      const promisesDeletion = [];
      competencySnap.forEach((doc) => {
        const parentTeamId = doc.ref.parent.parent.id;
        if (docParentIdsToDelete.indexOf(parentTeamId) > -1) {
          // We need to delete the doc
          promisesDeletion.push(doc.ref.delete());
        }
      });

      console.log('-- Start to delete Competencies from all Curriculums --');
      return Promise.all(promisesDeletion);
    };

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const client_id of clients) {
      console.log(`Client ${client_id} started`);
      await removeByClient(client_id);
      console.log(`Client ${client_id} finished`);
    }

    console.log('All clients finished!');
  }

  async removeCompetenciesWithoutParent() {
    console.log('-- Started to Remove Competencies Without Parent --');

    const removeByClient = async (clientId: string) => {
      const curriculumsRef = this.afs.collection(clientDataCollectionName).doc(clientId).collection(curriculumCollectionName);
      const curriculumsSnap = await firstValueFrom(curriculumsRef.get());

      curriculumsSnap.forEach(async (curriculumDoc) => {
        console.log('-- Removing from Curriculum --', curriculumDoc.id);
        const competenciesRef = this.afs.collection(clientDataCollectionName).doc(clientId)
          .collection(curriculumCollectionName).doc(curriculumDoc.id).collection(competencyCollectionName);
        const competencySnap = await firstValueFrom(competenciesRef.get());

        const competenciesId: string[] = [];

        competencySnap.forEach(async (doc) => {
          competenciesId.push(doc.id);
        });

        competencySnap.forEach(async (doc) => {
          const competency = doc.data() as CompetencyFlat;
          if (!competenciesId.includes(competency.parentID) && competency.level !== 0) {
            console.log('-- Removing Competency --', doc.id, 'from Curriculum', curriculumDoc.id);
            await doc.ref.delete();
          }
        });

      });

      return Promise.resolve();
    };

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const client_id of clients) {
      console.log(`Client ${client_id} started`);
      await removeByClient(client_id);
      console.log(`Client ${client_id} finished`);
    }

    console.log('All clients finished!');
  }

  async removeOldTimeblocksFromCurriculum() {
    console.log('-- Started to Remove Timeblocks --');
    const removeByClient = async (clientId: string) => {
      const timeBlockRef = this.afs.collectionGroup(timeBlockCollectionName);
      const timeBlockSnap = await firstValueFrom(timeBlockRef.get());

      const docParentIdsToDelete = [];

      const docParentIdsTreated = [];
      const promisesParentDocs = [];
      timeBlockSnap.forEach((doc) => {
        if (doc.ref.path.includes(`client_data/${clientId}`)) {
          const curriculumRef = doc.ref.parent.parent;
          const curriculumId = curriculumRef.id;
          if (docParentIdsTreated.indexOf(curriculumId) < 0) {
            // We need to check if the parent exists
            promisesParentDocs.push(curriculumRef.get());
            docParentIdsTreated.push(curriculumId);
            console.log(`-- Curriculum Treated -- ${curriculumId}`);
          }
        }
      });

      const parentsSnapshotsArray = await Promise.all(promisesParentDocs);

      parentsSnapshotsArray.forEach(snap => {
        if (!snap.exists) {
          // The parent team doc DOES NOT exist. It is shown in italic in the Firebase console.
          // => We need to delete the child docs
          docParentIdsToDelete.push(snap.id);
          console.log(`-- Curriculum does not Exist -- ${snap.id}`);
        } else { console.log(`-- Curriculum does exist -- ${snap.id}`); }
      });

      const promisesDeletion = [];
      timeBlockSnap.forEach((doc) => {
        const parentTeamId = doc.ref.parent.parent.id;
        if (docParentIdsToDelete.indexOf(parentTeamId) > -1) {
          // We need to delete the doc
          promisesDeletion.push(doc.ref.delete());
        }
      });

      console.log('-- Start to delete Timeblocks from all Curriculums --');
      return Promise.all(promisesDeletion);
    };

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const client_id of clients) {
      console.log(`Client ${client_id} started`);
      await removeByClient(client_id);
      console.log(`Client ${client_id} finished`);
    }

    console.log('All clients finished!');
  }

  async removeGroupFromCompetencies() {
    console.log('-- Started to Remove Groups from Competencies');

    const removeByClient = async (clientId: string) => {
      const curriculumsRef = this.afs.collection(clientDataCollectionName).doc(clientId).collection(curriculumCollectionName);
      const curriculumsSnap = await firstValueFrom(curriculumsRef.get());

      curriculumsSnap.forEach(async (curriculumDoc) => {
        console.log('-- Checking from curriculum --', curriculumDoc.id);
        const competenciesRef = this.afs.collection(clientDataCollectionName).doc(clientId)
          .collection(curriculumCollectionName).doc(curriculumDoc.id).collection(competencyCollectionName);
        const competencySnap = await firstValueFrom(competenciesRef.get());

        competencySnap.forEach(async (doc) => {
          const competency = doc.data() as CompetencyFlat;
          if (competency.level < 2) {
            competency.group = '';
            await doc.ref.update(competency)
              .then(() => console.log(`competency ${competency.id} updated`));
          }
        });

      });

      return Promise.resolve();
    };

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const client_id of clients) {
      console.log(`Client ${client_id} started`);
      await removeByClient(client_id);
      console.log(`Client ${client_id} finished`);
    }

    console.log('All clients finished!');

  }

  async removeOldIndustriesFromCurriculum() {
    console.log('-- Started to Remove Timeblocks --');

    const removeByClient = async (clientId: string) => {
      const IndustryRef = this.afs.collectionGroup(industriesCollectionName);
      const IndustrySnap = await firstValueFrom(IndustryRef.get());

      const docParentIdsToDelete = [];

      const docParentIdsTreated = [];
      const promisesParentDocs = [];
      IndustrySnap.forEach((doc) => {
        if (doc.ref.path.includes(`client_data/${clientId}`)) {
          const curriculumRef = doc.ref.parent.parent;
          const curriculumId = curriculumRef.id;
          if (docParentIdsTreated.indexOf(curriculumId) < 0) {
            // We need to check if the parent exists
            promisesParentDocs.push(curriculumRef.get());
            docParentIdsTreated.push(curriculumId);
            console.log(`-- Curriculum Treated -- ${curriculumId}`);
          }
        }
      });

      const parentsSnapshotsArray = await Promise.all(promisesParentDocs);

      parentsSnapshotsArray.forEach(snap => {
        if (!snap.exists) {
          // The parent team doc DOES NOT exist. It is shown in italic in the Firebase console.
          // => We need to delete the child docs
          docParentIdsToDelete.push(snap.id);
          console.log(`-- Curriculum does not Exist -- ${snap.id}`);
        } else { console.log(`-- Curriculum does exist -- ${snap.id}`); }
      });

      const promisesDeletion = [];
      IndustrySnap.forEach((doc) => {
        const parentTeamId = doc.ref.parent.parent.id;
        if (docParentIdsToDelete.indexOf(parentTeamId) > -1) {
          // We need to delete the doc
          promisesDeletion.push(doc.ref.delete());
        }
      });
      console.log('-- Start to delete Timeblocks from all Curriculums --');
      return Promise.all(promisesDeletion);
    };

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const client_id of clients) {
      console.log(`Client ${client_id} started`);
      await removeByClient(client_id);
      console.log(`Client ${client_id} finished`);
    }

    console.log('All clients finished!');
  }

  async removeOldPathsFromLearningFields() {
    console.log('-- Started to Remove Learning Paths Items --');
    const removeByClient = async (clientId: string) => {
      const learningPathItemsRef = this.afs.collectionGroup(learningPathItemsCollectionName);
      const learningPathItemSnap = await firstValueFrom(learningPathItemsRef.get());

      const docParentIdsToDelete = [];

      const docParentIdsTreated = [];
      const promisesParentDocs = [];
      learningPathItemSnap.forEach((doc) => {
        if (doc.ref.path.includes(`client_data/${clientId}`)) {
          const learningPathRef = doc.ref.parent.parent;
          const learningPathId = learningPathRef.id;
          if (docParentIdsTreated.indexOf(learningPathId) < 0) {
            // We need to check if the parent exists
            promisesParentDocs.push(learningPathRef.get());
            docParentIdsTreated.push(learningPathId);
            console.log(`-- Learning Path Treated -- ${learningPathId}`);
          }
        }
      });

      const parentsSnapshotsArray = await Promise.all(promisesParentDocs);

      parentsSnapshotsArray.forEach(snap => {
        if (!snap.exists) {
          // The parent team doc DOES NOT exist. It is shown in italic in the Firebase console.
          // => We need to delete the child docs
          docParentIdsToDelete.push(snap.id);
          console.log(`-- Learning Path does not Exist -- ${snap.id}`);
        } else { console.log(`-- Learning Path does exist -- ${snap.id}`); }
      });

      const promisesDeletion = [];
      learningPathItemSnap.forEach((doc) => {
        const parentTeamId = doc.ref.parent.parent.id;
        if (docParentIdsToDelete.indexOf(parentTeamId) > -1) {
          // We need to delete the doc
          promisesDeletion.push(doc.ref.delete());
        }
      });

      console.log('-- Start to delete Learning Path from all Curriculums --');
      return Promise.all(promisesDeletion);
    };

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const client_id of clients) {
      console.log(`Client ${client_id} started`);
      await removeByClient(client_id);
      console.log(`Client ${client_id} finished`);
    }

    console.log('All clients finished!');
  }

  async addIdToOldSubjectsFromCurriculum() {
    if (!await this.commonService.confirmation('Start add ID to subject?')) {
      return;
    }

    console.log('-- Started to add ID to all Subjects --');

    const addByClient = async (clientId: string) => {
      const curriculumsRef = this.afs.collection(clientDataCollectionName).doc(clientId).collection(curriculumCollectionName);
      const curriculumSnap = await firstValueFrom(curriculumsRef.get());

      curriculumSnap.forEach(async (curriculumDoc) => {
        const curriculum: Curriculum = curriculumDoc.data() as Curriculum;
        if (curriculum?.subjects && curriculum.subjects.length > 0) {
          curriculum.subjects.forEach((subject: CurriculumSubject) => {
            if (!subject.id) { subject.id = this.afs.createId(); }
          });
          await curriculumDoc.ref.update(Object.assign({}, curriculum));
        }
      });
    };

    console.log('-- End to add ID to all Subjects --');

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const client_id of clients) {
      console.log(`Client ${client_id} started`);
      await addByClient(client_id);
      console.log(`Client ${client_id} finished`);
    }

    console.log('All clients finished!');

  }

  async replaceShortNameToIdModule(updateMode: boolean) {
    if (updateMode && !await this.commonService.confirmation('Start replace?')) {
      return;
    }
    if (!updateMode && !await this.commonService.confirmation('Start check?')) {
      return;
    }
    console.log('-- Started to replace do all Modules --');

    const replaceByClient = async (clientId: string) => {


      const modulesRef = this.afs.collection(clientDataCollectionName).doc(clientId).collection(modulesCollectionName);
      const moduleSnap = await firstValueFrom(modulesRef.get());

      const total = moduleSnap.docs.filter(d => !isEmpty(d.data().curriculums) && !d.data().deleted).length;
      let current = 0;

      for (const moduleDoc of moduleSnap.docs) {
        const module = moduleDoc.data() as EduModule;

        const curriculumsRef = this.afs.collection(clientDataCollectionName).doc(clientId).collection(curriculumCollectionName);
        const curriculumSnap = await firstValueFrom(curriculumsRef.get());
        if (!isEmpty(module?.curriculums) && !module.deleted) {
          current++;
          const moduleCurriculums: ModuleCurriculums[] = module.curriculums;
          let skipUpdate = false;
          let cause = '';

          for (const moduleCurriculum of moduleCurriculums) {
            const subjectNewList: any[] = [];

            for (const curriculumDoc of curriculumSnap.docs) {
              const curriculum = curriculumDoc.data() as Curriculum;
              if (moduleCurriculum.curriculumId === curriculum.id) {
                moduleCurriculum.subjects.forEach(subjectShortName => {
                  const subjectSelected = curriculum?.subjects
                    .find(subject => (subject.shortName === subjectShortName) || (subject.id === subjectShortName));
                  if (subjectSelected && subjectSelected.id) {
                    subjectNewList.push(subjectSelected.id);
                  } else {
                    skipUpdate = true;
                    cause = cause + 'id is null, ';
                  }
                });
              }
            }
            if ((moduleCurriculum.subjects || []).length !== subjectNewList.length) {
              skipUpdate = true;
              cause = cause + 'count not equal, ';
            }
            if (!skipUpdate) {
              moduleCurriculum.subjects = subjectNewList;
            }
          }

          if (updateMode && !skipUpdate) {
            module.curriculums = moduleCurriculums;
            await moduleDoc.ref.update(Object.assign({}, module));
            console.log(`${current} of ${total} ` + 'UPDATE. module name: ' + module.name, 'id: ' + moduleDoc.id);
          } else if (updateMode && skipUpdate) {
            console.log(`${current} of ${total} ` + 'SKIP UPDATE. module name: ' + module.name, 'id: ' + moduleDoc.id, 'cause: ' + cause,
              'curriculums: ', module.curriculums);
          } else if (!updateMode && !skipUpdate) {
            console.log(`${current} of ${total} ` + 'CHECK. module name: ' + module.name, 'id: ' + moduleDoc.id);
          } else if (!updateMode && skipUpdate) {
            console.log(`${current} of ${total} ` + 'SKIP CHECK. module name: ' + module.name, 'id: ' + moduleDoc.id, 'cause: ' + cause,
              'curriculums: ', module.curriculums);
          }
        }
      }
      return Promise.resolve();
    };

    console.log('-- End to add ID to all Subjects --');

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const client_id of clients) {
      console.log(`Client ${client_id} started`);
      await replaceByClient(client_id);
      console.log(`Client ${client_id} finished`);
    }

    console.log('All clients finished!');

  }

  async removeLearningPathsWithoutParent() {
    console.log('-- Started to Remove Learning Path Item Without Parent --');

    const removeByClient = async (clientId: string) => {
      const learningPathsRef = this.afs.collection(clientDataCollectionName).doc(clientId).collection(learningPathsCollectionName);
      const learningPathSnap = await firstValueFrom(learningPathsRef.get());

      learningPathSnap.forEach(async (learningFieldDoc) => {
        console.log('-- Removing from Learning Field --', learningFieldDoc.id);
        const pathItemsRef = this.afs.collection(clientDataCollectionName).doc(clientId)
          .collection(learningPathsCollectionName).doc(learningFieldDoc.id).collection(learningPathItemsCollectionName);
        const pathItemSnap = await firstValueFrom(pathItemsRef.get());

        const pathItemsId: string[] = [];

        pathItemSnap.forEach(async (doc) => {
          pathItemsId.push(doc.id);
        });

        pathItemSnap.forEach(async (doc) => {
          const pathItem = doc.data() as LearningPathItem;
          if (!pathItemsId.includes(pathItem.parentId) && pathItem.level !== 0) {
            console.log('-- Removing Path Item --', doc.id, 'from Learning Field', learningFieldDoc.id);
            await doc.ref.delete();
          }
        });

      });

      return Promise.resolve();
    };

    console.log('--Finished Remove Learning Path Item Without Parent --');

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const client_id of clients) {
      console.log(`Client ${client_id} started`);
      await removeByClient(client_id);
      console.log(`Client ${client_id} finished`);
    }

    console.log('All clients finished!');
  }

  async checkAndFixEventRealFinishDateDate(onlyCheck: boolean) {
    if (!await this.commonService.confirmation('Run: Fixed event realFinishDate')) {
      return;
    }
    const clientId = this.loginService.client_id$.getValue();
    console.log('clientId: ', clientId);
    let eventsLength = 0;
    let counter = 0;
    const log = [];
    const skipLog = [];
    const cache: IWriteCache[] = [];
    const queryFn = q => q.where('deleted', '==', 0);
    console.log('~~~~~ start ~~~~~');
    this.afs.collection('client_data').doc(clientId)
      .collection('events', queryFn)
      .get()
      .toPromise()
      .then((snapshot) => {
        const events = snapshot.docs;
        eventsLength = events.length;
        for (const doc of events) {
          const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
          console.log('check: ', ++counter, 'of', eventsLength);
          if (event.deleted || !event.startDate || !event.endDate || event.dateMode === EVENT_DATE_MODE.ANYTIME) {
            skipLog.push({
              eventId: event.eventId,
              shortLink: event.shortLink,
              startDate: this.utils.formatDate(event.startDate.getTime(), DATE_FORMAT.DD_MM_YYYY_HH_mm),
              duration: event.duration,
              endDate: this.utils.formatDate(event.endDate, DATE_FORMAT.DD_MM_YYYY_HH_mm),
              name: event.name,
              dateMode: event.dateMode,
              realFinishDate: event.realFinishDate
            });
            continue;
          }
          if (event.durationFixed && event.endDateFixed && event.startDate.getTime() +
            (event.duration ?? 0) * Constants.ONE_MINUTE_MS < event.endDate) {
            log.push({
              eventId: event.eventId,
              shortLink: event.shortLink,
              startDate: this.utils.formatDate(event.startDate.getTime(), DATE_FORMAT.DD_MM_YY_HH_mm_ss),
              duration: event.duration,
              endDate: this.utils.formatDate(event.endDate, DATE_FORMAT.DD_MM_YY_HH_mm_ss),
              name: event.name,
              dateMode: event.dateMode,
              realFinishDate: event.realFinishDate,
              newDuration: Math.trunc((event.endDate - event.startDate.getTime()) / Constants.ONE_MINUTE_MS),
              wrapUpPhaseEnd: event.wrapUpPhaseEnd
            });
            if (!onlyCheck) {
              const ref = this.afs.collection('client_data').doc(clientId)
                .collection('events').doc(doc.id)
                .ref;
              const obj = {
                realFinishDate: new Date(event.endDate),
                duration: Math.trunc((event.endDate - event.startDate.getTime()) / Constants.ONE_MINUTE_MS),
                durationFixed: false
              };
              cache.push({ ref: ref, obj: obj });
            }
          }
        }
        console.log('~~~~~ log ~~~~~');
        console.log(log);
        console.log('~~~~~ skip ~~~~~');
        console.log(skipLog);
        if (!onlyCheck) {
          console.log('~~~~~ fixed ~~~~~');
          this.commitCache(cache);
        }
      });
  }

  async createSummaryByUserSectionRegistrationAllClients() {
    if (!await this.commonService.confirmation('Run create registration summary')) {
      return;
    }
    const clients = await this.afs
      .collection('client_data')
      .get()
      .toPromise()
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const clientId of clients) {
      console.log(`Client ${clientId} started`);
      await this.createSummaryByUserSectionRegistration(clientId);
      console.log(`Client ${clientId} finished`);
      console.log(`~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~`);
    }
    console.log('All clients finished');
  }

  private async createSummaryByUserSectionRegistration(clientIdValue: string) {
    const summary = (eventId, sectionId): Promise<any> => {
      return firstValueFrom(this.afs
        .collection('client_data').doc(clientId)
        .collection('conference').doc(eventId)
        .collection('timeline').doc(sectionId)
        .collection('registered_users').get())
        .then(rs => {
          const sm: { [status: string]: number; } = {};
          for (const ud of rs.docs) {
            const u: { status: string; } = ud.data() as { status: string; };
            if ([Constants.REGISTRATION_STATUS_NEW, Constants.REGISTRATION_STATUS_NOT_REGISTERED].includes(u.status)) {
              continue;
            }
            const status = u.status
              .split('.')
              .map((v, idx) => idx > 0 ? (v[0].toUpperCase() + v.substring(1)) : v)
              .join('');
            sm[status] = !sm[status] ? 1 : sm[status] + 1;
          }
          const ref = this.afs
            .collection('client_data').doc(clientId)
            .collection('conference').doc(eventId)
            .collection('timeline_counters').doc(sectionId).ref;
          return !isEmpty(sm) ? cache.push({ ref: ref, obj: sm }) : true;
        });
    };
    if (!clientIdValue) {
      return;
    }
    const clientId = clientIdValue;
    let eventsLength = 0;
    let counter = 0;
    const cache: IWriteCache[] = [];
    const queryFn = q => q.where('deleted', '==', 0);
    let eventPromise = Promise.resolve();
    console.log('Client ' + clientId + ' loading');
    return this.afs.collection('client_data').doc(clientId)
      .collection('events', queryFn)
      .get()
      .toPromise()
      .then((snapshot) => {
        const events = snapshot.docs;
        eventsLength = events.length;
        for (const doc of events) {
          const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
          eventPromise = eventPromise.then(() => this.afs
            .collection('client_data').doc(clientId)
            .collection('conference').doc(event.eventId)
            .collection('timeline')
            .get()
            .toPromise()
            .then((snapshotSections) => {
              console.log('process: ', ++counter, ' of ', eventsLength);
              let sp = Promise.resolve();
              for (const sd of snapshotSections.docs) {
                const sId = sd.id;
                sp = sp.then(() => summary(event.eventId, sId));
              }
              return sp;
            })
          );
        }
        return eventPromise;
      }).then(() => {
        console.log('~~~~~ commit ~~~~~');
        return this.commitCache(cache);
      });
  }

  deleteOldEvents(commit = false) {
    const clientId = 'P10_000001';
    let eventsLength = 0;
    let counter = 0;
    const cache: IWriteCache[] = [];
    const queryFn = q => q.where('deleted', '==', 0);
    console.log('Start');
    const list: string[] = [];
    return firstValueFrom(this.afs.collection('client_data').doc(clientId)
      .collection('events', queryFn)
      .get())
      .then((snapshot) => {
        const events = snapshot.docs;
        eventsLength = events.length;
        console.log('Total events:', eventsLength);
        const endDate = new Date(2020, 11, 31);
        for (const doc of events.filter(it => it.data().dateMode !== 2)) {
          const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
          const ref = this.afs.collection('client_data').doc(clientId)
            .collection('events').doc(doc.id).ref;
          const msg = event.shortLink + ';' + event.name + ';' + this.utils.formatDate(event.realFinishDate, DATE_FORMAT.DD_MM_YYYY);
          if (event.realFinishDate < endDate) {
            counter++;
            list.push(msg);
            console.log('Event ' + counter, msg);
            cache.push({ ref: ref, obj: { deleted: 3 } });
          } else if (event.endDate < endDate.getTime() && !event.wrapUpPhaseEnd) {
            counter++;
            list.push(msg);
            console.log('Event ' + counter, msg);
            cache.push({ ref: ref, obj: { deleted: 3 } });
          } else if (event.wrapUpPhaseEnd < endDate.getTime() && event.wrapUpPhaseEnd) {
            counter++;
            list.push(msg);
            console.log('Event ' + counter, msg);
            cache.push({ ref: ref, obj: { deleted: 3 } });
          }
        }
      }).then(() => {
        console.log('~~~~~ commit ~~~~~', cache.length, counter);
        this.utils.downloadCsvReport(list.join('\n'), 'old_events.csv', 'text/csv;encoding:utf-8');
        if (commit) {
          return this.commitCache(cache);
        }
      });
  }

  async updateSpeakerEmail() {

    const updateClient = async (clientId) => {
      let updateCount = 0;
      const users = {};
      return firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events').get())
        .then(async (snapshot) => {
          for (const doc of snapshot.docs.filter(it => !isEmpty(it.data().speakers))) {
            if (doc.exists) {
              const event = doc.data() as Event;
              for (const speakerId of Object.keys(event.speakers)) {
                if (!users[speakerId]) {
                  users[speakerId] = await this.getUser(speakerId, false);
                }
                if (users[speakerId] && event.speakers[speakerId].email !== users[speakerId].email) {
                  const obj = {
                    speakers: {
                      [speakerId]: { email: users[speakerId].email }
                    }
                  };
                  console.log(`Email was changed eventId=${doc.id}, speakerId=${speakerId}, oldEmail=${event.speakers[speakerId].email}, newEmail=${users[speakerId].email}`);
                  cache.push({ ref: doc.ref, obj: obj });
                  updateCount++;
                }
              }
            }
          }
          console.log('Updated ' + updateCount);
        });
    };

    const cache: IWriteCache[] = [];

    const clients = await this.afs
      .collection('client_data')
      .get()
      .toPromise()
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const clientId of clients) {
      console.log(`Client ${clientId} started`);
      await updateClient(clientId);
      console.log(`Client ${clientId} finished`);
    }

    await this.commitCache(cache);

    console.log('Finish!');
    this.progress.next('All tasks finished');

    return;
  }


  async disableManageTime() {

    const isManageTimeDisabled = (event: Event, config: ClientConfig) => {
      return this.featuresService.getFeatureValue('enableSlotMode', config) &&
        this.featuresService.getFeatureValue('importSlots', config) && !!event.classCode;
    };

    if (!await this.commonService.confirmation('Run set disable ManageTime')) {
      return;
    }

    let log: { clientId, eventId, isManageTimeDisabledFieldValue, isManageTimeDisabled }[] = [];

    const clients: ClientConfig[] = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => {
          const obj = it.data();
          obj['id'] = it.id;
          return new ClientConfig(obj);
        });
      });
    console.log('Clients', clients.map(it => it.id));
    for (const client of clients) {
      console.log(`Client ${client.id} started`);
      await firstValueFrom(this.afs.collection('client_data').doc(client.id)
        .collection('events').get())
        .then(async (snapshot) => {
          const events = snapshot.docs.map(it => new Event({ eventId: it.id, ...it.data() }));
          let i = 0;
          for (const event of events) {
            console.log('client', client.id, 'check', ++i, 'of', events.length);
            if (isManageTimeDisabled(event, client)) {
              log.push({
                clientId: client.id, eventId: event.eventId,
                isManageTimeDisabledFieldValue: event.disableManageTime, isManageTimeDisabled: isManageTimeDisabled(event, client)
              });
              await this.afs.collection('client_data').doc(client.id)
                .collection('events').doc(event.eventId).set({ disableManageTime: true }, { merge: true });
            }
          }
          console.log(log);
          console.log('all event count', events.length, 'disabled manage time count', log.length);
          log = [];
          return client;
        });
    }

  }


  async checkEventTimelineTreeStructure() {
    if (!await this.commonService.confirmation('Run: Check events timeline tree Date/Time structure in current client')) {
      return;
    }
    const clientId = this.loginService.client_id$.getValue();
    console.log('clientId: ', clientId);
    let eventsLength = 0;
    let counter = 0;
    const log = {};
    const queryFn = q => q.where('deleted', '==', 0);
    console.log('~~~~~ start client ~~~~~', clientId);
    await firstValueFrom(this.afs.collection('client_data').doc(clientId)
      .collection('events', queryFn).get())
      .then(async (snapshot) => {
        const events = snapshot.docs;
        eventsLength = events.length;
        for (const doc of events) {
          const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
          console.log('check:', ++counter, 'of', eventsLength);
          await firstValueFrom(this.afs.collection('client_data').doc(clientId)
            .collection('conference').doc(doc.id)
            .collection('timeline').get())
            .then((querySnapshot) => {
              const timeline: SectionContent[] = querySnapshot.docs.map(d => new SectionContent({ id: d.id, ...d.data() }));
              const root = timeline.find(s => s.isRoot);
              if (root) {
                const timelineLevelFirst = timeline
                  .filter(s => s.id !== root.id && s.parentId === root.id && !s.fixedSectionType && !s.education && !!s.plannedTime)
                  .sort(this.utils.comparator(Constants.ORDERINDEX));
                const timelineByOrderIndex = timeline.filter(s => !s.education).sort(this.utils.comparator(Constants.ORDERINDEX));
                for (let i = 0; i < timelineLevelFirst.length - 2; i++) {
                  const cs = timelineLevelFirst[i];
                  const ns = timelineLevelFirst[i + 1];
                  if (ns.plannedTime < cs.plannedTime) {
                    if (!log[event.eventId]) {
                      log[event.eventId] = [];
                    }
                    log[event.eventId].push(new Object({
                      a_event: { eventId: event.eventId, shortLink: event.shortLink },
                      b_current: {
                        currentId: cs.id, currentDate: this.utils.formatDate(cs.plannedTime, DATE_FORMAT.DD_MM_YYYY_HH_mm),
                        title: cs.title, subtitle: cs.subtitle,
                        hasChild: timeline.filter(s => s.parentId === cs.id).length > 0
                      },
                      c_next: {
                        nextId: ns.id, nexDate: this.utils.formatDate(ns.plannedTime, DATE_FORMAT.DD_MM_YYYY_HH_mm),
                        title: ns.title, subtitle: ns.subtitle,
                        hasChild: timeline.filter(s => s.parentId === ns.id).length > 0
                      },
                      e_timelineByOrderIndex: timelineByOrderIndex.map(s => new Object({
                        id: s.id,
                        orderIndex: s.orderIndex,
                        parentId: s.parentId,
                        plannedTime: this.utils.formatDate(s.plannedTime, DATE_FORMAT.DD_MM_YYYY_HH_mm),
                        title: s.title,
                        subtitle: s.subtitle
                      }))
                    }));
                  }
                }
              }
            });
        }
      }).then(() => {
        console.log('~~~~~ log client ~~~~~', clientId);
        console.log(log);
      });
  }

  async checkOrderIndexSlots() {
    if (!await this.commonService.confirmation('Run: Check events tree structure by orderIndex in current client')) {
      return;
    }
    const clientId = this.loginService.client_id$.getValue();
    console.log('clientId: ', clientId);
    let eventsLength = 0;
    let counter = 0;
    const queryFn = q => q.where('deleted', '==', 0);
    console.log('~~~~~ start client ~~~~~', clientId);
    const logs: { [key: string]: string[] } = {};
    await firstValueFrom(this.afs.collection('client_data').doc(clientId)
      .collection('events', queryFn).get())
      .then(async (snapshot) => {
        const events = snapshot.docs;
        eventsLength = events.length;
        for (const doc of events) {
          const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
          console.log(`check(id=${doc.id}, link=${event.shortLink}):`, ++counter, 'of', eventsLength);
          await firstValueFrom(this.afs.collection('client_data').doc(clientId)
            .collection('conference').doc(doc.id)
            .collection('timeline').get())
            .then((querySnapshot) => {
              const timeline: SectionContent[] = querySnapshot.docs.map(d => new SectionContent({ id: d.id, ...d.data() }))
                .filter(it => !it.fixedSectionType && !it.isRoot);
              const timelineSorted = timeline.filter(s => !s.education)
                .sort(this.utils.comparator(Constants.ORDERINDEX));
              const timelineSortedEd = timeline.filter(s => !!s.education)
                .sort(this.utils.comparator(Constants.ORDERINDEX));

              const addToLog = (id: string, value: string) => {
                if (!logs[id]) {
                  logs[id] = [];
                }
                logs[id].push(value);
              };
              const checkIndex = (sections: SectionContent[]) => {
                for (let i = 0; i < sections.length - 1; i++) {
                  const section = sections[i];
                  const sectionNext = sections[i + 1];
                  const diff = sectionNext.orderIndex - section.orderIndex;
                  if (diff < 1) {
                    addToLog(doc.id, `red:id=${section.id},%c idx1=${section.orderIndex}, idx2=${sectionNext.orderIndex}, diff=${diff}, title=${section.title}`);
                  } else if (diff < 10000) {
                    addToLog(doc.id, `orange:id=${section.id},%c idx1=${section.orderIndex}, idx2=${sectionNext.orderIndex}, diff=${diff}, title=${section.title}`);
                  }
                }
              };

              checkIndex(timelineSorted);
              checkIndex(timelineSortedEd);
            });
        }
      }).then(() => {
        let withCriticalIssues = 0;
        let withModerateIssues = 0;
        for (const id of Object.keys(logs)) {
          const eLog = logs[id];
          withCriticalIssues += eLog.some(it => it.includes('red:')) ? 1 : 0;
          withModerateIssues += eLog.some(it => it.includes('orange:')) ? 1 : 0;
          console.log(`%cEvent id=${id}`, 'color: blue');
          for (const it of eLog) {
            if (it.includes('red:')) {
              console.log(it.replace('red:', ''), 'color: red');
            } else if (it.includes('orange:')) {
              console.log(it.replace('orange:', ''), 'color: orange');
            }
          }
        }
        console.log(`Events with issues critical=${withCriticalIssues}, moderate=${withModerateIssues}`);
      }).then(() => {
        console.log('~~~~~ log client ~~~~~', clientId);
      });
  }

  async fixEventTimelineTreeStructure(available = false, fix = false) {
    if (!await this.commonService.confirmation('Run: Check events tree structure by orderIndex in current client')) {
      return;
    }
    interface IItem {
      obj: SectionContent;
      items: IItem[];
    }

    const cache: IWriteCache[] = [];
    const clientId = this.loginService.client_id$.getValue();
    console.log('clientId: ', clientId);
    let eventsLength = 0;
    let counter = 0;
    const queryFn = q => q.where('deleted', '==', 0);
    console.log('~~~~~ start client ~~~~~', clientId);
    await firstValueFrom(this.afs.collection('client_data').doc(clientId)
      .collection('events', queryFn).get())
      .then(async (snapshot) => {
        const events = snapshot.docs;
        eventsLength = events.length;
        const logs: { [key: string]: string[] } = {};
        for (const doc of events) {
          const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
          console.log(`check(id=${doc.id}, link=${event.shortLink}):`, ++counter, 'of', eventsLength);
          await firstValueFrom(this.afs.collection('client_data').doc(clientId)
            .collection('conference').doc(doc.id)
            .collection('timeline').get())
            .then((sectionSnapshot) => {
              const timeline: SectionContent[] = sectionSnapshot.docs.map(d => new SectionContent({ id: d.id, ...d.data() }))
                .filter(it => !it.fixedSectionType);
              const root = timeline.find(s => s.isRoot);
              if (root) {
                const timelineSorted = timeline.filter(s => !s.education).sort(this.utils.comparator(Constants.ORDERINDEX));
                const timelineByOrderIndexEd = [root].concat(timeline.filter(s => !!s.education))
                  .sort(this.utils.comparator(Constants.ORDERINDEX));

                const buildHierarchy = (srcList: SectionContent[], parentId: string | null | undefined = null): IItem[] => {
                  const result: IItem[] = [];

                  for (const item of srcList) {
                    // tslint:disable-next-line:triple-equals
                    if (item.parentId == parentId) {
                      const children = buildHierarchy(srcList, item.id);
                      if (children.length > 0) {
                        result.push({ obj: item, items: children });
                      } else {
                        result.push({ obj: item, items: [] });
                      }
                    }
                  }
                  return result;
                };

                const isValidRec = (array: IItem[], min: number, max: number) => {
                  level++;
                  let step = ((max - min) / (array.length + 1));
                  let index = min;
                  for (let i = 0; i < array.length; i++) {
                    const el = array[i].obj;
                    if (el.orderIndex > min && el.orderIndex < max && el.orderIndex > index) {
                      logs[doc.id].push(`${el.id}(${level}): idx=${el.orderIndex}, title=${el.title}, start=${this.utils.formatDate(el.plannedTime, DATE_FORMAT.DD_MM_HH_mm)}, end=${this.utils.formatDate(el.endTime, DATE_FORMAT.DD_MM_HH_mm)}, prentId=${el.parentId}`);
                      index = el.orderIndex;
                      step = ((max - index) / (array.length - i));
                    } else {
                      const localMin = index;
                      index += step;
                      logs[doc.id].push(`%c${el.id}(${level}): idx=${el.orderIndex}, min=${min}, max=${max}, localMin=${localMin}, new=${index}, title=${el.title}, start=${this.utils.formatDate(el.plannedTime, DATE_FORMAT.DD_MM_HH_mm)}, end=${this.utils.formatDate(el.endTime, DATE_FORMAT.DD_MM_HH_mm)}, prentId=${el.parentId}`);
                      el.orderIndex = index;
                      if (fix) {
                        const ref = sectionSnapshot.docs.find(it => it.id === el.id).ref;
                        cache.push({ ref: ref, obj: { orderIndex: index } });
                      }
                    }
                    const firstEl = array[i];
                    if ((i + 1) !== array.length) {
                      const secondEl = array[i + 1];
                      if (secondEl.obj.orderIndex > min && secondEl.obj.orderIndex < max && secondEl.obj.orderIndex > index) {
                        isValidRec(firstEl.items, firstEl.obj.orderIndex, secondEl.obj.orderIndex);
                      } else {
                        isValidRec(firstEl.items, firstEl.obj.orderIndex, index + step);
                      }
                    } else {
                      isValidRec(firstEl.items, firstEl.obj.orderIndex, max);
                    }
                  }
                  level--;
                };

                const isValid = (array: IItem[]) => {
                  for (let i = 0; i < array.length; i++) {
                    const firstEl = array[i];
                    logs[doc.id].push(`${firstEl.obj.id}(${level}): idx=${firstEl.obj.orderIndex}, title=${firstEl.obj.title}`);
                    if ((i + 1) !== array.length) {
                      const secondEl = array[i + 1];
                      isValidRec(firstEl.items, firstEl.obj.orderIndex, secondEl.obj.orderIndex);
                    } else {
                      isValidRec(firstEl.items, firstEl.obj.orderIndex, maxIdx + 1000000000);
                    }
                  }
                };

                const fixSlotsByDate = (array: IItem[]): boolean => {
                  let withIssues = false;
                  for (let i = 0; i < array.length; i++) {
                    if (i + 1 < array.length) {
                      const current = array[i].obj;
                      const next = array[i + 1].obj;
                      if (!current.plannedTimeFixed || !next.plannedTimeFixed || !current.freeSlotType || !next.freeSlotType) {
                        continue;
                      }
                      if (current.plannedTime > next.plannedTime) {
                        const currentRef = sectionSnapshot.docs.find(it => it.id === current.id).ref;
                        const nextRef = sectionSnapshot.docs.find(it => it.id === next.id).ref;
                        updateSlots[current.id] = {
                          ref: currentRef,
                          obj: { orderIndex: next.orderIndex }
                        };
                        updateSlots[next.id] = {
                          ref: nextRef,
                          obj: { orderIndex: current.orderIndex }
                        };
                        if (current.orderIndex === next.orderIndex) {
                          current.orderIndex = current.orderIndex + 0.5;
                          next.orderIndex = next.orderIndex - 0.5;
                        } else {
                          const nextOrderIndex = next.orderIndex;
                          next.orderIndex = current.orderIndex;
                          current.orderIndex = nextOrderIndex;
                        }
                        i++;
                        withIssues = true;
                      }
                    }
                  }
                  return withIssues;
                };

                logs[doc.id] = [];
                const updateSlots: {[id: string]: {ref: DocumentReference, obj: any}} = {};

                let resultArray: IItem[] = [];
                let maxIdx = 0;
                if (available) {
                  resultArray = buildHierarchy(timelineByOrderIndexEd);
                  maxIdx = timelineByOrderIndexEd.map(it => it.orderIndex).sort((a, b) => a - b).reverse()[0];
                } else {
                  resultArray = buildHierarchy(timelineSorted);
                  maxIdx = timelineSorted.map(it => it.orderIndex).sort((a, b) => a - b).reverse()[0];
                }

                let level = 0;
                if (resultArray.length === 1) {
                  let withIssues = true;
                  while (withIssues && event.classCode) {
                    withIssues = fixSlotsByDate(resultArray[0].items);
                    if (withIssues) {
                      logs[doc.id].push('%cNext iteration');
                      resultArray[0].items = resultArray[0].items.sort((a, b) => a.obj.orderIndex - b.obj.orderIndex);
                    }
                  }

                  if (fix && !isEmpty(updateSlots)) {
                    Object.values(updateSlots).forEach(it => {
                      cache.push({ ref: it.ref, obj: it.obj });
                    });
                  }
                  isValidRec(resultArray[0].items, root.orderIndex, maxIdx + 1000000000);
                  // isValid(resultArray[0].items);
                } else {
                  console.log(`${doc.id}%cMore 1 root section in results!!!`, 'color: red');
                }
              }
            });
        }

        const issuesCounter = [];
        for (const id of Object.keys(logs)) {
          const eLog = logs[id];
          const withIssues = eLog.some(it => it.includes('%c'));
          if (!withIssues) {
            continue;
          }
          issuesCounter.push(id);
          console.log(`%cEvent id=${id}`, 'color: blue');
          for (const it of eLog) {
            if (it.includes('%c')) {
              console.log(it, 'color: red');
            } else {
              console.log(it);
            }
          }
        }
        console.log(`Events with issues ${issuesCounter.length}`, issuesCounter);
      }).then(() => {
        if (fix) {
          this.commitCache(cache);
        }
        console.log('~~~~~ log client ~~~~~', clientId);
      });
  }

  async fixModuleTimelineTreeStructure(fix = false) {
    if (!await this.commonService.confirmation('Run: Check modules tree structure by orderIndex in current client')) {
      return;
    }
    interface IItem {
      obj: SectionContent;
      items: IItem[];
    }

    const cache: IWriteCache[] = [];
    const clientId = this.loginService.client_id$.getValue();
    console.log('clientId: ', clientId);
    let eventsLength = 0;
    let counter = 0;
    const queryFn = q => q.where('deleted', '==', 0);
    console.log('~~~~~ start client ~~~~~', clientId);
    await firstValueFrom(this.afs.collection('client_data').doc(clientId)
      .collection('modules', queryFn).get())
      .then(async (snapshot) => {
        const modules = snapshot.docs;
        eventsLength = modules.length;
        const logs: { [key: string]: string[] } = {};
        for (const doc of modules.filter(it => it.get('deleted') === 0)) {
          console.log(`check(id=${doc.id}):`, ++counter, 'of', eventsLength);
          await firstValueFrom(this.afs.collection('client_data').doc(clientId)
            .collection('modules').doc(doc.id)
            .collection('timeline').get())
            .then((querySnapshot) => {
              const timeline: SectionContent[] = querySnapshot.docs.map(d => new SectionContent({ id: d.id, ...d.data() }))
                .filter(it => !it.fixedSectionType);
              const root = timeline.find(s => s.isRoot);
              if (root) {
                const timelineSorted = timeline.sort(this.utils.comparator(Constants.ORDERINDEX));

                const buildHierarchy = (srcList: SectionContent[], parentId: string | null | undefined = null): IItem[] => {
                  const result: IItem[] = [];

                  for (const item of srcList) {
                    // tslint:disable-next-line:triple-equals
                    if (item.parentId == parentId) {
                      const children = buildHierarchy(srcList, item.id);
                      if (children.length > 0) {
                        result.push({ obj: item, items: children });
                      } else {
                        result.push({ obj: item, items: [] });
                      }
                    }
                  }

                  return result;
                };

                const isValidRec = (array: IItem[], min: number, max: number) => {
                  level++;
                  let step = ((max - min) / (array.length + 1));
                  let index = min;
                  for (let i = 0; i < array.length; i++) {
                    const el = array[i].obj;
                    if (el.orderIndex > min && el.orderIndex < max && el.orderIndex > index) {
                      logs[doc.id].push(`${el.id}(${level}): idx=${el.orderIndex}, title=${el.title}`);
                      index = el.orderIndex;
                      step = ((max - index) / (array.length - i));
                    } else {
                      const localMin = index;
                      index += step;
                      logs[doc.id].push(`%c${el.id}(${level}): idx=${el.orderIndex}, min=${min}, max=${max}, localMin=${localMin}, new=${index}, title=${el.title}`);
                      el.orderIndex = index;
                      if (fix) {
                        const ref = querySnapshot.docs.find(it => it.id === el.id).ref;
                        cache.push({ ref: ref, obj: { orderIndex: index } });
                      }
                    }
                    const firstEl = array[i];
                    if ((i + 1) !== array.length) {
                      const secondEl = array[i + 1];
                      if (secondEl.obj.orderIndex > min && secondEl.obj.orderIndex < max && secondEl.obj.orderIndex > index) {
                        isValidRec(firstEl.items, firstEl.obj.orderIndex, secondEl.obj.orderIndex);
                      } else {
                        isValidRec(firstEl.items, firstEl.obj.orderIndex, index + step);
                      }
                    } else {
                      isValidRec(firstEl.items, firstEl.obj.orderIndex, max);
                    }
                  }
                  level--;
                };

                const isValid = (array: IItem[]) => {
                  for (let i = 0; i < array.length; i++) {
                    const firstEl = array[i];
                    logs[doc.id].push(`${firstEl.obj.id}(${level}): idx=${firstEl.obj.orderIndex}, title=${firstEl.obj.title}`);
                    if ((i + 1) !== array.length) {
                      const secondEl = array[i + 1];
                      isValidRec(firstEl.items, firstEl.obj.orderIndex, secondEl.obj.orderIndex);
                    } else {
                      isValidRec(firstEl.items, firstEl.obj.orderIndex, maxIdx + 1000000000);
                    }
                  }
                };

                logs[doc.id] = [];

                const resultArray = buildHierarchy(timelineSorted);
                const maxIdx = timelineSorted.map(it => it.orderIndex).sort((a, b) => a - b).reverse()[0];

                let level = 1;
                if (resultArray.length === 1) {
                  isValid(resultArray[0].items);
                } else {
                  console.log(`${doc.id}%cMore 1 root section in results!!!`, 'color: red');
                }
              }
            });
        }

        const issuesCounter = [];
        for (const id of Object.keys(logs)) {
          const eLog = logs[id];
          const withIssues = eLog.some(it => it.includes('%c'));
          if (!withIssues) {
            continue;
          }
          issuesCounter.push(id);
          console.log(`%cModule id=${id}`, 'color: blue');
          for (const it of eLog) {
            if (it.includes('%c')) {
              console.log(it, 'color: red');
            } else {
              console.log(it);
            }
          }
        }
        console.log(`Modules with issues ${issuesCounter.length}`, issuesCounter);
      }).then(() => {
        if (fix) {
          this.commitCache(cache);
        }
        console.log('~~~~~ log client ~~~~~', clientId);
      });
  }

  async fixEventParentStructure() {
    const getParents = (section: SectionContent, list: SectionContent[]) => {
      const result: SectionContent[] = [];
      if (!section.parentId) {
        return result;
      }
      let parent = list.find(s => s.id === section.parentId);
      while (parent && !parent.isRoot) {
        result.push(parent);
        parent = list.find(s => s.id === parent.parentId);
      }
      return result;
    };

    if (!await this.commonService.confirmation('Run: Check events structure by parentId in current client')) {
      return;
    }
    const clientId = this.loginService.client_id$.getValue();
    console.log('clientId: ', clientId);
    let eventsLength = 0;
    let counter = 0;
    const queryFn = q => q.where('deleted', '==', 0);
    console.log('~~~~~ start client ~~~~~', clientId);
    await firstValueFrom(this.afs.collection('client_data').doc(clientId)
      .collection('events', queryFn).get())
      .then(async (snapshot) => {
        const events = snapshot.docs;
        eventsLength = events.length;
        const logs: { [key: string]: string[] } = {};
        for (const doc of events) {
          const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
          console.log(`check(id=${doc.id}, link=${event.shortLink}):`, ++counter, 'of', eventsLength);
          await firstValueFrom(this.afs.collection('client_data').doc(clientId)
            .collection('conference').doc(doc.id)
            .collection('timeline').get())
            .then((querySnapshot) => {
              const timeline: SectionContent[] = querySnapshot.docs.map(d => new SectionContent({ id: d.id, ...d.data() }))
                .filter(it => !it.fixedSectionType);
              const withParentNull = timeline.filter(it => !it.isRoot && !it.parentId);
              if (withParentNull.length > 1) {
                if (!logs[doc.id]) {
                  logs[doc.id] = [];
                }
                for (const section of withParentNull) {
                  const childs = timeline.filter(it => it.parentId === section.id);
                  logs[doc.id].push(`%cSection=${section.id} parent is null, education=${section.education}, childs=${childs.length}`);
                }
              }
              for (const section of timeline) {
                if (section.isRoot) {
                  continue;
                }
                const parent = timeline.find(it => it.id === section.parentId);
                if (parent == null) {
                  if (!logs[doc.id]) {
                    logs[doc.id] = [];
                  }
                  const childs = timeline.filter(it => it.parentId === section.id);
                  logs[doc.id].push(`Section=${section.id} have not exist parent=${section.parentId}, education=${section.education}, childs=${childs.length}`);
                  continue;
                }
                if (section.education && !section.deleted) {
                  const parents = getParents(section, timeline);
                  if (!isEmpty(parents) && parents.some(p => p.deleted)) {
                    if (!logs[doc.id]) {
                      logs[doc.id] = [];
                    }
                    logs[doc.id].push(`%cSection=${section.id} not deleted but have deleted parents=${parents.filter(s => s.deleted).map(s => s.id)}`);
                  }
                }
              }
            });
        }

        const issuesCounter = [];
        for (const id of Object.keys(logs)) {
          const eLog = logs[id];
          issuesCounter.push(id);
          console.log(`%cEvent id=${id}`, 'color: blue');
          for (const it of eLog) {
            if (it.includes('%c')) {
              console.log(it, 'color: red');
            } else {
              console.log(it);
            }
          }
        }
        console.log(`Events with issues ${issuesCounter.length}`, issuesCounter);
      }).then(() => {
        console.log('~~~~~ log client ~~~~~', clientId);
      });
  }

  async checkOldFields() {
    const fields = ['moods', 'messages', 'instantSettings'];
    const updateClient = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events', q => q.where('deleted', '==', 0)).get())
        .then(async (snapshot) => {
          const events = snapshot.docs.map(it => new Event({ eventId: it.id, ...it.data() }));
          // .filter(it => it.eventId === '0PYugHyJWdqq2pPRMatK');
          for (const event of events) {
            await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('conference').doc(event.eventId)
              .collection('timeline').get())
              .then((querySnapshot) => {
                for (const doc of querySnapshot.docs) {
                  for (const field of fields) {
                    if (doc.data().hasOwnProperty(field)) {
                      const logObj = {
                        date: this.utils.formatDate(event.startDate, DATE_FORMAT.DD_MM_YYYY_HH_mm),
                        value: doc.data()[field]
                      };
                      console.log('eid=' + event.eventId + ' sid=' + doc.id + ' field=' + field, logObj);
                      const obj = {
                        [field]: firebase.firestore.FieldValue.delete()
                      };
                      cache.push({ ref: doc.ref, obj: obj });
                    }
                  }
                }
              });
          }
        });
    };

    const cache: IWriteCache[] = [];

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const clientId of clients) {
      console.log(`Client ${clientId} started`);
      await updateClient(clientId);
      console.log(`Client ${clientId} finished`);
    }

    await this.commitCache(cache);

    console.log('Finish!');
    this.progress.next('All tasks finished');
  }

  async rebuildQuizSummary() {
    const rebuild = (clientId) => {
      let eventsLength = 0;
      let counter = 0;
      let deletedCounter = 0;
      console.log(`Client ${clientId} started`);
      this.status1.push('Start process clientId ' + clientId + ' ' +
        this.utils.formatDate(new Date().getTime(), DATE_FORMAT.DD_MM_YYYY_HH_mm));
      return this.afs.collection('client_data').doc(clientId)
        .collection('events')
        .get()
        .toPromise()
        .then((snapshot) => {
          const events = snapshot.docs;
          let secPromise = Promise.resolve();
          eventsLength = events.length;
          for (const doc of events) {
            const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
            if (event.deleted) {
              deletedCounter++;
              continue;
            }
            secPromise = secPromise.then(() => {
              return this.afs
                .collection('client_data').doc(clientId)
                .collection('conference').doc(event.eventId)
                .collection('timeline')
                .get()
                .toPromise()
                .then((snapshotSections) => {
                  const sections: SectionContent[] = snapshotSections.docs.map(d => new SectionContent({ id: d.id, ...d.data() }));
                  let contentsPromise = Promise.resolve();
                  for (const section of sections) {
                    contentsPromise = contentsPromise.then(() => {
                      return this.afs
                        .collection('client_data').doc(clientId)
                        .collection('conference').doc(event.eventId)
                        .collection('timeline')
                        .doc(section.id)
                        .collection('contents', q =>
                          q.where('type', '==', Constants.CONTENT_TYPE_CONTENT_CONTAINER)
                            .where('itemsTypes', 'array-contains', CONTAINER_ITEM_TYPE.QUIZ))
                        .get().toPromise().then(async contentsSnap => {
                          const contentsDocs = contentsSnap.docs;
                          let docCounter = 0;
                          for (const contentDoc of contentsDocs) {
                            // const content = new ContentContainer({id: contentDoc.id, ...contentDoc.data()});
                            const quizItems = contentDoc.data().items.filter(it => it.type === CONTAINER_ITEM_TYPE.QUIZ);
                            for (const item of quizItems) {
                              const path: IDocumentPathParams = {
                                eventId: event.eventId,
                                sectionId: section.id,
                                contentId: contentDoc.id,
                                containerId: item.id
                              };
                              const hasAnswers = await firstValueFrom(this.afs
                                .collection('client_data').doc(clientId)
                                .collection('conference').doc(event.eventId)
                                .collection('timeline').doc(section.id)
                                .collection('contents').doc(contentDoc.id)
                                .collection('containers').doc(item.id)
                                .collection('answers', q => q.limit(1)).get());
                              if (hasAnswers.docs.length) {
                                await this.eventDataService.buildQuizSummary(clientId, path)
                                  .catch((e) => {
                                    errorList.push({ error: e, path: path });
                                    console.log('%cError rebuild for path%c', 'color: red',
                                      'color: blue', path, 'color: black', e);
                                  });
                              }
                            }
                            console.log('processed eventId', event.eventId,
                              'sectionId', section.id, ++docCounter, 'of', contentsDocs.length);
                          }
                          return Promise.resolve();
                        });
                    });
                  }
                  return contentsPromise;
                });
            }).then(() => {
              console.log('processed ' + (++counter) + ' of ' + (eventsLength - deletedCounter));
              return Promise.resolve();
            });
          }
          return secPromise;
        }).then(() => {
          console.log('End process clientId', clientId);
          this.status1.push('End process clientId ' + clientId + ' ' +
            this.utils.formatDate(new Date().getTime(), DATE_FORMAT.DD_MM_YYYY_HH_mm));
          return Promise.resolve(true);
        });
    };

    if (!await this.commonService.confirmation('Run: Rebuild quiz answers summary')) {
      return;
    }
    const errorList: { error, path }[] = [];
    const clients: ClientConfig[] = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => {
          const obj = it.data();
          obj['id'] = it.id;
          return new ClientConfig(obj);
        });
      });
    this.status1 = [];
    console.log('Start process.');
    this.status1.push('Start process ' + clients.length + ' clients');
    for (const client of clients) {
      await rebuild(client.id);
    }
    console.log('End process.');
    this.status1.push('End process ' + clients.length + ' clients');
    if (errorList.length > 0) {
      console.log('%cProcess end with error%c', 'color: red', 'color: black', errorList);
    }
  }

  async checkDuplicateUsers() {
    const updateClient = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('app_users').get())
        .then((userSnapshot) => {
          const emails = {};
          for (const userDoc of userSnapshot.docs) {
            if (userDoc.get('email') == null) {
              continue;
            }
            if (!emails[userDoc.get('email')]) {
              emails[userDoc.get('email')] = userDoc.id;
            } else {
              console.log('Duplicate user', emails[userDoc.get('email')], userDoc.id, userDoc.get('email'));
            }
          }
        });
    };

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const clientId of clients) {
      console.log(`Client ${clientId} started`);
      await updateClient(clientId);
      console.log(`Client ${clientId} finished`);
    }

    console.log('Finish!');
    this.progress.next('All tasks finished');
  }

  async linkModulesWithSections(updateLink: boolean) {

    const process = async (clientId) => {
      console.log(`Client ${clientId} started`);
      const modulesList: EduModule[] = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('modules', ref => ref.where('deleted', '==', 0))
        .get()).then(snapshot => snapshot.docs
          .map(d => <EduModule>new Object({ ...d.data(), id: d.id })));
      const eventsList: Event[] = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events', ref => ref.where('deleted', '==', 0))
        .get()).then(snapshot => snapshot.docs.map(d => new Event({ eventId: d.id, ...d.data() })));
      let i = 1;
      for (const event of eventsList) {
        console.log('processed event:', i++, 'of', eventsList.length);
        logEventWithSectionAsModule[event.eventId] = {
          eventId: event.eventId,
          shortLink: event.shortLink,
          hasModules: false,
          hasDuplicate: false,
          name: event.name,
          createDate: event.createDate.getTime(),
          subjects: [],
          link: []
        };
        let sections: SectionContent[] = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
          .collection('conference').doc(event.eventId).collection('timeline')
          .get()).then(snapshot => snapshot.docs.map(d => new SectionContent({ id: d.id, ...d.data() })));
        if (event.slotMode) {
          sections = sections.filter(s => s.education || s.isRoot);
        }
        const root = sections.find(s => s.isRoot);
        for (const s of sections) {
          if (s.isRoot) {
            continue;
          }
          const parent = sections.find(o => o.id === s.parentId);
          if (!parent) {
            continue;
          }
          if (parent.parentId !== root.id) {
            continue;
          }
          let modules = modulesList.filter(m => m.name?.trim() === s.title?.trim());
          if (modules.length > 1) {
            const md = modules.find(o => o.description?.trim() === s.subtitle?.trim());
            if (md) {
              modules = [md];
            }
          }
          if (modules.length > 0) {
            logEventWithSectionAsModule[event.eventId].link.push(
              { sectionId: s.id, matchingCount: modules.length, modules: modules.map(o => o.id), name: s.title });
            logEventWithSectionAsModule[event.eventId].hasModules = true;
          }
          if (!logEventWithSectionAsModule[event.eventId].hasDuplicate && modules.length > 1) {
            logEventWithSectionAsModule[event.eventId].hasDuplicate = true;
          }
          if (s.subjects) {
            logEventWithSectionAsModule[event.eventId].subjects =
              unionBy(logEventWithSectionAsModule[event.eventId].subjects, s.subjects, 'shortName');
          }
        }
        logEventWithSectionAsModule[event.eventId].link.sort(this.utils.comparator('matchingCount'));
      }

      i = 1;
      const eventWithoutModules = eventsList.filter(e => !logEventWithSectionAsModule[e.eventId].hasModules);
      for (const event of eventWithoutModules) {
        console.log('processed event without modules:', i++, 'of', eventWithoutModules.length);
        let modules = modulesList.filter(m => m.name?.trim() === event.name?.trim());
        if (modules.length > 1) {
          logEventAsModule[event.eventId] = {
            eventId: event.eventId,
            shortLink: event.shortLink,
            modulesLink: [],
            name: event.name
          };
          const md = modules.find(o => o.description?.trim() === event.description?.trim());
          if (md) {
            modules = [md];
          }
          if (modules.length > 1) {
            logEventAsModule[event.eventId].modulesLink = modules.map(m => m.id);
          }
        }
      }

      console.log('get curriculums');
      const curriculums: Curriculum[] = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('curriculums')
        .get()).then(snapshot => snapshot.docs.map(d => <Curriculum>new Object({ ...d.data(), id: d.id })));
      const curriculumsLog: { [id: string]: any[] } = {};
      const curriculumsTimeBlock: { [curriculumId: string]: CurriculumTimeBlock[] } = {};
      for (const curriculum of curriculums) {
        curriculumsLog[curriculum.id] = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
          .collection('export_to_timeline_event_log').doc(curriculum.id).collection('curriculum_path_export')
          .get()).then(snapshot => snapshot.docs
            .map(d => <IObject>new Object({ id: d.id, ...d.data() }))
            .filter(d => d[d.id].find(r => r.status === 'end'))
            .map(d => new Object({ ...d, name: d[d.id].find(r => r.status === 'start').info })));
        curriculumsTimeBlock[curriculum.id] = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
          .collection('curriculums').doc(curriculum.id).collection('timeblocks')
          .get()).then(snapshot => snapshot.docs.map(d => <CurriculumTimeBlock>new Object({ id: d.id, ...d.data() })));
      }

      const eventWithModules = eventsList.filter(e => logEventWithSectionAsModule[e.eventId].hasModules);
      const linkEventToCurriculum: { eventId, hasCurriculum, name: string, curriculumId: string[] }[] = [];
      for (const event of eventWithModules) {
        const eventLink = { eventId: event.eventId, hasCurriculum: false, name: event.name, curriculumId: [] };
        for (const clogId of Object.keys(curriculumsLog)) {
          const clog = curriculumsLog[clogId];
          for (const value of clog) {
            if (event.name === value['name'] && !eventLink.curriculumId.includes(clogId)) {
              eventLink.curriculumId.push(clogId);
              eventLink.hasCurriculum = true;
            }
          }
        }
        linkEventToCurriculum.push(eventLink);
      }

      console.log('all event');
      console.log(Object.values(logEventWithSectionAsModule));
      console.log('simple event');
      console.log(Object.values(Object.values(logEventWithSectionAsModule)
        .filter(o => !logEventAsModule[o.eventId] && !Object.values(logEventWithSectionAsModule)
          .find(e => e.eventId === o.eventId).hasModules)
        .map(o => {
          delete o.hasModules;
          delete o.hasDuplicate;
          delete o.subjects;
          delete o.link;
          return new Object({ ...o });
        })));
      console.log('event possibly created from modules');
      console.log(logEventAsModule);
      console.log('event with modules link to curriculum');
      console.log(linkEventToCurriculum.sort(this.utils.comparator('hasCurriculum')));
      console.log('event has modules');
      const eventHasModules = Object.values(logEventWithSectionAsModule)
        .filter(o => o.hasModules)
        .map(l => {
          delete l.hasModules;
          return new Object({ ...l, curriculumLink: linkEventToCurriculum.find(lnk => lnk.eventId === l.eventId) });
        })
        .sort(this.utils.comparator('hasDuplicate'));
      console.log(eventHasModules);
      if (updateLink) {
        console.log('Start update');
        // update sections
        let ind = 1;
        for (const eventObject of eventHasModules) {
          console.log('update event sections:', ind++, 'of', eventHasModules.length);
          if (eventObject['hasDuplicate']) {
            continue;
          }
          for (const section of eventObject['link']) {
            const eventId = eventObject['eventId'];
            const sectionId = section['sectionId'];
            const moduleId = section['modules'][0];
            const entityLink: IContentEntityLink = {
              entityId: moduleId,
              entityType: ENTITY_LINK_TYPE.MODULE
            };
            await this.afs.collection('client_data').doc(clientId)
              .collection('conference').doc(eventId)
              .collection('timeline').doc(sectionId)
              .set({ entityLink: entityLink }, { merge: true });
          }
        }
        // update events
        let upd = 0;
        let skip = 0;
        for (const event of linkEventToCurriculum) {
          if (!event.hasCurriculum) {
            console.log('skip event:', ++skip, 'of', linkEventToCurriculum.length);
            continue;
          } else {
            console.log('update event:', ++upd, 'of', linkEventToCurriculum.length);
          }
          const curriculum = curriculums.find(c => c.id === event.curriculumId[0]);
          let timeBlock: CurriculumTimeBlock = null;
          for (const tb of curriculumsTimeBlock[curriculum.id]) {
            if (event.name.toUpperCase().includes(` ${tb.type.toUpperCase()} `) ||
              event.name.toUpperCase().endsWith(` ${tb.type.toUpperCase()}`)) {
              timeBlock = tb;
              break;
            }
          }
          const subjects = curriculum.subjects.filter(sub => logEventWithSectionAsModule[event.eventId]
            .subjects.find(sb => sb.shortName === sub.shortName));
          const entityLink: IEventEntityLink = {
            entityId: event.curriculumId[0],
            entityType: ENTITY_LINK_TYPE.CURRICULUM,
            params: {
              curriculumId: event.curriculumId[0],
              learningPlaceId: curriculum.learningPlaces[0].id,
              timeBlockId: timeBlock?.id ?? null,
              subjectList: subjects
            }
          };
          await this.afs.collection('client_data').doc(clientId)
            .collection('events').doc(event.eventId)
            .set({ entityLink: entityLink }, { merge: true });
        }
        console.log('End update');
      }
    };

    if (!updateLink) {
      if (!await this.commonService.confirmation('Show information about link modules with event sections')) {
        return;
      }
    } else {
      if (!await this.commonService.confirmation('Update sections with modules link!')) {
        return;
      }
    }
    console.log('Start process.');
    const logEventWithSectionAsModule: {
      [eventId: string]: {
        eventId: string,
        shortLink: string;
        name: string;
        hasModules: boolean;
        hasDuplicate: boolean;
        createDate: number;
        subjects: ISectionSubject[];
        link: { sectionId, name, matchingCount: number, modules: string[] }[]
      }
    } = {};
    const logEventAsModule: {
      [eventId: string]: {
        eventId: string,
        shortLink: string;
        name: string;
        modulesLink: string[]
      }
    } = {};
    await process(this.currentClientId);
    console.log('End process.');
  }

  async setModuleVersion() {
    const updateClient = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('modules').get())
        .then(async modulesSnapshot => {
          const moduleDocList = modulesSnapshot.docs.filter(d => !d['deleted']);
          let counter = 0;
          for (const moduleDoc of moduleDocList) {
            console.log('process', ++counter, 'of', moduleDocList.length);
            const module: Event = moduleDoc.data() as Event;
            const releaseVersion = module.releaseVersion?.version?.toString() ?? '2023-12-23';
            cache.push({ ref: moduleDoc.ref, obj: { releaseVersion: { version: releaseVersion } } });
            const sectionDocsList = await firstValueFrom(this.afs
              .collection('client_data').doc(clientId)
              .collection('modules').doc(moduleDoc.id)
              .collection('timeline')
              .get());
            for (const sectionDoc of sectionDocsList.docs) {
              const contentsDocList = await firstValueFrom(this.afs
                .collection('client_data').doc(clientId)
                .collection('modules').doc(moduleDoc.id)
                .collection('timeline')
                .doc(sectionDoc.id)
                .collection('contents').get());
              for (const contentDoc of contentsDocList.docs) {
                if (!contentDoc.data().releaseVersion) {
                  cache.push({ref: contentDoc.ref, obj: {releaseVersion: releaseVersion.toString()}});
                }
              }
            }
          }
        });
    };

    const cache: IWriteCache[] = [];

    if (!await this.commonService.confirmation('Run: Set modules and modules contents version')) {
      return;
    }
    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });
    console.log('Clients', clients);
    for (const clientId of clients) {
      console.log(`Client ${clientId} started`);
      await updateClient(clientId);
      console.log(`Client ${clientId} finished`);
    }

    await this.commitCache(cache);

    console.log('End process.');
  }

  async setAutoSyncSlots() {
    const updateClient = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events', (q) => q.where('slotMode', '==', true)).get())
        .then((eventsSnapshot) => {
          const filtered = eventsSnapshot.docs.filter(it => !!it.get('classCode'));
          const size = filtered.length;
          let counter = 0;
          for (const eventDoc of filtered) {
            if (!eventDoc.get('classCodeAutoupdate')) {
              cache.push({ ref: eventDoc.ref, obj: { classCodeAutoupdate: true } });
            }
            if (!!eventDoc.get('endDateFixed') && !!eventDoc.get('classCode')) {
              cache.push({ ref: eventDoc.ref, obj: { durationFixed: false } });
            }
            console.log(`Event ${++counter} of ${size}`, eventDoc.id, eventDoc.get('shortLink'), eventDoc.get('classCode'));
          }
        });
    };

    const cache: IWriteCache[] = [];
    const clients = [this.loginService.client_id$.getValue()];

    console.log('Clients', clients);
    for (const clientId of clients) {
      console.log(`Client ${clientId} started`);
      await updateClient(clientId);
      console.log(`Client ${clientId} finished`);
    }

    await this.commitCache(cache);

    console.log('All tasks finished');
    this.progress.next('All tasks finished');
  }

  async updateTimeblockId() {
    const updateClient = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events', (q) => q.where('entityLink.params.curriculumId', '!=', '')).get())
        .then(async (eventsSnapshot) => {
          const filtered = eventsSnapshot.docs.filter(it => !it.get('entityLink.params.timeBlockId'));

          const curIds: {
            [key: string]: any[]
          } = {};

          const size = filtered.length;
          let counter = 0;
          for (const eventDoc of filtered) {
            const curId = eventDoc.get('entityLink.params.curriculumId');
            if (!curIds[curId]) {
              const timeblocks = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
                .collection('curriculums').doc(curId)
                .collection('timeblocks').get())
                .then((tbSnapshot) => {
                  return tbSnapshot.docs.map(it => new Object({ id: it.id, ...it.data() }));
                });
              curIds[curId] = timeblocks;
            }
            const tbId = curIds[curId].length > 1 ? null : curIds[curId].find(it => !!it.id).id;
            if (tbId) {
              cache.push({ ref: eventDoc.ref, obj: { entityLink: { params: { timeBlockId: tbId } } } });
            }
            console.log(`Event ${++counter} of ${size}`, eventDoc.id, eventDoc.get('entityLink'), eventDoc.get('entityLink.params.curriculumId'), tbId);
          }
          console.log('ids', curIds);
        });
    };

    const cache: IWriteCache[] = [];
    const clients = [this.loginService.client_id$.getValue()];

    console.log('Clients', clients);
    for (const clientId of clients) {
      console.log(`Client ${clientId} started`);
      await updateClient(clientId);
      console.log(`Client ${clientId} finished`);
    }

    await this.commitCache(cache);

    console.log('All tasks finished');
    this.progress.next('All tasks finished');
  }

  async updateEventStraightawayTimeline() {
    const updateClient = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events', (q) => q.where('entityLink.params.curriculumId', '!=', '')).get())
        .then(async (eventsSnapshot) => {
          let isEducation = false;

          const filtered = eventsSnapshot.docs;
          const size = filtered.length;
          let counter = 0;
          for (const eventDoc of filtered) {
            const entityLink = eventDoc.get('entityLink');
            if (entityLink.hasOwnProperty('straightawayTimeline')) {
              console.log(`Exist. Event ${++counter} of ${size}`, eventDoc.id, eventDoc.get('entityLink'), eventDoc.get('entityLink.params.curriculumId'));
              continue;
            }
            const sections = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('conference').doc(eventDoc.id)
              .collection('timeline', q => q.where('education', '==', true).limit(1)).get())
              .then((tlSnapshot) => {
                return tlSnapshot.docs.map(it => new Object({ id: it.id, ...it.data() }));
              });
            isEducation = !isEmpty(sections);

            if (!isEducation) {
              cache.push({ ref: eventDoc.ref, obj: { entityLink: { straightawayTimeline: true } } });
              console.log(`Update. Event ${++counter} of ${size}`, eventDoc.id, eventDoc.get('entityLink'), eventDoc.get('entityLink.params.curriculumId'));
            } else {
              console.log(`Skip. Event ${++counter} of ${size}`, eventDoc.id, eventDoc.get('entityLink'), eventDoc.get('entityLink.params.curriculumId'));
            }
          }
        });
    };

    const cache: IWriteCache[] = [];
    const clients = [this.loginService.client_id$.getValue()];

    console.log('Clients', clients);
    for (const clientId of clients) {
      console.log(`Client ${clientId} started`);
      await updateClient(clientId);
      console.log(`Client ${clientId} finished`);
    }

    await this.commitCache(cache);

    console.log('All tasks finished');
    this.progress.next('All tasks finished');
  }

  async createEventsStatisticsByUsersCountry() {

    const getUser = (clientId: string, userId: string) => {
      return firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('app_users').doc(userId)
        .get())
        .then((user) => {
          return user.data() ? new AppUser({ userId: userId, ...user.data() }) : null;
        });
    };


    const rebuild = (client: ClientConfig) => {
      const clientId = client.id;
      let eventsLength = 0;
      let counter = 0;
      const appUsers: { [userId: string]: AppUser } = {};
      console.log(`Client ${clientId} started`);
      this.status2.push('Start process clientId ' + clientId + ' ' +
        this.utils.formatDate(new Date().getTime(), DATE_FORMAT.DD_MM_YYYY_HH_mm));
      return this.afs.collection('client_data').doc(clientId)
        .collection('events')
        .get()
        .toPromise()
        .then((snapshot) => {
          const events = snapshot.docs.filter(d => !d.data().deleted);
          let secPromise = Promise.resolve();
          eventsLength = events.length;
          for (const doc of events) {
            const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
            secPromise = secPromise.then(() => {
              console.log('process ', ++counter, ' of ', eventsLength);
              let skipCounter = 0;
              return firstValueFrom(this.afs
                .collection('client_data').doc(clientId)
                .collection('conference').doc(event.eventId)
                .collection('users_online').get())
                .then(async snapshotSections => {
                  const users: ConferenceUser[] = snapshotSections.docs.map(d => new ConferenceUser({ id: d.id, ...d.data() }));
                  const statistics: {
                    [countryCode: string]: {
                      countryName: string,
                      countryTotal: number,
                      languages: {
                        [language: string]: number
                      }
                    }
                  } = {};
                  console.log('create statistics: ', event.eventId, event.shortLink, 'users online count: ', users.length);
                  for (const user of users) {
                    let countryCode = user.countryCode;
                    let countryName = user.country;
                    let language = user.language;
                    if (!countryCode) {
                      let appUser = appUsers[user.userId];
                      if (!appUser) {
                        appUser = await getUser(clientId, user.userId);
                        if (appUser) {
                          appUsers[user.userId] = appUser;
                        }
                        if (!appUser || !appUser.countryCode) {
                          skipCounter++;
                          continue;
                        }
                      }
                      if (!appUser.countryCode) {
                        skipCounter++;
                        continue;
                      }
                      countryCode = appUser.countryCode;
                      countryName = appUser.country;
                      language = appUser.language;
                    }
                    if (!language) {
                      language = client.language;
                    }
                    const country = statistics[countryCode];
                    if (!country) {
                      statistics[countryCode] = { countryName: countryName, countryTotal: 1, languages: { [language]: 1 } };
                    } else {
                      country.countryTotal += 1;
                      if (!country.languages[language]) {
                        country.languages[language] = 1;
                      } else {
                        country.languages[language] += 1;
                      }
                    }
                  }
                  console.log('save statistics: ', event.eventId, event.shortLink, 'users online count: ', users.length,
                    'skip count: ', skipCounter, 'statistics: ', statistics);
                  return this.afs.collection('client_data').doc(clientId)
                    .collection('conference').doc(event.eventId)
                    .collection('statistics')
                    .doc('country_statistics').set(statistics, { merge: false });
                });
            });
          }
          return secPromise;
        }).then(() => {
          console.log('End process clientId', clientId);
          this.status2.push('End process clientId ' + clientId + ' ' +
            this.utils.formatDate(new Date().getTime(), DATE_FORMAT.DD_MM_YYYY_HH_mm));
          return Promise.resolve(true);
        });
    };

    if (!await this.commonService.confirmation('Run: Create events statistics by users country')) {
      return;
    }
    const clients: ClientConfig[] = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => {
          const obj = it.data();
          obj['id'] = it.id;
          return new ClientConfig(obj);
        });
      });
    this.status2 = [];
    console.log('Start process.');
    this.status2.push('Start process ' + clients.length + ' clients');
    for (const client of clients) {
      await rebuild(client);
    }
    console.log('End process.');
    this.status2.push('End process ' + clients.length + ' clients');
  }

  async checkTimelineStructureServerMethod() {
    if (!await this.commonService.confirmation('Run: Check events tree structure by orderIndex and parentId (server method)')) {
      return;
    }
    const clientId = this.loginService.client_id$.getValue();
    console.log('clientId: ', clientId);
    let eventsLength = 0;
    let counter = 0;
    let hasWarning = false;
    const warning = [];
    const queryFn = q => q.where('deleted', '==', 0);
    const deviceRef = this.afDB.database
      .ref(`client_data/${clientId}/service_task_check_timeline_log`);
    await deviceRef.remove();
    await firstValueFrom(this.afs.collection('client_data').doc(clientId)
      .collection('events', queryFn).get())
      .then(async snapshot => {
        const events = snapshot.docs;
        eventsLength = events.length;
        const logs: { [key: string]: string[] } = {};
        for (const doc of events) {
          const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
          console.log(`check(id=${doc.id}, link=${event.shortLink}):`, ++counter, 'of', eventsLength);
          const checkResult = await this.dataService
            .checkEventTimelineStructure(event.eventId, 'service_task_check_timeline_log');
          console.log(`check(id=${doc.id}, link=${event.shortLink}):`, checkResult);
          if (checkResult === 'warning') {
            hasWarning = true;
            warning.push(event.eventId);
          }
        }
      });
    if (hasWarning) {
      await deviceRef.once('value', snap => {
        const logs = snap.val();
        if (!isEmpty(logs)) {
          console.log('~~~~~~~~~ log ~~~~~~~~~');
          const eventsIds = Object.keys(logs || {});
          for (const eventId of eventsIds) {
            console.log('%cEvent id: ' + eventId, 'color: orange');
            for (const logKey of Object.keys(logs[eventId])) {
              const log: any[] = logs[eventId][logKey];
              console.log(logKey);
              if (logKey.includes('-timeline') || logKey.includes('-education')) {
                log.forEach(r => {
                  if (r.withIssue) {
                    console.log(`%c${r.id}(${r.level}): idx=${r.idx}, localMinIdx=${r.localMinIdx},
                   localMaxIdx=${r.localMaxIdx}, maxIdx=${r.maxIdx}, minIdx=${r.minIdx}, newIdx=${r.newIdx}, title=${r.title},
                   withIssue=${r.withIssue}`, 'color: red');
                  } else {
                    console.log(`${r.id}(${r.level}): idx=${r.idx}, title=${r.title}, withIssue=${r.withIssue}`);
                  }
                });
              } else {
                log.forEach(r => console.log(r));
              }
            }
          }
        }
      });
    }
    console.log('End process. warning: ', warning);
  }

  async viewImportSlotsFBLog(datesList: boolean, dateLog: string, eventId: string) {
    const clientId = this.loginService.client_id$.getValue();
    if (datesList) {
      const deviceRef = this.afDB.database
        .ref(`client_data/${clientId}/import_slots_log`);
      await deviceRef.once('value', snap => {
        const logs = snap.val();
        if (!isEmpty(logs)) {
          const dates = Object.keys(logs || {});
          console.log(dates);
        }
      });
    } else if (dateLog && !eventId) {
      const deviceRef = this.afDB.database
        .ref(`client_data/${clientId}/import_slots_log/${dateLog}`);
      await deviceRef.once('value', snap => {
        const logs = snap.val();
        if (!isEmpty(logs)) {
          const events = Object.keys(logs || {});
          console.log(events);
        }
      });
    } else if (dateLog && eventId) {
      const deviceRef = this.afDB.database
        .ref(`client_data/${clientId}/import_slots_log/${dateLog}/${eventId}`);
      await deviceRef.once('value', snap => {
        const logs = snap.val();
        if (!isEmpty(logs)) {
          for (const ba of ['before', 'after']) {
            console.log(`%c~~~~~~~~~ ${ba.toUpperCase()} ~~~~~~~~~`, 'color: lime');
            for (const logKey of Object.keys(logs[ba])) {
              const log: any[] = logs[ba][logKey];
              console.log(`%c~~~~~~~~~ ${logKey.toUpperCase()} ~~~~~~~~~`, 'color: darkmagenta');
              if (logKey.includes('-timeline') || logKey.includes('-education')) {
                log.forEach(r => {
                  if (r.withIssue) {
                    console.log(`%c${r.id}(${r.level}): idx=${r.idx}, localMinIdx=${r.localMinIdx},
                   localMaxIdx=${r.localMaxIdx}, maxIdx=${r.maxIdx}, minIdx=${r.minIdx}, newIdx=${r.newIdx}, title=${r.title},
                   withIssue=${r.withIssue}`, 'color: red');
                  } else {
                    console.log(`${r.id}(${r.level}): idx=${r.idx}, title=${r.title}, withIssue=${r.withIssue}`);
                  }
                });
              } else {
                log.forEach(r => console.log(r));
              }
            }
          }
        }
      });
    }
    console.log('End process.');
  }

  async fixSectionBackgroundImage() {

    const downloadUrl = (eventId: string, path: string, clntId) => {
      const reference = this.eventModeApi.getStorageRef(`conference/${eventId}/${path}`, clntId);
      return reference.getDownloadURL();
    };

    const copyImage = (clId, mId, mSId, evnId, evnSId) => {
      const callable = this.aff.httpsCallable('copyStorageObject');
      const obj = {
        pathFrom: `client_data/${clId}/modules/${mId}/image/${mSId}/${mSId}.png`,
        pathTo: `client_data/${clId}/conference/${evnId}/image/${evnSId}/${evnSId}.png`
      };
      return firstValueFrom(callable(obj))
        .then(() => downloadUrl(evnId, `image/${evnSId}/${evnSId}.png`, clId)
          .then(url => this.afs.collection('client_data').doc(clId)
            .collection('conference')
            .doc(evnId)
            .collection('timeline')
            .doc(evnSId)
            .set({ backgroundImage: url ? url : null }, { merge: true })));
    };

    if (!await this.commonService.confirmation('Run: Fix section background image')) {
      return;
    }

    const clientId = this.loginService.client_id$.getValue();
    const modulesCache: { [key: string]: number } = {};
    return firstValueFrom(this.afs.collection('client_data').doc(clientId)
      .collection('events').get())
      .then(async snapshot => {
        const events = snapshot.docs.filter(d => !d.data().deleted);
        const eventsLength = events.length;
        let counter = 0;
        for (const doc of events) {
          const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
          console.log('process ', ++counter, ' of ', eventsLength);
          await firstValueFrom(this.afs
            .collection('client_data').doc(clientId)
            .collection('conference').doc(event.eventId)
            .collection('timeline').get()).then(async snap => {
              const sections: SectionContent[] = (snap.docs || []).map(d => new SectionContent({ id: d.id, ...d.data() }))
                .filter(s => s.entityLink?.entityType === 'section');
              for (const section of sections) {
                if (section.backgroundImage?.includes(section.id)) {
                  continue;
                }
                const moduleId = section.entityLink.entityId;
                const moduleSectionId = section.entityLink.sectionId;
                if (!modulesCache[`${moduleId}-${moduleSectionId}`]) {
                  await firstValueFrom(this.afs
                    .collection('client_data').doc(clientId)
                    .collection('modules').doc(moduleId)
                    .collection('timeline').doc(moduleSectionId).get()).then(async snapDoc => {
                      if (snapDoc.exists) {
                        if (snapDoc.data().backgroundImage) {
                          modulesCache[`${moduleId}-${moduleSectionId}`] = 1;
                          console.log('update eventId: ', event.eventId, 'sectionId: ', section.id);
                          await copyImage(clientId, moduleId, moduleSectionId, event.eventId, section.id);
                        } else {
                          modulesCache[`${moduleId}-${moduleSectionId}`] = -1;
                        }
                      } else {
                        modulesCache[`${moduleId}-${moduleSectionId}`] = -1;
                      }
                    });
                } else if (modulesCache[`${moduleId}-${moduleSectionId}`] > 0) {
                  console.log('update eventId: ', event.eventId, 'sectionId: ', section.id);
                  await copyImage(clientId, moduleId, moduleSectionId, event.eventId, section.id);
                }
              }
            });
        }
      }).then(() => console.log('End process.'));
  }

  recalcOrderIndex(eventId: string) {
    if (!eventId) {
      return;
    }
    console.log('Recalculation start');
    this.dataService.recalcOrderIndex(eventId)
      .then(() => {
        console.log('Recalculation finish');
      });
  }

  async enableEventsMyKnowledge() {
    if (!await this.commonService.confirmation('Run: Enable events MyKnowledge')) {
      return;
    }
    const clientId = this.loginService.client_id$.getValue();
    console.log('clientId: ', clientId);
    return firstValueFrom(this.afs.collection('client_data').doc(clientId)
      .collection('events', ref => ref.where('deleted', '==', 0).where('slotMode', '==', true)).get())
      .then(async snapshot => {
        const events = snapshot.docs
          .map(doc => new Event({ eventId: doc.id, ...(doc.data() as any) }))
          .filter(ev => ev.slotMode && ev.classCode);
        const eventsLength = events.length;
        let counter = 0;
        for (const event of events) {
          const settingsDoc = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
            .collection('instant_settings')
            .doc(event.eventId).get());
          await settingsDoc.ref.set({ myKnowledge: true }, { merge: true });
          console.log('process ', ++counter, ' of ', eventsLength);
        }
      }).then(() => console.log('End process.'));
  }

  async updateEventTimelineEducationField() {
    const updateClient = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events').get())
        .then(async (eventsSnapshot) => {
          const filtered = eventsSnapshot.docs;
          const size = filtered.length;
          let counter = 0;
          for (const eventDoc of filtered) {
            await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('conference').doc(eventDoc.id)
              .collection('timeline').get())
              .then((tlSnapshot) => {
                let sectionCounter = 0;
                for (const sectionDoc of tlSnapshot.docs) {
                  const section = sectionDoc.data() as SectionContent;
                  if (section.education === null || section.education === undefined) {
                    cache.push({ ref: sectionDoc.ref, obj: { education: false } });
                    sectionCounter++;
                  }
                }
                if (sectionCounter > 0) {
                  console.log(`Update. Event ${++counter} of ${size}. contain ${sectionCounter} changes`, eventDoc.id);
                } else {
                  console.log(`Skip. Event ${++counter} of ${size}.`, eventDoc.id);
                }
              });
          }
        });
    };

    const cache: IWriteCache[] = [];
    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const clientId of clients) {
      console.log(`Client ${clientId} started`);
      await updateClient(clientId);
      console.log(`Client ${clientId} finished`);
    }

    await this.commitCache(cache);

    console.log('All tasks finished');
    this.progress.next('All tasks finished');
  }

  async uglifyAppUsers() {
    if (this.commonService.getEnv().production) {
      this.commonService.showPopupError('Production environment detected');
      return;
    }
    if (!await this.commonService.confirmation('Run: Uglify AppUsers')) {
      return;
    }

    const cache: IWriteCache[] = [];

    let counter = 0;
    const usedStrings = [];

    const firstRef = this.afs.collection('app_users', q => q.orderBy('email').limit(1000));

    await firstValueFrom(firstRef.get())
      .then(async (firstSnapshot) => {
        const generateRandomString = (length: number) => {
          let randomString = this.commonService.utils.generateRandomString(length);
          while (usedStrings.includes(randomString)) {
            randomString = this.commonService.utils.generateRandomString(length);
          }
          usedStrings.push(randomString);
          return randomString;
        };
        const processSnapshot = (snapshot) => {
          for (const doc of snapshot.docs) {
            const email = doc.get('email') as string;
            if (email?.endsWith('@roche.com')) {
              const randomString1 = generateRandomString(6);
              const randomString2 = generateRandomString(10);
              const obj = {
                email: randomString1 + '.' + randomString2 + '@roche.com',
                fullName: randomString1 + ' ' + randomString2,
                userName: randomString1 + ' ' + randomString2
              };
              counter++;
              console.log(`Update(${counter}) email=${email}, obj=${JSON.stringify(obj)}`);
              cache.push({ ref: doc.ref, obj: obj });
            }
          }
        };

        processSnapshot(firstSnapshot);

        let lastDoc = firstSnapshot.docs[firstSnapshot.docs.length - 1];

        let hasNext = firstSnapshot.docs.length >= 1000;
        while (hasNext) {
          const queryFn = q => q.orderBy('email').startAfter(lastDoc).limit(1000);
          const nextRef = this.afs.collection('app_users', queryFn);
          const nextSnapshot = await firstValueFrom(nextRef.get());
          if (!nextSnapshot.empty) {
            processSnapshot(nextSnapshot);
            lastDoc = nextSnapshot.docs[nextSnapshot.docs.length - 1];
          } else {
            hasNext = false;
          }
        }
      });

    await this.commitCacheSafe(cache);
    console.log('All tasks finished');
  }

  async setVersionForTimelineSectionsLinkedWithModules() {
    const updateClientSectionTimelineVersion = async (clientId) => {
      const modulesVersionCache: { [moduleId: string]: any } = {};
      const ver = {};
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events', ref => ref.where('deleted', '==', 0)).get())
        .then(async (eventsSnapshot) => {
          const eventDocs = eventsSnapshot.docs;
          const size = eventDocs.length;
          let counter = 0;
          for (const eventDoc of eventDocs) {
            await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('conference').doc(eventDoc.id)
              .collection('timeline', ref => ref.where('entityLink.entityType', '==', 'module')).get())
              .then(async (tlSnapshot) => {
                console.log(`process ${++counter} of ${size}`);
                for (const sectionDoc of tlSnapshot.docs) {
                  const sectionId = sectionDoc.id;
                  const section = sectionDoc.data() as SectionContent;
                  if (!section.releaseVersion?.version) {
                    const moduleSnap: any = !modulesVersionCache[section.entityLink.entityId] ?
                      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
                        .collection('modules').doc(section.entityLink.entityId).get()) : modulesVersionCache[section.entityLink.entityId];
                    if (!modulesVersionCache[section.entityLink.entityId]) {
                      modulesVersionCache[section.entityLink.entityId] = {
                        id: moduleSnap.id,
                        exists: moduleSnap.exists,
                        data: moduleSnap.data()
                      };
                    }
                    const moduleDoc = modulesVersionCache[section.entityLink.entityId];
                    if (moduleDoc.exists) {
                      const module = moduleDoc.data as Event;
                      if (module.releaseVersion?.version !== '2023-12-22' && module.releaseVersion?.version !== '2023-12-23') {
                        ver[module.releaseVersion?.version] = merge([module.releaseVersion?.version], { id: moduleDoc.id });
                      } else {
                        ver[module.releaseVersion?.version] = true;
                      }
                      if ((module.releaseVersion?.version === '2023-12-22' || module.releaseVersion?.version === '2023-12-23') &&
                        isEmpty(section.releaseVersion)) {
                        const version = module.releaseVersion.version;
                        cache.push({ ref: sectionDoc.ref, obj: { releaseVersion: { version: version } } });
                      }
                    }
                  }
                }
              });
          }
          console.log(ver);
        });
    };

    const cache: IWriteCache[] = [];

    if (!await this.commonService.confirmation('Run: Set version for timeline sections linked with modules')) {
      return;
    }
    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id).filter(id => id === this.currentClientId);
      });
    console.log('Clients', clients);
    for (const clientId of clients) {
      console.log(`Client ${clientId} started`);
      await updateClientSectionTimelineVersion(clientId);
      console.log(`Client ${clientId} finished`);
    }
    await this.commitCache(cache);
    console.log('End process.');
  }

  async linkModulesSectionsAndContentsWithTimelineSectionsAndContents() {

    const equal = (s1: string, s2: string) => (s1 ?? '').trim() === (s2 ?? '').trim();

    const cacheModuleData = async (clientId: string, moduleId: string) => {
      const msCache = { sections: [], contents: [] };
      return await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('modules').doc(moduleId)
        .collection('timeline').get()).then(async msSnap => {
          msCache.sections = msSnap.docs.filter(d => !!d.data().parentId).map(d => new Object({
            id: d.id, title: d.data().title,
            subtitle: d.data().subtitle, orderIndex: d.data().orderIndex
          }))
            .sort(this.utils.comparator(Constants.ORDERINDEX));
          for (const section of msCache.sections) {
            await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('modules').doc(moduleId)
              .collection('timeline').doc(section.id).collection('contents').get()).then(mscSnap => {
                for (const mscDoc of mscSnap.docs) {
                  msCache.contents.push({ id: mscDoc.id, parentId: mscDoc.data().parentId,
                    title: mscDoc.data().title, releaseVersion: mscDoc.data().releaseVersion });
                }
              });
          }
          return msCache;
        });
    };

    const timelineSectionContents = async (clientId: string, eventId: string, sectionId: string, linked: (value: number) => void) => {
      const contents: { id: string, parentId: string, title: string, releaseVersion: string, cdbRef: any }[] = [];
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('conference').doc(eventId)
        .collection('timeline').doc(sectionId).collection('contents').get()).then(snap => {
          for (const doc of snap.docs.filter(d => isEmpty(d.data().entityLink) ||
            (!isEmpty(d.data().entityLink) && d.data().entityLink['script']))) {
            contents.push({ id: doc.id, parentId: doc.data().parentId, title: doc.data().title,
              releaseVersion: doc.data().releaseVersion, cdbRef: doc.ref });
          }
          linked(snap.docs.filter(d => !isEmpty(d.data().entityLink) && !d.data().entityLink['script']).length);
        });
      return contents;
    };

    const linkModulesWithTimeline = async (clientId) => {
      const modulesCache: {
        [moduleId: string]: {
          sections: { id: string, title: string, subtitle: string, orderIndex: number }[],
          contents: { id: string, parentId: string, title: string , releaseVersion: string}[]
        }
      } = {};
      const clientStatistics = {};
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events', ref => ref.where('deleted', '==', 0)).get())
        .then(async (eventsSnapshot) => {
          const eventDocs = eventsSnapshot.docs; // .filter(d => d.id === '....');
          const size = eventDocs.length;
          let counter = 0;
          for (const eventDoc of eventDocs) {
            await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('conference').doc(eventDoc.id)
              .collection('timeline').get())
              .then(async (timelineSnapshot) => {
                console.log(`process ${++counter} of ${size}`);
                const hasModules = timelineSnapshot.docs.filter(d => d.data()?.entityLink?.entityType === 'module').length > 0;
                const statistic = {
                  f1_sectionsAsModulesCount: 0,
                  f2_timelineSectionsCount: 0,
                  f3_linkedSectionsCount: 0,
                  f4_notLinkedSectionsCount: 0,
                  f5_contentsCount: 0,
                  f6_linkedContentsCount: 0,
                  f7_notLinkedContentsCount: 0
                };
                if (hasModules) {
                  const timelineSections = timelineSnapshot.docs.map(d => new SectionContent({ id: d.id, ...d.data() }));
                  const sectionsModules = timelineSections.filter(s => s.entityLink?.entityType === 'module');
                  statistic.f1_sectionsAsModulesCount = sectionsModules.length;
                  for (const sectionModule of sectionsModules) {
                    const moduleId = sectionModule.entityLink.entityId;
                    if (!modulesCache[moduleId]) {
                      modulesCache[moduleId] = await cacheModuleData(clientId, moduleId);
                    }
                    if (isEmpty(modulesCache[moduleId].sections)) {
                      console.log(`moduleId=timelineId: [${moduleId}] = [${sectionModule.id}] no sections in the module.`);
                      continue;
                    }
                    const timelineChildrenSections = this.utils.getSectionChildrenTree(sectionModule, timelineSections)
                      .filter(s => s.id !== sectionModule.id)
                      .filter(s => !s.entityLink || (s.entityLink && s.entityLink['script']));
                    const linkedTimelineChildrenSections = this.utils.getSectionChildrenTree(sectionModule, timelineSections)
                      .filter(s => s.id !== sectionModule.id)
                      .filter(s => s.entityLink && !s.entityLink['script']);
                    const cachedSections = modulesCache[moduleId].sections;
                    const cachedContents = modulesCache[moduleId].contents;
                    const usedCachedSectionsIds = {};
                    const notLinkedSections = [];
                    let linkedSectionCount = linkedTimelineChildrenSections.length;
                    statistic.f2_timelineSectionsCount = statistic.f2_timelineSectionsCount + linkedSectionCount;
                    statistic.f3_linkedSectionsCount = statistic.f3_linkedSectionsCount + linkedSectionCount;
                    for (const timelineSection of timelineChildrenSections) {
                      // try link section
                      const moduleSection = cachedSections.filter(s => !usedCachedSectionsIds[s.id])
                        .find(s => equal(s.title, timelineSection.title) && equal(s.subtitle, timelineSection.subtitle));
                      statistic.f2_timelineSectionsCount++;
                      if (!moduleSection) {
                        notLinkedSections.push({ id: timelineSection.id, title: timelineSection.title });
                        statistic.f4_notLinkedSectionsCount++;
                        let lnk = 0;
                        const notLinkedContents = await timelineSectionContents(clientId, eventDoc.id, timelineSection.id, (v) => lnk = v);
                        statistic.f5_contentsCount = statistic.f5_contentsCount + lnk + notLinkedContents.length;
                        statistic.f6_linkedContentsCount = statistic.f6_linkedContentsCount + lnk;
                        statistic.f7_notLinkedContentsCount = statistic.f7_notLinkedContentsCount + notLinkedContents.length;
                        continue;
                      }
                      linkedSectionCount++;
                      usedCachedSectionsIds[moduleSection.id] = true;
                      const sEntityLink = {
                        entityType: ENTITY_LINK_TYPE.SECTION,
                        entityId: moduleId,
                        sectionId: moduleSection.id,
                        script: true
                      };
                      statistic.f3_linkedSectionsCount++;
                      const sdbRef = timelineSnapshot.docs.find(d => d.id === timelineSection.id).ref;
                      cache.push({ ref: sdbRef, obj: { entityLink: sEntityLink } });
                      // try link section contents
                      const cachedSectionContents = cachedContents.filter(cn => cn.parentId === moduleSection.id);
                      if (isEmpty(cachedSectionContents)) {
                        continue;
                      }
                      let linked = 0;
                      const timelineContents = await timelineSectionContents(clientId, eventDoc.id, timelineSection.id, (v) => linked = v);
                      if (isEmpty(timelineContents) && !linked) {
                        continue;
                      }
                      let linkedContentCount = linked;
                      statistic.f5_contentsCount = statistic.f5_contentsCount + linkedContentCount;
                      statistic.f6_linkedContentsCount = statistic.f6_linkedContentsCount + linkedContentCount;
                      for (const timelineContent of timelineContents) {
                        const moduleContent = cachedSectionContents.find(cn => equal(cn.title, timelineContent.title));
                        statistic.f5_contentsCount++;
                        if (!moduleContent) {
                          statistic.f7_notLinkedContentsCount++;
                          continue;
                        }
                        linkedContentCount++;
                        const cEntityLink = {
                          entityType: ENTITY_LINK_TYPE.CONTENT,
                          entityId: moduleId,
                          sectionId: moduleSection.id,
                          contentId: moduleContent.id,
                          script: true
                        };
                        statistic.f6_linkedContentsCount++;
                        if (!moduleContent.releaseVersion) {
                          console.log(`%c!!!!!!!!! releaseVersion is null ${cEntityLink}`, 'color: red');
                          continue;
                        }
                        cache.push({ ref: timelineContent.cdbRef, obj: {
                            entityLink: cEntityLink,
                            releaseVersion: timelineContent.releaseVersion ?? moduleContent.releaseVersion}
                        });
                      }
                      console.log(`moduleId=timelineId: [${moduleId}] = [${sectionModule.id}] child sectionId ${timelineSection.id} ` +
                        `linked contents ${linkedContentCount} of ${timelineContents.length + linked}. ` +
                        `Module contents count ${cachedSectionContents.length}.`);
                    }
                    for (const timelineSection of linkedTimelineChildrenSections) {
                      let linked = 0;
                      const timelineContents = await timelineSectionContents(clientId, eventDoc.id, timelineSection.id, (v) => linked = v);
                      const cachedSectionContents = cachedContents.filter(cn => cn.parentId === timelineSection.entityLink.sectionId);
                      statistic.f5_contentsCount = statistic.f5_contentsCount + linked + timelineContents.length;
                      statistic.f7_notLinkedContentsCount = statistic.f7_notLinkedContentsCount + timelineContents.length;
                      statistic.f6_linkedContentsCount = statistic.f6_linkedContentsCount + + linked;
                      console.log(`moduleId=timelineId: [${moduleId}] = [${sectionModule.id}] child sectionId ${timelineSection.id} ` +
                        `linked contents ${linked} of ${timelineContents.length + linked}. ` +
                        `Module contents count ${cachedSectionContents.length}.`);
                    }
                    console.log(`moduleId=timelineId: [${moduleId}] = [${sectionModule.id}] linked sections ` +
                      `${linkedSectionCount} of ${timelineChildrenSections.length + linkedTimelineChildrenSections.length}. ` +
                      `Module section count ${cachedSections.length}. ` +
                      `Not linked:`, notLinkedSections);
                  }
                  console.log(`Event ${eventDoc.id} (${eventDoc.data().shortLink}) statistics:`, statistic);
                  clientStatistics[eventDoc.id] = statistic;
                }
              });
          }
          console.log('Client statistics by events:');
          console.log(clientStatistics);
          console.log('Client statistics by events (JSON):');
          console.log(JSON.stringify(clientStatistics));
        });
    };

    const cache: IWriteCache[] = [];

    if (!await this.commonService.confirmation('Run: Link Modules sections and contents with Timeline sections and contents')) {
      return;
    }
    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id); // .filter(id => id === this.currentClientId);
      });
    console.log('Clients', clients);
    this.status3 = [];
    for (const clientId of clients) {
      console.log(`Client ${clientId} started`);
      this.status3.push('Start process clientId ' + clientId + ' ' +
        this.utils.formatDate(new Date().getTime(), DATE_FORMAT.DD_MM_YYYY_HH_mm));
      await linkModulesWithTimeline(clientId);
      console.log(`Client ${clientId} finished`);
      this.status3.push('End process clientId ' + clientId + ' ' +
        this.utils.formatDate(new Date().getTime(), DATE_FORMAT.DD_MM_YYYY_HH_mm));
    }
    await this.commitCache(cache);
    console.log('End process.');
  }

  async fixSlotDates() {
    const fixClient = async (clientId: string) => {
      return firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events', ref => ref.where('deleted', '==', 0)).get())
        .then(async (eventsSnapshot) => {
          for (const eventDoc of eventsSnapshot.docs) {
            const sections = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('conference').doc(eventDoc.id)
              .collection('timeline').get())
              .then((tlSnapshot) => {
                return tlSnapshot.docs.map(it => new Object({id: it.id, ...it.data()}) as SectionContent)
                  .filter(it => !it.education || !it.parentId)
                  .filter(it => !it.fixedSectionType);
              });
            if (sections.length <= 1) {
              continue;
            }
            const rootSection = sections.find(it => !it.parentId);
            const firstLevel = sections.filter(it => it.parentId === rootSection.id).sort(this.utils.comparator(Constants.ORDERINDEX));
            const wrongList = [];
            for (let i = 0; i < firstLevel.length; i++) {
              const section = firstLevel[i];
              if ((i + 1) >= firstLevel.length || !section.freeSlotType) {
                continue;
              }
              const nextSection = firstLevel[i + 1];
              if (section.plannedTime > nextSection.plannedTime && nextSection.freeSlotType) {
                wrongList.push(section);
              }
            }
            const lastSection = firstLevel[firstLevel.length - 1];
            if (wrongList.length) {
              console.log(`%cEvent id=${eventDoc.id}, link=${eventDoc.get('shortLink')}, idx=${lastSection.orderIndex}, date=${new Date(lastSection.plannedTime)}`, 'color: blue');
            }
            let orderIndex = lastSection.orderIndex + 10000000;
            const sorted = wrongList.sort(this.utils.comparator('plannedTime'));
            for (const section of sorted as SectionContent[]) {
              if (lastSection.plannedTime < section.plannedTime) {
                const hasChild = sections.find(it => it.parentId === section.id);
                if (hasChild) {
                  console.log(`%cSection(CHILD) id=${section.id}, idx=${section.orderIndex}, date=${new Date(section.plannedTime)}, title=${section.title}`, 'color: red');
                  // continue;
                }
                console.log(`Section id=${section.id}, idx=${section.orderIndex}, new=${orderIndex}, date=${new Date(section.plannedTime)}, title=${section.title}`);
                const ref = this.afs.collection('client_data').doc(clientId)
                  .collection('conference').doc(eventDoc.id)
                  .collection('timeline').doc(section.id).ref;
                cache.push({ref: ref, obj: {orderIndex: orderIndex}});
                orderIndex += 10000000;
              }
            }
          }
        });
    };

    const cache: IWriteCache[] = [];
    // const clients = await firstValueFrom(this.afs
    //   .collection('client_data')
    //   .get())
    //   .then((snapshot) => {
    //     return snapshot.docs.map(it => it.id);
    //   });
    const clients = [this.loginService.client_id$.getValue()];
    for (const clientId of clients) {
      await fixClient(clientId);
    }

    await this.commitCache(cache);
    console.log('End process.');
  }

  async checkModulesFieldEducation(check: boolean) {
    const checkModulesSections = async (clientId) => {
      const log = {};
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('modules', ref => ref.where('deleted', '==', 0)).get())
        .then(async (eventsSnapshot) => {
          const eventDocs = eventsSnapshot.docs; // .filter(d => d.id === '.....');
          const size = eventDocs.length;
          let counter = 0;
          for (const eventDoc of eventDocs) {
            await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('modules').doc(eventDoc.id)
              .collection('timeline').get())
              .then(async (snapshot) => {
                console.log(`process ${++counter} of ${size}`);
                const allSection = snapshot.docs.map(d => new SectionContent({id: d.id, ...d.data()}));
                const rootId = allSection.find(s => s.isRoot).id;
                const sections = allSection.filter(s => !s.isRoot && !s.education);
                if (sections.length > 0) {
                  console.log('Module id ', eventDoc.id);
                  log[eventDoc.id] = {};
                  for (const s of sections) {
                    const contents = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
                      .collection('modules').doc(eventDoc.id)
                      .collection('timeline').doc(s.id).collection('contents').get())
                      .then(snap => snap.docs);
                    log[eventDoc.id][s.id] = {
                      id: s.id,
                      title: s.title,
                      createDate: this.utils.formatDate(s.createTime, DATE_FORMAT.DD_MM_YY_HH_mm_ss),
                      hasChildrenSections: allSection.filter(ch => ch.parentId === s.id).length,
                      hasContents: contents.length,
                      orderIndexDuplicate: allSection.filter(ch => ch.id !== s.id && ch.orderIndex === s.orderIndex).length,
                      parentId: s.parentId,
                      parentIsRoot: rootId === s.parentId
                    };
                    if (!check) {
                      for (const cnRef of contents) {
                        await cnRef.ref.delete()
                          .then(() => console.log('delete content id: ', cnRef.id));
                      }
                      const sectionRef = snapshot.docs.find(d => d.id === s.id);
                      if (sectionRef) {
                        await sectionRef.ref.delete()
                          .then(() => console.log('delete section id: ', sectionRef.id));
                      }
                    }
                  }
                }
              });
          }
          console.log('Modules with section field education ', Object.keys(log).length);
          console.log(log);
        });
    };

    if (!await this.commonService.confirmation(check ?
      'Run: Check modules section with field education = false' : 'Run: Remove modules section with field education = false')) {
      return;
    }
    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id); // .filter(id => id === this.currentClientId);
      });
    console.log('Clients', clients);
    for (const clientId of clients) {
      console.log(`Client ${clientId} started`);
      await checkModulesSections(clientId);
      console.log(`Client ${clientId} finished`);
    }
    console.log('End process.');
  }

  async fixedModulesTimelineContentShort() {
    const fixedTimelineShort = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('modules', ref => ref.where('deleted', '==', 0)).get())
        .then(async (eventsSnapshot) => {
          const eventDocs = eventsSnapshot.docs; // .filter(d => d.id === '.....');
          const size = eventDocs.length;
          let counter = 0;
          for (const eventDoc of eventDocs) {
            await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('modules').doc(eventDoc.id)
              .collection('timeline').get())
              .then(async (snapshot) => {
                console.log(`process ${++counter} of ${size}`);
                const sections = snapshot.docs.map(d => new SectionContent({id: d.id, ...d.data()})).filter(s => !s.isRoot);
                if (sections.length > 0) {
                  for (const s of sections) {
                    const contents: {id, type}[] = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
                      .collection('modules').doc(eventDoc.id)
                      .collection('timeline').doc(s.id).collection('contents').get())
                      .then(snap => snap.docs.map(d => new Object({id: d.id, type: d.data().type}) as {id, type}));
                    if (!isEmpty(contents)) {
                      const shortObj = contents.reduce((acc, item) => {
                        acc[item.id] = item.type;
                        return acc;
                      }, {});
                      const shortRef = this.afs.collection('client_data').doc(clientId)
                        .collection('modules').doc(eventDoc.id)
                        .collection('timeline_content_short').doc(s.id).ref;
                      cache.push({ref: shortRef, obj: shortObj});
                    }
                  }
                }
              });
          }
        });
    };

    if (!await this.commonService.confirmation('Run: Fixed modules timeline_content_short collection')) {
      return;
    }
    const cache: IWriteCache[] = [];
    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id); // .filter(id => id === this.currentClientId);
      });
    console.log('Clients', clients);
    for (const clientId of clients) {
      console.log(`Client ${clientId} started`);
      await fixedTimelineShort(clientId);
      console.log(`Client ${clientId} finished`);
    }
    await this.commitCache(cache);
    console.log('End process.');
  }

  async fixContentsImageLinkedWithModules(manualClientId, manualEventId) {
    const copyStorageObject = (pathFrom: string, pathTo: string) => {
      const callable = this.aff.httpsCallable('copyStorageObject');
      const obj = {
        pathFrom: `${pathFrom}`,
        pathTo: `${pathTo}`
      };
      return firstValueFrom(callable(obj));
    };

    const getStorageURL = (path: string) =>  {
      const reference = this.angularFireStorage.storage.ref(`${path}`);
      return reference.getDownloadURL();
    };

    const moduleTimeLineShort = async (clientId, moduleId, moduleCache) => {
      console.log(`get timeline for moduleId ${moduleId}`);
      moduleCache[moduleId] = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('modules').doc(moduleId)
        .collection('timeline_content_short').get())
        .then(snap => !snap.empty ? snap.docs.reduce((acc, d) => {
            acc = merge(acc, Object.keys(d.data())
              .reduce((a, cId) => {
                a[cId] = d.id;
                return a;
              }, {}));
            return acc;
          }, {}) : null);
      return moduleCache[moduleId];
    };

    const getModuleContentDocument = (clientId, moduleId, sectionId, contentId) => {
      return firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('modules')
        .doc(moduleId)
        .collection('timeline')
        .doc(sectionId)
        .collection('contents')
        .doc(contentId).get())
        .then(snap => snap.exists ? new Object({id: snap.id, ...snap.data()}) : null);
    };


    const runFixContentsImageCreateStatistics = async (clientId) => {
      const moduleCache = {};
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events', ref => ref.where('deleted', '==', 0)).get())
        .then(async (eventsSnapshot) => {
          const eventDocs = eventsSnapshot.docs.filter(d => manualEventId ? d.id === manualEventId : true);
          const size = eventDocs.length;
          let counter = 0;
          for (const eventDoc of eventDocs) {
            await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('conference').doc(eventDoc.id)
              .collection('timeline').get())
              .then(async (snapshot) => {
                console.log(`process ${++counter} of ${size}`);
                const allSection = snapshot.docs.map(d => new SectionContent({id: d.id, ...d.data()}));
                const sections = allSection.filter(s => !s.isRoot);
                if (sections.length > 0) {
                  console.log(`event id ${eventDoc.id} sections count ${sections.length}`);
                  let si = 0;
                  for (const s of sections) {
                    console.log(`process eventId ${eventDoc.id} sectionId ${s.id} count ${++si} of ${sections.length}`);
                    const contents = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
                      .collection('conference').doc(eventDoc.id)
                      .collection('timeline').doc(s.id)
                      .collection('contents', ref => ref.where('type', '==', Constants.CONTENT_TYPE_CONTENT_CONTAINER))
                      .get())
                      .then(snap => (snap.docs || []).filter(d => !isEmpty(d.data()['entityLink'])));
                    for (const ccDoc of contents) {
                      let ccNeedUpdate = false;
                      const cc = new ContentContainer({id: ccDoc.id, ...ccDoc.data()});
                      const images = cc.items.filter(it => it.type === CONTAINER_ITEM_TYPE.IMAGE)
                        .filter(gl => !!gl.data.pictures?.filter(p => !p.src).length);
                      const tasks = cc.items.filter(it => it.type === CONTAINER_ITEM_TYPE.TASK)
                        .filter(gl => !!gl.data.files?.filter(p => !p.src).length);
                      const pdfs = cc.items.filter(it => it.type === CONTAINER_ITEM_TYPE.PDF)
                        .filter(gl => !gl.data.src);
                      const quizzes = cc.items.filter(it => it.type === CONTAINER_ITEM_TYPE.QUIZ)
                        .map(qd => new Object({id: qd.id, quiz: new Quiz(qd.data)}) as {id: string, quiz: Quiz})
                        .filter(q => !!(Object.values(q.quiz.questions) as EventQuestion[])
                          .filter(ques => ques.storypoint === Constants.QTYPE_MATCHING_MAP && !ques.files[0]).length);
                      if (!isEmpty(images) || !isEmpty(quizzes) || !isEmpty(pdfs) || !isEmpty(tasks)) {
                        const moduleTimeLine = moduleCache[cc.entityLink.entityId] ??
                          await moduleTimeLineShort(clientId, cc.entityLink.entityId, moduleCache);
                        if (isEmpty(moduleTimeLine)) {
                          continue;
                        }
                        const sectionId = moduleTimeLine[cc.entityLink.contentId];
                        if (!sectionId) {
                          console.log('section for content not found', sectionId);
                          log[clientId][cc.entityLink.entityId] = union(log[clientId][cc.entityLink.entityId] ?? [],
                            [{
                              a_moduleId: cc.entityLink.entityId, b_sectionId: sectionId,
                              c_contentId: cc.entityLink.contentId, warning: 'section for content not found'
                            }]);
                          continue;
                        }
                        const mcObject = await getModuleContentDocument(clientId,
                          cc.entityLink.entityId, sectionId, cc.entityLink.contentId);
                        if (!mcObject) {
                          console.log('content not found', cc.entityLink.contentId);
                          log[clientId][cc.entityLink.entityId] = union(log[clientId][cc.entityLink.entityId] ?? [],
                            [{
                              a_moduleId: cc.entityLink.entityId, b_sectionId: sectionId,
                              c_contentId: cc.entityLink.contentId, warning: 'content not found'
                            }]);
                          continue;
                        }
                        if (mcObject) {
                          const mContent = new ContentContainer(mcObject);
                          for (const imgCn of images) {
                            const mdItm = mContent.items.find(it => it.id === imgCn.id);
                            if (mdItm) {
                              const pictures = imgCn.data.pictures.filter(p => !p.src);
                              const mdPictures = mdItm.data?.pictures;
                              if (!isEmpty(mdPictures)) {
                                for (const pict of pictures) {
                                  const mdpict = mdPictures.find(p => p.id === pict.id);
                                  if (mdpict && mdpict.src) {
                                    const fileName = this.utils.extractFileNameFromUrl(mdpict.src);
                                    const pathFrom = `client_data/${clientId}/modules/${cc.entityLink.entityId}/${cc.entityLink.contentId}/${imgCn.id}/${fileName}`;
                                    const pathTo = `client_data/${clientId}/conference/${eventDoc.id}/${cc.id}/${imgCn.id}/${fileName}`;
                                    await copyStorageObject(pathFrom, pathTo)
                                      .then(() => getStorageURL(pathTo)
                                        .then(url => {
                                          pict.src = url;
                                          ccNeedUpdate = true;
                                          console.log('copy image fileName ' + fileName);
                                        })).catch(() => {
                                        console.log('store object not found');
                                        log[clientId][cc.entityLink.entityId] = union(log[clientId][cc.entityLink.entityId] ?? [],
                                          [{
                                            a_moduleId: cc.entityLink.entityId, b_sectionId: sectionId,
                                            c_contentId: cc.entityLink.contentId, d_containerId: mdItm.id, pictureId: mdpict.id,
                                            e_type: CONTAINER_ITEM_TYPE.IMAGE,
                                            warning: 'store object not found'
                                          }]);
                                      });
                                  } else if (mdpict && !mdpict.src) {
                                    log[clientId][cc.entityLink.entityId] = union(log[clientId][cc.entityLink.entityId] ?? [],
                                      [{
                                        a_moduleId: cc.entityLink.entityId, b_sectionId: sectionId,
                                        c_contentId: cc.entityLink.contentId, d_containerId: mdItm.id, pictureId: mdpict.id,
                                        e_type: CONTAINER_ITEM_TYPE.IMAGE,
                                        warning: 'picture without src'
                                      }]);
                                    console.log('picture without src');
                                  }
                                }
                              }
                            }
                          }
                          for (const taskCn of tasks) {
                            const mdItm = mContent.items.find(it => it.id === taskCn.id);
                            if (mdItm) {
                              const files = taskCn.data.files.filter(p => !p.src);
                              const mdFiles = mdItm.data?.files;
                              if (!isEmpty(mdFiles)) {
                                for (const file of files) {
                                  const mdfile = mdFiles.find(p => p.id === file.id);
                                  if (mdfile && mdfile.src) {
                                    const fileName = this.utils.extractFileNameFromUrl(mdfile.src);
                                    const pathFrom = `client_data/${clientId}/modules/${cc.entityLink.entityId}/${cc.entityLink.contentId}/${taskCn.id}/task/${fileName}`;
                                    const pathTo = `client_data/${clientId}/conference/${eventDoc.id}/${cc.id}/${taskCn.id}/task/${fileName}`;
                                    await copyStorageObject(pathFrom, pathTo)
                                      .then(() => getStorageURL(pathTo)
                                        .then(url => {
                                          file.src = url;
                                          ccNeedUpdate = true;
                                          console.log('copy task fileName ' + fileName);
                                        })).catch(() => {
                                        console.log('store object not found');
                                        log[clientId][cc.entityLink.entityId] = union(log[clientId][cc.entityLink.entityId] ?? [],
                                          [{
                                            a_moduleId: cc.entityLink.entityId, b_sectionId: sectionId,
                                            c_contentId: cc.entityLink.contentId, d_containerId: mdItm.id, fileId: mdfile.id,
                                            e_type: CONTAINER_ITEM_TYPE.TASK,
                                            warning: 'store object not found'
                                          }]);
                                      });
                                  } else if (mdfile && !mdfile.src) {
                                    log[clientId][cc.entityLink.entityId] = union(log[clientId][cc.entityLink.entityId] ?? [],
                                      [{
                                        a_moduleId: cc.entityLink.entityId, b_sectionId: sectionId,
                                        c_contentId: cc.entityLink.contentId, d_containerId: mdItm.id, fileId: mdfile.id,
                                        e_type: CONTAINER_ITEM_TYPE.TASK,
                                        warning: 'task without src'
                                      }]);
                                    console.log('task without src');
                                  }
                                }
                              }
                            }
                          }
                          for (const pdfCn of pdfs) {
                            const mdItm = mContent.items.find(it => it.id === pdfCn.id);
                            if (mdItm) {
                              const mdPdf = mdItm.data as TPdfData;
                              if (mdPdf && mdPdf.src) {
                                const fileName = this.utils.extractFileNameFromUrl(mdPdf.src);
                                const pathFrom = `client_data/${clientId}/modules/${cc.entityLink.entityId}/${cc.entityLink.contentId}/${pdfCn.id}/${fileName}`;
                                const pathTo = `client_data/${clientId}/conference/${eventDoc.id}/${cc.id}/${pdfCn.id}/${fileName}`;
                                await copyStorageObject(pathFrom, pathTo)
                                  .then(() => getStorageURL(pathTo)
                                    .then(url => {
                                      pdfCn.data.src = url;
                                      ccNeedUpdate = true;
                                      console.log('copy pdf fileName ' + fileName);
                                    })).catch(() => {
                                    console.log('store object not found');
                                    log[clientId][cc.entityLink.entityId] = union(log[clientId][cc.entityLink.entityId] ?? [],
                                      [{
                                        a_moduleId: cc.entityLink.entityId, b_sectionId: sectionId,
                                        c_contentId: cc.entityLink.contentId, d_containerId: mdItm.id,
                                        e_type: CONTAINER_ITEM_TYPE.PDF,
                                        warning: 'store object not found'
                                      }]);
                                  });
                              } else if (mdPdf && !mdPdf.src) {
                                log[clientId][cc.entityLink.entityId] = union(log[clientId][cc.entityLink.entityId] ?? [],
                                  [{
                                    a_moduleId: cc.entityLink.entityId, b_sectionId: sectionId,
                                    c_contentId: cc.entityLink.contentId, d_containerId: mdItm.id,
                                    e_type: CONTAINER_ITEM_TYPE.PDF,
                                    warning: 'pdf without src'
                                  }]);
                                console.log('pdf without src');
                              }
                            }
                          }
                          for (const quizCn of quizzes) {
                            const mdItm = mContent.items.find(it => it.id === quizCn.id);
                            if (mdItm) {
                              const questions = Object.values(quizCn.quiz.questions)
                                .filter(q => q.storypoint === Constants.QTYPE_MATCHING_MAP && !q.files[0]);
                              const mdQuestions = new Quiz(mdItm.data).questions;
                              if (!isEmpty(mdQuestions)) {
                                for (const ques of questions) {
                                  const mdQuestion = mdQuestions[ques.id];
                                  if (mdQuestion && mdQuestion.files[0]) {
                                    const fileName = this.utils.extractFileNameFromUrl(mdQuestion.files[0]);
                                    const pathFrom = `client_data/${clientId}/modules/${cc.entityLink.entityId}/${cc.entityLink.contentId}/${quizCn.id}/${mdQuestion.id}/${fileName}`;
                                    const pathTo = `client_data/${clientId}/conference/${eventDoc.id}/${cc.id}/${quizCn.id}/${mdQuestion.id}/${fileName}`;
                                    await copyStorageObject(pathFrom, pathTo)
                                      .then(() => getStorageURL(pathTo)
                                        .then(url => {
                                          cc.items.find(it => it.id === quizCn.id).data.questions[ques.id].imageUrl = url;
                                          ccNeedUpdate = true;
                                          console.log('copy question image fileName ' + fileName);
                                        })).catch(() => {
                                        console.log('store object not found');
                                        log[clientId][cc.entityLink.entityId] = union(log[clientId][cc.entityLink.entityId] ?? [],
                                          [{
                                            a_moduleId: cc.entityLink.entityId, b_sectionId: sectionId,
                                            c_contentId: cc.entityLink.contentId, d_containerId: mdItm.id,
                                            e_type: CONTAINER_ITEM_TYPE.QUIZ,
                                            warning: 'store object not found'
                                          }]);
                                      });
                                  } else if (mdQuestion && !mdQuestion.files[0]) {
                                    log[clientId][cc.entityLink.entityId] = union(log[clientId][cc.entityLink.entityId] ?? [],
                                      [{
                                        a_moduleId: cc.entityLink.entityId, b_sectionId: sectionId,
                                        c_contentId: cc.entityLink.contentId, d_containerId: mdItm.id,
                                        e_type: CONTAINER_ITEM_TYPE.QUIZ,
                                        warning: 'question type matching map without image'
                                      }]);
                                    console.log('question type matching map without imanage');
                                  }
                                }
                              }
                            }
                          }
                          if (ccNeedUpdate) {
                            cache.push({ref: ccDoc.ref, obj: cc.toObject()});
                          }
                        }
                      }
                    }
                  }
                }
              });
          }
        });
    };

    if (!await this.commonService.confirmation(
      'Run: Fixed event linked with modules contents image. Create statistics by contents with empty srs url in image content.')) {
      return;
    }
    const clientIds = Array.from(manualClientId.selectedOptions).map(({ value }) => value);
    if (isEmpty(clientIds)) {
      return;
    }
    const cache: IWriteCache[] = [];
    const log = {};
    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => new ClientConfig(Object.assign({id: it.id}, it.data())))
         .filter(cl => clientIds.includes(cl.id));
      });
    for (const client of clients) {
      const clientId = client.id;
      console.log(`Client ${clientId} started`);
      log[clientId] = {};
      await runFixContentsImageCreateStatistics(clientId);
      console.log(`Client ${clientId} finished`);
    }
    this.commitCache(cache)
      .then(() => {
        console.log('~~~~ log ~~~~');
        console.log(log);
        const clientCSV = {};
        for (const clientId of Object.keys(log)) {
          const clientData = log[clientId];
          const list: string[] = ['module;section;content;subcontent;type;message;link'];
          for (const moduleId of Object.keys(clientData)) {
            const rowData: {a_moduleId, b_sectionId, c_contentId, d_containerId, e_type, warning}[] = clientData[moduleId];
            for (const row of rowData) {
             const link = `module/${row.a_moduleId}?sid=${row.b_sectionId}&cid=${row.c_contentId}`;
             const r = `${row.a_moduleId};${row.b_sectionId};${row.c_contentId};${row.d_containerId};${row.e_type};${row.warning};${link}`;
             list.push(r);
            }
          }
          clientCSV[clientId] = list;
        }
        Object.keys(clientCSV)
          .forEach(clId => this.utils.downloadCsvReport(clientCSV[clId].join('\n'),
            `${clients.find(c => c.id === clId).name}.csv`, 'text/csv;encoding:utf-8'));
        console.log('End process.');
      });
  }

  async createStatisticsByModulesWithoutPicturesSrc() {

    const runCreateStatisticsByModulesWithoutPicturesSrc = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('modules', ref => ref.where('deleted', '==', 0)).get())
        .then(async (eventsSnapshot) => {
          const eventDocs = eventsSnapshot.docs; // .filter(d => d.id === '4Ihh0KS2qz2AXptArPPL');
          const size = eventDocs.length;
          let counter = 0;
          for (const eventDoc of eventDocs) {
            await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('modules').doc(eventDoc.id)
              .collection('timeline').get())
              .then(async (snapshot) => {
                console.log(`process ${++counter} of ${size}`);
                const allSection = snapshot.docs.map(d => new SectionContent({id: d.id, ...d.data()}));
                const sections = allSection.filter(s => !s.isRoot);
                if (sections.length > 0) {
                  console.log(`module id ${eventDoc.id} sections count ${sections.length}`);
                  let si = 0;
                  for (const s of sections) {
                    console.log(`process event id ${eventDoc.id} section ${++si} of ${sections.length}`);
                    const contents = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
                      .collection('modules').doc(eventDoc.id)
                      .collection('timeline').doc(s.id)
                      .collection('contents', ref => ref.where('type', '==', Constants.CONTENT_TYPE_CONTENT_CONTAINER))
                      .get())
                      .then(snap => (snap.docs || []));
                    for (const ccDoc of contents) {
                      const cc = new ContentContainer({id: ccDoc.id, ...ccDoc.data()});
                      const images = cc.items.filter(it => it.type === CONTAINER_ITEM_TYPE.IMAGE)
                        .filter(gl => !!gl.data.pictures?.filter(p => !p.src).length);
                      const files = cc.items.filter(it => it.type === CONTAINER_ITEM_TYPE.TASK)
                        .filter(gl => !!gl.data.files?.filter(p => !p.src).length);
                      const pdfs = cc.items.filter(it => it.type === CONTAINER_ITEM_TYPE.PDF)
                        .filter(gl => !gl.data.src);
                      const quizzes = cc.items.filter(it => it.type === CONTAINER_ITEM_TYPE.QUIZ)
                        .map(qd => new Object({id: qd.id, quiz: new Quiz(qd.data)}) as { id: string, quiz: Quiz })
                        .filter(q => !!(Object.values(q.quiz.questions) as EventQuestion[])
                          .filter(ques => ques.storypoint === Constants.QTYPE_MATCHING_MAP && !ques.files[0]).length);
                      if (!isEmpty(images)) {
                        log[clientId][eventDoc.id] = union(log[clientId][eventDoc.id] ?? [],
                          [{
                            a_moduleId: eventDoc.id, b_sectionId: cc.parentId,
                            c_contentId: cc.id, warning: 'picture without src'
                          }]);
                      }
                      if (!isEmpty(files)) {
                        log[clientId][eventDoc.id] = union(log[clientId][eventDoc.id] ?? [],
                          [{
                            a_moduleId: eventDoc.id, b_sectionId: cc.parentId,
                            c_contentId: cc.id, warning: 'files without src'
                          }]);
                      }
                      if (!isEmpty(quizzes)) {
                        log[clientId][eventDoc.id] = union(log[clientId][eventDoc.id] ?? [],
                          [{
                            a_moduleId: eventDoc.id, b_sectionId: cc.parentId,
                            c_contentId: cc.id, warning: 'question type matching map without imanage'
                          }]);
                      }
                      if (!isEmpty(pdfs)) {
                        log[clientId][eventDoc.id] = union(log[clientId][eventDoc.id] ?? [],
                          [{
                            a_moduleId: eventDoc.id, b_sectionId: cc.parentId,
                            c_contentId: cc.id, warning: 'pdf without src'
                          }]);
                      }
                    }
                  }
                }
              });
          }
        });
    };

    if (!await this.commonService.confirmation(
      'Run: Create statistics by modules without pictures src')) {
      return;
    }
    const log = {};
    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => new ClientConfig(Object.assign({id: it.id}, it.data())));
        // .filter(cl => cl.id === this.currentClientId);
      });
    for (const client of clients) {
      const clientId = client.id;
      console.log(`Client ${clientId} started`);
      log[clientId] = {};
      await runCreateStatisticsByModulesWithoutPicturesSrc(clientId);
      console.log(`Client ${clientId} finished`);
    }
    console.log('~~~~ log ~~~~');
    console.log(log);
    const clientCSV = {};
    for (const clientId of Object.keys(log)) {
      const clientData = log[clientId];
      const list: string[] = ['module;section;content;message;link'];
      for (const moduleId of Object.keys(clientData)) {
        const rowData: {a_moduleId, b_sectionId, c_contentId, warning}[] = clientData[moduleId];
        for (const row of rowData) {
          const link = `module/${row.a_moduleId}?sid=${row.b_sectionId}&cid=${row.c_contentId}`;
          list.push(`${row.a_moduleId};${row.b_sectionId};${row.c_contentId};${row.warning};${link}`);
        }
      }
      clientCSV[clientId] = list;
    }
    Object.keys(clientCSV)
      .forEach(clId => this.utils.downloadCsvReport(clientCSV[clId].join('\n'),
        `${clients.find(c => c.id === clId).name}.csv`, 'text/csv;encoding:utf-8'));
    console.log('End process.');
  }

  async createDailyWebhook() {
    const url = this.utils.getEnv().BACKEND_BASE + '/dailyApi/v3/api/token';
    const dailyToken = await firstValueFrom(this.http.get(url, {
      responseType: 'text'
    }));
    const projectId = this.utils.getEnv().firebase['projectId'];
    const result = await firstValueFrom(this.http.post('https://daily.co/api/v1/webhooks', {
      url: `https://europe-west6-${projectId}.cloudfunctions.net/onRecordingDownloadReady`,
      eventTypes: ['recording.ready-to-download']
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + dailyToken
      }),
      responseType: 'json'
    }));
    console.log('result', result);

  }

  async getDailyWebhooks() {
    const url = this.utils.getEnv().BACKEND_BASE + '/dailyApi/v3/api/token';
    const dailyToken = await firstValueFrom(this.http.get(url, {
      responseType: 'text'
    }));
    const result = await firstValueFrom(this.http.get('https://daily.co/api/v1/webhooks', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + dailyToken
      }),
      responseType: 'json'
    }));
    console.log('Webhooks', result);
  }

  async deleteDailyWebhook(uuid: string) {
    const url = this.utils.getEnv().BACKEND_BASE + '/dailyApi/v3/api/token';
    const dailyToken = await firstValueFrom(this.http.get(url, {
      responseType: 'text'
    }));
    const result = await firstValueFrom(this.http.delete(`https://daily.co/api/v1/webhooks/${uuid}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + dailyToken
      }),
      responseType: 'json'
    }));
    console.log('result', result);
  }

  async findSectionsWithDuplicatePlannedTime(manualClientId, manualEventId) {

    const hasContents = (clientId, eventId, sectionId) => {
      return firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('conference').doc(eventId)
        .collection('timeline').doc(sectionId)
        .collection('contents', ref => ref.limit(1))
        .get())
        .then(snap => !isEmpty(snap.docs));
    };

    const runFindSectionsWithDuplicatePlannedTime = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events', ref => ref.where('classCode', '>', '')).get())
        .then(async (eventsSnapshot) => {
          const eventDocs = eventsSnapshot.docs
            .filter(d => !d.data().deleted)
            .filter(d => d.data().endDate >= new Date('2024-02-14').getTime())
            .filter(d => manualEventId ? d.id === manualEventId : true);
          const size = eventDocs.length;
          let counter = 0;
          for (const eventDoc of eventDocs) {

            await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('conference').doc(eventDoc.id)
              .collection('timeline').get())
              .then(async (snapshot) => {
                console.log(`process ${++counter} of ${size}`);
                const sectionsDBRef: {[key: string]: DocumentReference} = {};
                const allSection = snapshot.docs.map(d => new SectionContent({id: d.id, ...d.data()}));
                snapshot.docs.forEach(d => sectionsDBRef[d.id] = d.ref);
                const sections = allSection.filter(s => !s.isRoot && !s.education)
                  .map(s => {
                    if (s.plannedTime) {
                      const d = new Date(s.plannedTime);
                      s.plannedTime = new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes()).getTime();
                    }
                    return s;
                  }).sort(this.utils.comparator('plannedTime'));
                if (sections.length > 0) {
                  console.log(`event id ${eventDoc.id} sections count ${sections.length}`);
                  const timeCache = {};
                  for (const s of sections) {
                    if (!s.plannedTime) {
                      continue;
                    }
                    if (!timeCache[s.plannedTime]) {
                      timeCache[s.plannedTime] = s.plannedTime;
                      const list = sections.filter(its => its.plannedTime === s.plannedTime && its.parentId === s.parentId)
                        .sort(this.utils.comparator('createTime'));
                      if (list.length > 1) {
                        const slog = [];
                        const titles = [];
                        for (let i = 0; i < list.length; i++) {
                          const its = list[i];
                          const hasChildrenContents = await hasContents(clientId, eventDoc.id, its.id);
                          const hasChildrenSections = sections.filter(it => it.parentId === its.id).length > 0;
                          titles.push(its.title);
                          titles.push(hasChildrenSections);
                          titles.push(hasChildrenContents);
                          slog.push(new Object({
                            id: its.id,
                            plannedTime: this.utils.formatDate(its.plannedTime, DATE_FORMAT.DD_MM_YYYY_HH_mm),
                            createTime: this.utils.formatDate(its.createTime, DATE_FORMAT.DD_MM_YYYY_HH_mm),
                            hasChildrenSections: hasChildrenSections,
                            hasContents: hasChildrenContents,
                            title: its.title
                          }));
                          if (i < list.length - 1 && !hasChildrenSections && !hasChildrenContents) {
                            titles.push('delete');
                            await sectionsDBRef[its.id].delete();
                          }
                        }
                        csvLog.push(titles.join(';'));
                        log[clientId][eventDoc.id] = union(log[clientId][eventDoc.id] ?? [],
                          [{eventId: eventDoc.id, shortLink: eventDoc.data().shortLink,
                            eventEndDate: this.utils.formatDate(eventDoc.data().endDate, DATE_FORMAT.DD_MM_YYYY_HH_mm),
                            plannedTime: this.utils.formatDate(s.plannedTime, DATE_FORMAT.DD_MM_YYYY_HH_mm), sections: slog}]);
                      }
                    }
                  }
                }
              });
          }
        });
    };

    if (!await this.commonService.confirmation(
      'Run: Find sections with duplicate planned time.')) {
      return;
    }
    const clientIds = Array.from(manualClientId.selectedOptions).map(({ value }) => value);
    if (isEmpty(clientIds)) {
      return;
    }
    const cache: IWriteCache[] = [];
    const log = {};
    const csvLog = [];
    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => new ClientConfig(Object.assign({id: it.id}, it.data())))
          .filter(cl => clientIds.includes(cl.id));
      });
    for (const client of clients) {
      const clientId = client.id;
      console.log(`Client ${clientId} started`);
      log[clientId] = {};
      await runFindSectionsWithDuplicatePlannedTime(clientId);
      console.log(`Client ${clientId} finished`);
    }
    console.log('~~~~ log ~~~~');
    console.log(log);
    console.log('~~~~ title ~~~~');
    console.log(csvLog);
    this.utils.downloadCsvReport(csvLog.join('\n'), 'titles.csv', 'text/csv;encoding:utf-8');
  }

  async removeLeaderboardField() {
    const request = (collection: string, size: number, startAt) => {
      let queryFn = q => q.orderBy('type').limit(size);
      if (startAt) {
        queryFn = q => q.orderBy('type').limit(size).startAfter(startAt);
      }
      return this.afs.collectionGroup(collection, queryFn).get();
    };

    if (!await this.commonService.confirmation(
      'Run: Remove field leaderboard from sections')) {
      return;
    }

    const cache: IWriteCache[] = [];
    console.log('Start process');

    const chunkSize = 200;

    console.log('query sections');
    let sectionsCount = 0;
    let sections_ = await firstValueFrom(request('timeline', chunkSize, null));
    while (sections_.docs.length > 0) {
      sectionsCount += sections_.docs.length;
      console.log('loaded', sectionsCount);
      for (const doc of sections_.docs) {
        const sn = doc.data();
        if (sn.hasOwnProperty('leaderboard')) {
          cache.push({ref: doc.ref, obj: {'leaderboard': firebase.firestore.FieldValue.delete()}});
        }
      }
      sections_ = await firstValueFrom(request('timeline', chunkSize, sections_.docs[sections_.docs.length - 1]));
    }

    this.commitCache(cache).then(() => console.log('End process.'));
  }

  async convertOldQuestionOptionsToNewFormat() {

    const convertQuestion = (question, type: 'quiz' | 'questionnaire') => {
      const options = {};
      const files = [];
      let matchingFieldChanged = false;
      if (!isEmpty(question.items) && question.items.some(o => o.hasOwnProperty('matchingId'))) {
        for (const item of question.items) {
          if (!!item.matchingId) {
            item.matching = [item.matchingId];
            delete item.matchingId;
            matchingFieldChanged = true;
          }
        }
      }
      if (question.hasOwnProperty('simpleMatching')) {
        options['simpleMatching'] = question.simpleMatching;
        delete question.simpleMatching;
      }
      if (question.hasOwnProperty('smilesRatingSet')) {
        options['smilesRatingSet'] = question.smilesRatingSet;
        delete question.smilesRatingSet;
      }
      if (question.hasOwnProperty('wordCloudTemplate')) {
        if (question.wordCloudTemplate?.url) {
          files.push(question.wordCloudTemplate.url);
          delete question.wordCloudTemplate.url;
        }
        options['wordCloudTemplate'] = cloneDeep(question.wordCloudTemplate);
        delete question.wordCloudTemplate;
      }
      if (type === 'quiz' && question.hasOwnProperty('imageUrl')) {
        files.push(question.imageUrl);
        delete question.imageUrl;
      }
      if (matchingFieldChanged || !isEmpty(options) || !isEmpty(files)) {
        if (!isEmpty(options)) {
          question['options'] = options;
        }
        if (!isEmpty(files)) {
          question['files'] = files;
        }
        return true;
      }
      return false;
    };

    const runConferenceConvertQuestionOptions = async (clientId) => {
      return await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events').get())
        .then(async (eventsSnapshot) => {
          const eventDocs = eventsSnapshot.docs
            .filter(d => !d.data().deleted);
          const size = eventDocs.length;
          let counter = 0;
          for (const eventDoc of eventDocs) {
            await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('conference').doc(eventDoc.id)
              .collection('timeline').get())
              .then(async (snapshot) => {
                console.log(`process event ${++counter} of ${size}`);
                let sectionCounter = 0;
                const sectionCount = snapshot.docs.length;
                for (const s of snapshot.docs) {
                  console.log(`process event ${eventDoc.id} sections ${++sectionCounter} of ${sectionCount}`);
                  const contents = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
                    .collection('conference').doc(eventDoc.id)
                    .collection('timeline').doc(s.id)
                    .collection('contents', ref => ref.where('itemsTypes', 'array-contains', CONTAINER_ITEM_TYPE.QUIZ))
                    .get())
                    .then(snap => snap.docs);
                  const contentContainersDocs = contents
                    .filter(d => d.data().type === Constants.CONTENT_TYPE_CONTENT_CONTAINER &&
                      d.data().itemsTypes?.includes(CONTAINER_ITEM_TYPE.QUIZ))
                    .map(cd => new Object({id: cd.id, ...cd.data()}));
                  for (const content of contentContainersDocs) {
                    const quizItems = content['items'].filter(it => it.type === CONTAINER_ITEM_TYPE.QUIZ);
                    let needUpdate = false;
                    for (const quizItem of quizItems) {
                      const questions = quizItem.data.questions;
                      for (const questionId of Object.keys(questions)) {
                        const question = questions[questionId];
                        if (convertQuestion(question, 'quiz')) {
                          needUpdate = true;
                        }
                      }
                    }
                    if (needUpdate) {
                      const ref = contents.find(d => d.id === content['id']).ref;
                      delete content['id'];
                      cache.push({ref: ref, obj: content});
                    }
                  }
                  const questionnaireDocs = contents
                    .filter(d => d.data().type === Constants.CONTENT_TYPE_QUESTIONNAIRE)
                    .map(cd => new Object({id: cd.id, ...cd.data()}));
                  for (const content of questionnaireDocs) {
                    const questions = content['questions'];
                    let needUpdate = false;
                    for (const questionId of Object.keys(questions)) {
                      const question = questions[questionId];
                      if (convertQuestion(question, 'questionnaire')) {
                        needUpdate = true;
                      }
                    }
                    if (needUpdate) {
                      const ref = contents.find(d => d.id === content['id']).ref;
                      delete content['id'];
                      cache.push({ref: ref, obj: content});
                    }
                  }
                }
              });
          }
        });
    };

    const runModulesConvertQuestionOptions = async (clientId) => {
      return await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('modules').get())
        .then(async (eventsSnapshot) => {
          const eventDocs = eventsSnapshot.docs
            .filter(d => !d.data().deleted);
          const size = eventDocs.length;
          let counter = 0;
          for (const eventDoc of eventDocs) {
            await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('modules').doc(eventDoc.id)
              .collection('timeline').get())
              .then(async (snapshot) => {
                console.log(`process module ${++counter} of ${size}`);
                for (const s of snapshot.docs) {
                  const contents = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
                    .collection('modules').doc(eventDoc.id)
                    .collection('timeline').doc(s.id)
                    .collection('contents')
                    .get())
                    .then(snap => snap.docs);
                  const contentContainersDocs = contents
                    .filter(d => d.data().type === Constants.CONTENT_TYPE_CONTENT_CONTAINER &&
                      d.data().itemsTypes?.includes(CONTAINER_ITEM_TYPE.QUIZ))
                    .map(cd => new Object({id: cd.id, ...cd.data()}));
                  for (const content of contentContainersDocs) {
                    const quizItems = content['items'].filter(it => it.type === CONTAINER_ITEM_TYPE.QUIZ);
                    let needUpdate = false;
                    for (const quizItem of quizItems) {
                      const questions = quizItem.data.questions;
                      for (const questionId of Object.keys(questions)) {
                        const question = questions[questionId];
                        if (convertQuestion(question, 'quiz')) {
                          needUpdate = true;
                        }
                      }
                    }
                    if (needUpdate) {
                      const ref = contents.find(d => d.id === content['id']).ref;
                      delete content['id'];
                      cache.push({ref: ref, obj: content});
                    }
                  }
                  const questionnaireDocs = contents
                    .filter(d => d.data().type === Constants.CONTENT_TYPE_QUESTIONNAIRE)
                    .map(cd => new Object({id: cd.id, ...cd.data()}));
                  for (const content of questionnaireDocs) {
                    const questions = content['questions'];
                    let needUpdate = false;
                    for (const questionId of Object.keys(questions)) {
                      const question = questions[questionId];
                      if (convertQuestion(question, 'questionnaire')) {
                        needUpdate = true;
                      }
                    }
                    if (needUpdate) {
                      logOldQuestionnaire.push({clientId,
                        eventId: eventDoc.id, shortLink: eventDoc.data()['shortLink'], sectionId: s.id, contentId: content['id']});
                      const ref = contents.find(d => d.id === content['id']).ref;
                      delete content['id'];
                      cache.push({ref: ref, obj: content});
                    }
                  }

                  const draftContents = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
                    .collection('modules').doc(eventDoc.id)
                    .collection('timeline').doc(s.id)
                    .collection('draft_contents')
                    .get())
                    .then(snap => snap.docs);
                  const draftContentContainersDocs = draftContents
                    .filter(d => d.data().type === Constants.CONTENT_TYPE_CONTENT_CONTAINER &&
                      d.data().itemsTypes?.includes(CONTAINER_ITEM_TYPE.QUIZ))
                    .map(cd => new Object({id: cd.id, ...cd.data()}));
                  for (const content of draftContentContainersDocs) {
                    const quizItems = content['items'].filter(it => it.type === CONTAINER_ITEM_TYPE.QUIZ);
                    let needUpdate = false;
                    for (const quizItem of quizItems) {
                      const questions = quizItem.data.questions;
                      for (const questionId of Object.keys(questions)) {
                        const question = questions[questionId];
                        if (convertQuestion(question, 'quiz')) {
                          needUpdate = true;
                        }
                      }
                    }
                    if (needUpdate) {
                      const ref = draftContents.find(d => d.id === content['id']).ref;
                      delete content['id'];
                      cache.push({ref: ref, obj: content});
                    }
                  }
                }
              });
          }
        });
    };

    let clientIds: string[];
    const logOldQuestionnaire = [];
    if (isEmpty(clientIds = await this.confirmationBeforeRun('Run: Convert old question options to new format.'))) {
      return;
    }
    const cache: IWriteCache[] = [];
    for (const clientId of clientIds) {
      console.log(`Client ${clientId} started`);
      await runConferenceConvertQuestionOptions(clientId);
      await runModulesConvertQuestionOptions(clientId);
      console.log(`Client ${clientId} finished`);
    }
    if (!isEmpty(logOldQuestionnaire)) {
      console.log(logOldQuestionnaire);
    }
    this.commitCacheSafe(cache).then(() => console.log('End process.'));
  }


  async fixedJapaneseLanguageISOCode() {

    const runFixedJapaneseLanguageISOCode = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events', ref => ref.where('languages', 'array-contains', 'jp')).get())
        .then(async (eventsSnapshot) => {
          const eventsDocs = eventsSnapshot.docs.filter(d => !d.data().deleted);
          let counter = 0;
          const size = eventsDocs.length;
          for (const eventDoc of eventsDocs) {
            console.log('processed ' + (++counter) + ' of ' + size);
            const event = new Event({eventId: eventDoc.id, ...eventDoc.data()});
            const eventUpdateObject = {languages: union(event.languages.filter(l => l !== 'jp'), ['ja'])};
            cache.push({ref: eventDoc.ref, obj: eventUpdateObject});
            const sectionsDocs = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('conference').doc(event.eventId)
              .collection('timeline', ref => ref.where('enabledLanguages', 'array-contains', 'jp')).get())
              .then(sectionsSnapshot => sectionsSnapshot.docs);
            for (const sectionDoc of sectionsDocs) {
              const section = new SectionContent({id: sectionDoc.id, ...sectionDoc.data()});
              const sectionUpdateObject = {enabledLanguages: union(section.enabledLanguages.filter(l => l !== 'jp'), ['ja'])};
              cache.push({ref: sectionDoc.ref, obj: sectionUpdateObject});
              const contentsDocs = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
                .collection('conference').doc(event.eventId)
                .collection('timeline').doc(section.id)
                .collection('contents', ref => ref.where('enabledLanguages', 'array-contains', 'jp')).get())
                .then(contentsSnapshot => contentsSnapshot.docs);
              for (const contentDoc of contentsDocs) {
                const content = new ContentContainer({id: contentDoc.id, ...contentDoc.data()});
                const contentUpdateObject = {enabledLanguages: union(content.enabledLanguages.filter(l => l !== 'jp'), ['ja'])};
                cache.push({ref: contentDoc.ref, obj: contentUpdateObject});
              }
            }
          }
        });
    };

    if (!await this.commonService.confirmation(
      'Run: Find sections with duplicate planned time.')) {
      return;
    }
    const cache: IWriteCache[] = [];
    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => new ClientConfig(Object.assign({id: it.id}, it.data())));
      });
    for (const client of clients) {
      const clientId = client.id;
      console.log(`Client ${clientId} started`);
      await runFixedJapaneseLanguageISOCode(clientId);
      console.log(`Client ${clientId} finished`);
    }
    this.commitCacheSafe(cache)
      .then(() => console.log('End process.'));
  }

  async setToTrueFollowMeAutoAssignPresenter() {

    const runSetToTrueFollowMeAutoAssignPresenter = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events').get())
        .then(async (eventsSnapshot) => {
          const eventsDocs = eventsSnapshot.docs.filter(d => !d.data().deleted);
          let counter = 0;
          const eventsLength = eventsDocs.length;
          for (const eventDoc of eventsDocs) {
            const settingsDoc = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('instant_settings')
              .doc(eventDoc.id).get());
            cache.push({ref: settingsDoc.ref, obj: {
              followMeSettings: {
                autoAssignPresenterToFollowMe: true
              }
            }});
            console.log('process ', ++counter, ' of ', eventsLength);
          }
        });
    };

    if (!await this.commonService.confirmation(
      'Run: Set To True FollowMe Auto Assign Presenter')) {
      return;
    }
    const cache: IWriteCache[] = [];
    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => new ClientConfig(Object.assign({id: it.id}, it.data())));
      });

    const cid = this.loginService.client_id$.getValue();
    for (const client of clients.filter(it => it.id === cid)) {
      const clientId = client.id;
      console.log(`Client ${clientId} started`);
      await runSetToTrueFollowMeAutoAssignPresenter(clientId);
      console.log(`Client ${clientId} finished`);
    }
    this.commitCache(cache)
      .then(() => console.log('End process.'));
  }

  async removeIdFromContentDocuments() {

    const request = (collection: string, size: number, startAt) => {
      let queryFn = q => q.orderBy('type').limit(size);
      if (startAt) {
        queryFn = q => q.orderBy('type').limit(size).startAfter(startAt);
      }
      return this.afs.collectionGroup(collection, queryFn).get();
    };

    if (!await this.commonService.confirmation(
      'Run: Remove field ID from content documents')) {
      return;
    }

    const cache: IWriteCache[] = [];
    console.log('Start process');

    const chunkSize = 250;

    console.log('query contents');
    let contentCount = 0;
    let contents_ = await firstValueFrom(request('contents', chunkSize, null));
    while (contents_.docs.length > 0) {
      contentCount += contents_.docs.length;
      console.log('loaded', contentCount);
      for (const doc of contents_.docs) {
        const cn = doc.data();
        if (cn.hasOwnProperty('id')) {
          cache.push({ref: doc.ref, obj: {id: firebase.firestore.FieldValue.delete()}});
        }
      }
      contents_ = await firstValueFrom(request('contents', chunkSize, contents_.docs[contents_.docs.length - 1]));
    }

    console.log('query draft contents.');
    let draftCount = 0;
    let draftContents_ = await firstValueFrom(request('draft_contents', chunkSize, null));
    while (draftContents_.docs.length > 0) {
      draftCount += draftContents_.docs.length;
      console.log('loaded', draftCount);
      for (const doc of draftContents_.docs) {
        const cn = doc.data();
        if (cn.hasOwnProperty('id')) {
          cache.push({ref: doc.ref, obj: {id: firebase.firestore.FieldValue.delete()}});
        }
      }
      draftContents_ = await firstValueFrom(request('draft_contents', chunkSize, draftContents_.docs[draftContents_.docs.length - 1]));
    }

    this.commitCache(cache).then(() => console.log('End process.'));
  }

  async fillEventAdditional() {

    const runFillEventAdditional = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('event_additional').get())
        .then(async (eventsSnapshot) => {
          const event_additionals = eventsSnapshot.docs;
          let counter = 0;
          const size = event_additionals.length;
          for (const additionalDoc of event_additionals) {
            if (!additionalDoc.get('shortLink') || !additionalDoc.get('deleted')) {
              const eventDoc = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
                .collection('events').doc(additionalDoc.id)
                .get());
              if (eventDoc.exists) {
                cache.push({
                  ref: additionalDoc.ref,
                  obj: {
                    shortLink: eventDoc.get('shortLink'),
                    deleted: eventDoc.get('deleted')
                  }
                });
              }
            }
            console.log('processed ' + (++counter) + ' of ' + size);
          }
        });
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events', q => q.where('guest', '==', true)).get())
        .then(async (eventsSnapshot) => {
          const events = eventsSnapshot.docs;
          let counter = 0;
          const size = events.length;
          for (const eventDoc of events) {
            if (!eventDoc.data().hasOwnProperty('guestName') && !eventDoc.data().hasOwnProperty('guestEmail')) {
              cache.push({
                ref: eventDoc.ref,
                obj: {
                  guestName: true,
                  guestEmail: true
                }
              });
            }
            console.log('processed ' + (++counter) + ' of ' + size);
          }
        });
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events').get())
        .then(async (eventsSnapshot) => {
          const events = eventsSnapshot.docs;
          let counter = 0;
          const size = events.length;
          for (const eventDoc of events) {
            if (eventDoc.data().hasOwnProperty('guestMode')) {
              cache.push({
                ref: eventDoc.ref,
                obj: {
                  guestMode: firebase.firestore.FieldValue.delete()
                }
              });
            }
            console.log('processed ' + (++counter) + ' of ' + size);
          }
        });
    };

    if (!await this.commonService.confirmation(
      'Run: Fill Event Additional.')) {
      return;
    }
    const cache: IWriteCache[] = [];
    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => new ClientConfig(Object.assign({id: it.id}, it.data())));
      });
    for (const client of clients) {
      const clientId = client.id;
      console.log(`Client ${clientId} started`);
      await runFillEventAdditional(clientId);
      console.log(`Client ${clientId} finished`);
    }
    this.commitCache(cache)
      .then(() => console.log('End process.'));
  }

  async createNewEmailTemplate() {

    const createNewEmailTemplate = async (clientId) => {
      await this.afs.collection('client_data').doc(clientId)
        .collection('mail_template').doc('R1EWOFxBhiw4VAk53LOX')
        .set({
          body: 'Hi ${user_name}!<br/><br/>Your Timeline password has been reset by the administrator. <br/><br/>Login Link: ${application_link}<br/>Your E-Mail: ${email}<br/>Your new password: ${password}<br/><br/>Best regards, The Timeline Team',
          name: 'New Password',
          subject: 'Your New Timeline Password',
          type: 'reset_password'
        });
    };

    if (!await this.commonService.confirmation(
      'Run: Create New Email Template.')) {
      return;
    }
    const cache: IWriteCache[] = [];
    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => new ClientConfig(Object.assign({id: it.id}, it.data())));
      });
    for (const client of clients) {
      const clientId = client.id;
      console.log(`Client ${clientId} started`);
      await createNewEmailTemplate(clientId);
      console.log(`Client ${clientId} finished`);
    }
    this.commitCache(cache)
      .then(() => console.log('End process.'));
  }

  async blockGmailUsers(save = false) {
    if (!await this.commonService.confirmation('Run: Block Gmail Users')) {
      return;
    }

    const csvLog = ['type;id;email;name'];

    let counter = 0;

    const cid = this.loginService.client_id$.getValue();
    const firstRef = this.afs.collection('client_data').doc(cid)
      .collection('app_users', q => q.orderBy('email').limit(1000));

    await firstValueFrom(firstRef.get())
      .then(async (firstSnapshot) => {
        const processSnapshot = (snapshot) => {
          for (const doc of snapshot.docs) {
            const email = doc.get('email') as string;
            let name = doc.get('fullName') as string;
            if (!name) {
              name = doc.get('userName') as string;
            }
            const blocked = doc.get('blocked') as boolean;
            const guest = doc.get('guest') as boolean;
            const uid = doc.id;
            if (blocked) {
              continue;
            }
            if (guest) {
              console.log(`Guest(${counter}). email=${email}, uid=${uid}, name=${name}`);
              csvLog.push(`guest;${uid};${email};${name}`);
            }
            if (email?.endsWith('@gmail.com')) {
              counter++;
              console.log(`Gmail(${counter}). email=${email}, uid=${uid}, name=${name}`);
              csvLog.push(`gmail;${uid};${email};${name}`);
/*
              if (save) {
                await this.dataService.setUserBlocked(uid, true)
                  .catch((e) => this.commonService.log.error(e));
              }
*/
            }
          }
        };

        processSnapshot(firstSnapshot);

        let lastDoc = firstSnapshot.docs[firstSnapshot.docs.length - 1];

        let hasNext = firstSnapshot.docs.length >= 1000;
        while (hasNext) {
          const queryFn = q => q.orderBy('email').startAfter(lastDoc).limit(1000);
          const nextRef = this.afs.collection('client_data').doc(cid)
            .collection('app_users', queryFn);
          const nextSnapshot = await firstValueFrom(nextRef.get());
          if (!nextSnapshot.empty) {
            processSnapshot(nextSnapshot);
            lastDoc = nextSnapshot.docs[nextSnapshot.docs.length - 1];
          } else {
            hasNext = false;
          }
        }
      });
    this.utils.downloadCsvReport(csvLog.join('\n'), 'gmail.csv', 'text/csv;encoding:utf-8');
    console.log('All tasks finished');
  }

  async fixSectionSpeakers() {
    const runFixSectionSpeakers = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events').get())
        .then(async (eventsSnapshot) => {
          const eventsDocs = eventsSnapshot.docs;
          let counter = 0;
          const eventsLength = eventsDocs.length;
          for (const eventDoc of eventsDocs) {
/*
            if (isEmpty(eventDoc.data())) {
              console.log(`isEmpty. id=${eventDoc.id} process ${++counter} of ${eventsLength}.`, eventDoc.id, eventDoc.data());
              await eventDoc.ref.delete();
              continue;
            }
*/
            let speakersDoc = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('event_sections_speakers').doc(eventDoc.id).get());

            if (speakersDoc.exists && speakersDoc.get('updateTrigger')) {
              await speakersDoc.ref.update({updateTrigger: firebase.firestore.FieldValue.delete()});
              speakersDoc = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
                .collection('event_sections_speakers').doc(eventDoc.id).get());
            }
            const speakers = speakersDoc.exists ? speakersDoc.data() : {};

            const timeline = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('conference').doc(eventDoc.id)
              .collection('timeline').get());

            const obj: {
              [sectionId: string]: {
                [userId: string]: boolean
              }
            } = {};

            let withIssue = false;
            const speakersObj = {};

            for (const sectionDoc of timeline.docs) {
              const section = sectionDoc.data() as SectionContent;
              if (!isEmpty(section.users)) {
                for (const user of section.users) {
                  if (user.speaker) {
                    if (!obj[sectionDoc.id]) {
                      obj[sectionDoc.id] = {};
                    }
                    obj[sectionDoc.id][user.userId] = true;
                    speakersObj[user.userId] = true;
                    const event_speakers = eventDoc.get('speakers');
                    if (!withIssue) {
                      withIssue = !event_speakers || !event_speakers[user.userId];
                    }
                  }
                }
              }
            }

            for (const sectionId of Object.keys(speakers)) {
              if (isEmpty(speakers[sectionId])) {
                delete speakers[sectionId];
              }
            }


            if (!isEqual(speakers, obj)) {
              console.log(`id=${eventDoc.id} process ${++counter} of ${eventsLength}. obj=${Object.keys(obj).length}, speakers=${Object.keys(speakers).length}`, obj, speakers);
              cache.push({ref: speakersDoc.ref, obj, options: {merge: false}});
            } else if (withIssue) {
              console.log(`wrong event speakers list. id=${eventDoc.id} process ${++counter} of ${eventsLength}. event=${Object.keys(eventDoc.get('speakers')).length}, document=${Object.keys(speakersObj).length}, obj=${Object.keys(obj).length}, speakers=${Object.keys(speakers).length}`, eventDoc.get('speakers'), obj, speakers);
              cache.push({ref: speakersDoc.ref, obj: {updateTrigger: {}}});
            } else {
              console.log(`id=${eventDoc.id} process ${++counter} of ${eventsLength}`);
            }
          }
        });
    };

    if (!await this.commonService.confirmation(
      'Run: Fix Section Speakers')) {
      return;
    }
    const cache: IWriteCache[] = [];
    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => new ClientConfig(Object.assign({id: it.id}, it.data())));
      });

    for (const client of clients) {
      const clientId = client.id;
      console.log(`Client ${clientId} started`);
      await runFixSectionSpeakers(clientId);
      console.log(`Client ${clientId} finished`);
    }
    console.log('Finish!');
    this.commitCache(cache)
      .then(() => console.log('End process.'));
  }

  async updateContentsRectFrom12To24() {
    const request = (collection: string, size: number, startAt) => {
      let queryFn: QueryFn = q => q.where('type', '==', Constants.CONTENT_TYPE_CONTENT_CONTAINER)
        .orderBy('type').limit(size);
      if (startAt) {
        queryFn = q => q.where('type', '==', Constants.CONTENT_TYPE_CONTENT_CONTAINER)
          .orderBy('type').limit(size).startAfter(startAt);
      }
      return this.afs.collectionGroup(collection, queryFn).get();
    };

    if (!await this.commonService.confirmation(
      'Run: Update contents rect from 12 to 24')) {
      return;
    }

    let cache: IWriteCache[] = [];
    console.log('Start process');

    const chunkSize = 500;

    console.log('query contents');
    let contentsCount = 0;
    let contents_ = await firstValueFrom(request('contents', chunkSize, null));
    while (contents_.docs.length > 0) {
      contentsCount += contents_.docs.length;
      console.log('loaded', contentsCount);
      for (const doc of contents_.docs) {
        const cc = doc.data() as ContentContainer;
        if (!cc.hasOwnProperty('systemData') && cc.hasOwnProperty('items')) {
          const bakData = cc.items.map(it => cloneDeep(pick(it, ['id', 'rect'])));
          for (const item of cc.items) {
            if (item.rect.startColumn < 1 || item.rect.columnLength > 12) {
              console.log(`%cIncorrect rect%c`, 'color: red', 'color: black', 'id: ', doc.id, cc);
            }
            if (item.rect.startColumn < 1) {
              item.rect.startColumn = 1;
            } else {
              item.rect.startColumn = (item.rect.startColumn * 2) - 1;
            }
            if (item.rect.columnLength > 12) {
              item.rect.columnLength = 24;
            } else {
              item.rect.columnLength = item.rect.columnLength * 2;
            }
          }
          cc.systemData = bakData;
          cache.push({ref: doc.ref, obj: cc});
        }
      }
      if (cache.length) {
        await this.commitCache(cache);
        cache = [];
      }
      contents_ = await firstValueFrom(request('contents', chunkSize, contents_.docs[contents_.docs.length - 1]));
    }

    console.log('query draft_contents');
    let draftContentsCount = 0;
    let draftContents_ = await firstValueFrom(request('draft_contents', chunkSize, null));
    while (draftContents_.docs.length > 0) {
      draftContentsCount += draftContents_.docs.length;
      console.log('loaded', draftContentsCount);
      for (const doc of draftContents_.docs) {
        const cc = doc.data() as ContentContainer;
        if (!cc.hasOwnProperty('systemData') && cc.hasOwnProperty('items')) {
          const bakData = cc.items.map(it => cloneDeep(pick(it, ['id', 'rect'])));
          for (const item of cc.items) {
            if (item.rect.startColumn < 1 || item.rect.columnLength > 12) {
              console.log(`%cIncorrect rect%c`, 'color: red', 'color: black', cc);
              continue;
            }
            item.rect.startColumn = (item.rect.startColumn * 2) - 1;
            item.rect.columnLength = item.rect.columnLength * 2;
          }
          cc.systemData = bakData;
          cache.push({ref: doc.ref, obj: cc});
        }
      }
      draftContents_ = await firstValueFrom(request('draft_contents', chunkSize, draftContents_.docs[draftContents_.docs.length - 1]));
    }

    this.commitCache(cache).finally(() => console.log('End process.'));
  }

  async clearTemporaryDataAfterUpdateRectFrom12To24() {
    const request = (collection: string, size: number, startAt) => {
      let queryFn: QueryFn = q => q.where('type', '==', Constants.CONTENT_TYPE_CONTENT_CONTAINER)
        .orderBy('type').limit(size);
      if (startAt) {
        queryFn = q => q.where('type', '==', Constants.CONTENT_TYPE_CONTENT_CONTAINER)
          .orderBy('type').limit(size).startAfter(startAt);
      }
      return this.afs.collectionGroup(collection, queryFn).get();
    };

    if (!await this.commonService.confirmation(
      'Run: Clear temporary data')) {
      return;
    }

    const cache: IWriteCache[] = [];
    console.log('Start process');

    const chunkSize = 200;

    console.log('query contents');
    let contentsCount = 0;
    let contents_ = await firstValueFrom(request('contents', chunkSize, null));
    while (contents_.docs.length > 0) {
      contentsCount += contents_.docs.length;
      console.log('loaded', contentsCount);
      for (const doc of contents_.docs) {
        const cc = doc.data() as ContentContainer;
        if (cc.hasOwnProperty('systemData')) {
          cache.push({ref: doc.ref, obj: {systemData: firebase.firestore.FieldValue.delete()}});
        }
      }
      contents_ = await firstValueFrom(request('contents', chunkSize, contents_.docs[contents_.docs.length - 1]));
    }

    console.log('query draft_contents');
    let draftContentsCount = 0;
    let draftContents_ = await firstValueFrom(request('draft_contents', chunkSize, null));
    while (draftContents_.docs.length > 0) {
      draftContentsCount += draftContents_.docs.length;
      console.log('loaded', draftContentsCount);
      for (const doc of draftContents_.docs) {
        const cc = doc.data() as ContentContainer;
        if (cc.hasOwnProperty('systemData')) {
          cache.push({ref: doc.ref, obj: {systemData: firebase.firestore.FieldValue.delete()}});
        }
      }
      draftContents_ = await firstValueFrom(request('draft_contents', chunkSize, draftContents_.docs[draftContents_.docs.length - 1]));
    }

    this.commitCache(cache).finally(() => console.log('End process.'));
  }

  async revertUpdateRectFrom12To24() {
    const request = (collection: string, size: number, startAt) => {
      let queryFn: QueryFn = q => q.where('type', '==', Constants.CONTENT_TYPE_CONTENT_CONTAINER)
        .orderBy('type').limit(size);
      if (startAt) {
        queryFn = q => q.where('type', '==', Constants.CONTENT_TYPE_CONTENT_CONTAINER)
          .orderBy('type').limit(size).startAfter(startAt);
      }
      return this.afs.collectionGroup(collection, queryFn).get();
    };

    if (!await this.commonService.confirmation(
      'Run: Revert data')) {
      return;
    }

    const cache: IWriteCache[] = [];
    console.log('Start process');

    const chunkSize = 200;

    console.log('query contents');
    let contentsCount = 0;
    let contents_ = await firstValueFrom(request('contents', chunkSize, null));
    while (contents_.docs.length > 0) {
      contentsCount += contents_.docs.length;
      console.log('loaded', contentsCount);
      for (const doc of contents_.docs) {
        const cc = doc.data() as ContentContainer;
        if (cc.systemData) {
          for (const item of cc.items) {
            const bakItem = cc.systemData.find(it => it.id === item.id);
            if (bakItem) {
              item.rect = bakItem.rect;
            }
          }
          cache.push({ref: doc.ref, obj: cc});
        }
      }
      contents_ = await firstValueFrom(request('contents', chunkSize, contents_.docs[contents_.docs.length - 1]));
    }

    console.log('query draft_contents');
    let draftContentsCount = 0;
    let draftContents_ = await firstValueFrom(request('draft_contents', chunkSize, null));
    while (draftContents_.docs.length > 0) {
      draftContentsCount += draftContents_.docs.length;
      console.log('loaded', draftContentsCount);
      for (const doc of draftContents_.docs) {
        const cc = doc.data() as ContentContainer;
        if (cc.systemData) {
          for (const item of cc.items) {
            const bakItem = cc.systemData.find(it => it.id === item.id);
            if (bakItem) {
              item.rect = bakItem.rect;
            }
          }
          cache.push({ref: doc.ref, obj: cc});
        }
      }
      draftContents_ = await firstValueFrom(request('draft_contents', chunkSize, draftContents_.docs[draftContents_.docs.length - 1]));
    }

    this.commitCache(cache).finally(() => console.log('End process.'));
  }

  async checkContentsItemsRect() {
    const request = (collection: string, size: number, startAt) => {
      let queryFn: QueryFn = q => q.where('type', '==', Constants.CONTENT_TYPE_CONTENT_CONTAINER)
        .orderBy('type').limit(size);
      if (startAt) {
        queryFn = q => q.where('type', '==', Constants.CONTENT_TYPE_CONTENT_CONTAINER)
          .orderBy('type').limit(size).startAfter(startAt);
      }
      return this.afs.collectionGroup(collection, queryFn).get();
    };

    if (!await this.commonService.confirmation('Run: Check contents items rect')) {
      return;
    }


    console.log('Start process');

    const csvLog = ['clientId;eventId;parentId;id;path;path-type;info'];
    const chunkSize = 200;

    console.log('query contents');
    let contentsCount = 0;
    let contents_ = await firstValueFrom(request('contents', chunkSize, null));
    while (contents_.docs.length > 0) {
      contentsCount += contents_.docs.length;
      console.log('loaded', contentsCount);
      for (const doc of contents_.docs) {
        const cc = doc.data() as ContentContainer;
        if (cc.hasOwnProperty('items') && !cc.itemsColumnFormat) {
          let warn = false;
          let warnCount = 0;
          const overflowList = [];
          for (const item of cc.items) {
            if (item.rect.startColumn > 12 || item.rect.columnLength > 12 || (item.rect.startColumn + item.rect.columnLength) > 13) {
              warn = true;
              warnCount++;
              overflowList.push(item.rect.columnLength);
            }
          }
          if (warn) {
            const clientId = doc.ref.path.split('/')[1];
            let info: string;
            if (cc.items.length === 1 && cc.items[0].rect.startColumn === 1 && cc.items[0].rect.columnLength > 12) {
              info = `count = 1, startColumn = 1, columnLength = ${cc.items[0].rect.columnLength}`;
            } else {
              info = `count = ${cc.items.length}, warning count = ${warnCount}, overflow list = [${overflowList.join(',')}]`;
            }
            csvLog.push(`${clientId};${cc.eventId};${cc.parentId};${doc.id};${doc.ref.path.split('/')[2]};content;${info}`);
            console.log(`%cIncorrect rect%c`, 'color: red', 'color: black', 'id: ', doc.id, cc);
          }
        }
      }
      contents_ = await firstValueFrom(request('contents', chunkSize, contents_.docs[contents_.docs.length - 1]));
    }

    console.log('query draft_contents');
    let draftContentsCount = 0;
    let draftContents_ = await firstValueFrom(request('draft_contents', chunkSize, null));
    while (draftContents_.docs.length > 0) {
      draftContentsCount += draftContents_.docs.length;
      console.log('loaded', draftContentsCount);
      for (const doc of draftContents_.docs) {
        const cc = doc.data() as ContentContainer;
        if (cc.hasOwnProperty('items') && !cc.itemsColumnFormat) {
          let warn = false;
          let warnCount = 0;
          const overflowList = [];
          for (const item of cc.items) {
            if (item.rect.startColumn > 12 || item.rect.columnLength > 12 || (item.rect.startColumn + item.rect.columnLength) > 13) {
              warn = true;
              warnCount++;
              overflowList.push(item.rect.columnLength);
            }
          }
          if (warn) {
            const clientId = doc.ref.path.split('/')[1];
            let info: string;
            if (cc.items.length === 1 && cc.items[0].rect.startColumn === 1 && cc.items[0].rect.columnLength > 12) {
              info = `count = 1, startColumn = 1, columnLength = ${cc.items[0].rect.columnLength}`;
            } else {
              info = `count = ${cc.items.length}, warning count = ${warnCount}, overflow list = [${overflowList.join(',')}]`;
            }
            csvLog.push(`${clientId};${cc.eventId};${cc.parentId};${doc.id};${doc.ref.path.split('/')[2]};content;${info}`);
            console.log(`%cIncorrect rect%c`, 'color: red', 'color: black', 'id: ', doc.id, cc);
          }
        }
      }
      draftContents_ = await firstValueFrom(request('draft_contents', chunkSize, draftContents_.docs[draftContents_.docs.length - 1]));
    }
    console.log(csvLog);
    this.utils.downloadCsvReport(csvLog.join('\n'), 'check-items-rect.csv', 'text/csv;encoding:utf-8');
  }

  async updateEventSettingAssignFreeSlotModeValue() {

    const runUpdateEventSettingAssignFreeSlotModeValue = async (clientId) => {
      return await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('instant_settings').get())
        .then(async (settingsSnapshot) => {
          const settingsDocs = settingsSnapshot.docs
            .filter(d => !d.data().deleted);
          const size = settingsDocs.length;
          let counter = 0;
          for (const settingsDoc of settingsDocs) {
            console.log(`process ${++counter} of ${size}`);
            const settings = settingsDoc.data() as InstantSettings;
            const assignFreeSlotMode = settings?.slotModeSettings?.assignFreeSlotMode;
            if (assignFreeSlotMode && assignFreeSlotMode !== ASSIGN_FREE_SLOT_MODE.REFERENCE) {
              cache.push({
                ref: settingsDoc.ref,
                obj: {slotModeSettings: {
                    assignFreeSlotMode: ASSIGN_FREE_SLOT_MODE.REFERENCE
                  }
                }
              });
            }
          }
        });
    };

    let clientIds: string[];
    if (isEmpty(clientIds = await this.confirmationBeforeRun('Run: Update event setting assign free slot mode value.'))) {
      return;
    }
    const cache: IWriteCache[] = [];
    for (const clientId of clientIds) {
      console.log(`Client ${clientId} started`);
      await runUpdateEventSettingAssignFreeSlotModeValue(clientId);
      console.log(`Client ${clientId} finished`);
    }
    this.commitCache(cache).finally(() => console.log('End process.'));
  }

  async checkAvailableContents(remove) {

    const runCheckAvailableContents = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events').get())
        .then(async (eventsSnapshot) => {
          const eventsDocs = eventsSnapshot.docs.filter(d => !d.get('deleted'));
          let counter = 0;
          const size = eventsDocs.length;
          for (const eventDoc of eventsDocs) {
            console.log('processed ' + (++counter) + ' of ' + size);
            const event = new Event({eventId: eventDoc.id, ...eventDoc.data()});
            const straightawayTimeline = event.entityLink?.straightawayTimeline ||
              event.entityLink?.params?.straightawayTimeline;
            // 1722459600000 = 2024/08/01
            if (!straightawayTimeline && (event.endDate < 1722459600000 || !event.endDate)) {
              continue;
            }
            const eventSections = (await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('conference').doc(event.eventId)
              .collection('timeline', ref => ref.where('education', '==', !straightawayTimeline)).get())
              .then(sectionsSnapshot => sectionsSnapshot.docs.map(d => new SectionContent({id: d.id, ...d.data()}))))
              .sort(this.utils.comparator(Constants.ORDERINDEX));
            const competencyList = eventSections.filter(s => s.entityLink?.entityType === ENTITY_LINK_TYPE.COMPETENCY)
              .sort(this.utils.comparator(Constants.ORDERINDEX));
            for (const competency of competencyList) {
              const modules = eventSections
                .filter(s => s.entityLink?.entityType === ENTITY_LINK_TYPE.MODULE && s.parentId === competency.id);
              if (isEmpty(modules)) {
                continue;
              }
              const modulesIds = modules.map(m => m.entityLink.entityId);
              if (modulesIds.length === uniq(modulesIds).length) {
                continue;
              }
              if (!statistics[event.eventId]) {
                statistics[event.eventId] = {
                  eventId: event.eventId, eventShortLink: event.shortLink,
                  createDate: this.utils.formatDate(event.createDate.getTime(), 'yyyy-MM-dd, HH:mm:ss'),
                  endDate: this.utils.formatDate(event.endDate, 'yyyy-MM-dd, HH:mm:ss'),
                  eventName: event.name,
                  competency: {}};
              }
              if (!statistics[event.eventId].competency[competency.id]) {
                statistics[event.eventId].competency[competency.id] = {
                  competencyId: competency.id, competencyTitle: competency.title, modules: {}
                };
              }
              const duplicateIds = [];
              for (const moduleId of uniq(modulesIds)) {
                if (modulesIds.filter(id => id === moduleId).length > 1) {
                  duplicateIds.push(moduleId);
                  statistics[event.eventId].competency[competency.id].modules[moduleId] = {
                    activeShortcuts: false,
                    duplicateInModulesChildren: false,
                    customContentsInModulesChildren: false,
                    sections: []
                  };
                }
              }
              for (const moduleId of duplicateIds) {
                const moduleSections = modules.filter(s => s.entityLink.entityId === moduleId)
                  .sort(this.utils.comparator('createTime'));
                // check first section in duplicate
                const firstModule = moduleSections[0];
                const firstModuleChildren = this.utils.getSectionChildrenTree(firstModule, eventSections); // include firstModule
                let firstModuleHasCustomContents = false;
                console.log(`check module first duplicate ${firstModule.id} custom contents and sections`);
                let counter = 0;
                for (const child of firstModuleChildren) {
                  if (!child.entityLink) {
                    customSection.push({eventShortLink: event.shortLink, moduleSectionId: firstModule.id, section: child});
                    firstModuleHasCustomContents = true;
                  }
                  console.log(`check first duplicate: ${firstModule.id}`, ++counter, ' of ', firstModuleChildren.length);
                  const contents = await this.dataService.getSectionContentsPromise(child.eventId, child.id, CONTENT_PATH_TYPE.DEFAULT);
                  if (!isEmpty(contents) && contents.some(cn => isEmpty(cn.entityLink) || !cn.releaseVersion)) {
                    customContents.push({eventShortLink: event.shortLink, moduleSectionId: firstModule.id, section: child,
                      contentsIds: contents.filter(cn => isEmpty(cn.entityLink) || !cn.releaseVersion).map(cn => cn.id)});
                    firstModuleHasCustomContents = true;
                  }
                }
                const hasShortcut = firstModuleChildren.some(s => !isEmpty(s.shortcuts));
                const firstModuleChildrenIds = firstModuleChildren
                  .filter(s => s.id !== firstModule.id && !!s.entityLink)
                  .map(s => s.entityLink.sectionId);
                const duplicateInChildren = firstModuleChildrenIds.length !== uniq(firstModuleChildrenIds).length;
                const duplicateChildren: any[] = [];
                for (const childId of (duplicateInChildren ? uniq(firstModuleChildrenIds) : [])) {
                  const duplicateList = firstModuleChildren.filter(s => s.entityLink?.sectionId === childId)
                    .sort(this.utils.comparator('createTime'))
                    .map(s => new Object({
                      id: s.id,
                      createTimeStr: this.utils.formatDate(s.createTime, 'yyyy-MM-dd, HH:mm:ss'),
                      createTime: s.createTime,
                      title: s.title,
                      entityLinkId: s.entityLink.sectionId,
                      shortcuts: s.shortcuts,
                      orderIndex: s.orderIndex
                    }));
                  duplicateChildren.push(...duplicateList);
                }
                const originIdLinks: {idFrom, idTo, shortcuts}[] = [];
                if (hasShortcut) {
                  // for first module this is only for information. not for change.
                  for (const child of firstModuleChildren.filter(ch => !isEmpty(ch.shortcuts))) {
                    if (child.entityLink.entityType === ENTITY_LINK_TYPE.MODULE) {
                      originIdLinks.push({idFrom: child.id, idTo: firstModule.id, shortcuts: child.shortcuts});
                    } else if (child.entityLink.entityType === ENTITY_LINK_TYPE.SECTION) {
                      const firstChild = firstModuleChildren.find(ch => ch.entityLink.sectionId === child.entityLink.sectionId);
                      if (firstChild) {
                        originIdLinks.push({idFrom: child.id, idTo: firstChild.id, shortcuts: child.shortcuts});
                      } else {
                        originIdLinks.push({idFrom: child.id, idTo: 'not found', shortcuts: child.shortcuts});
                      }
                    }
                  }
                }
                statistics[event.eventId].competency[competency.id].modules[moduleId].sections
                  .push({
                    sectionId: firstModule.id,
                    releaseVersion: firstModule.releaseVersion?.version,
                    createTimeStr: this.utils.formatDate(firstModule.createTime, 'yyyy-MM-dd, HH:mm:ss'),
                    hasShortcut: hasShortcut,
                    hasCustomContents: firstModuleHasCustomContents,
                    entityLinkId: firstModule.entityLink.entityId,
                    duplicateInChildren: duplicateInChildren,
                    duplicateChildren: duplicateChildren,
                    title: firstModule.title,
                    orderIndex: firstModule.orderIndex,
                    createTime: firstModule.createTime,
                    originIdLinks: originIdLinks
                  });
                // check other sections in duplicate
                for (const ms of moduleSections.filter(m => m.id !== firstModule.id)) {
                  const children = this.utils.getSectionChildrenTree(ms, eventSections); // include ms
                  let hasCustomContents = false;
                  console.log(`check module other duplicate ${ms.id} custom contents and sections`);
                  let counter = 0;
                  for (const child of children) {
                    if (!child.entityLink) {
                      customSection.push({eventShortLink: event.shortLink, moduleSectionId: ms.id, section: child});
                      hasCustomContents = true;
                    }
                    console.log(`check other duplicate: ${ms.id}`, ++counter, ' of ', children.length);
                    const contents = await this.dataService.getSectionContentsPromise(child.eventId, child.id, CONTENT_PATH_TYPE.DEFAULT);
                    if (!isEmpty(contents) && contents.some(cn => isEmpty(cn.entityLink) || !cn.releaseVersion)) {
                      customContents.push({eventShortLink: event.shortLink, moduleSectionId: ms.id, section: child,
                        contentsIds: contents.filter(cn => isEmpty(cn.entityLink) || !cn.releaseVersion).map(cn => cn.id)});
                      hasCustomContents = true;
                    }
                  }
                  const hasShortcut = children.some(s => !isEmpty(s.shortcuts));
                  const moduleChildrenIds = children
                    .filter(s => s.id !== ms.id && !!s.entityLink)
                    .map(s => s.entityLink.sectionId);
                  const duplicateInChildren = moduleChildrenIds.length !== uniq(moduleChildrenIds).length;
                  const duplicateChildren: any[] = [];
                  for (const childId of (duplicateInChildren ? uniq(moduleChildrenIds) : [])) {
                    const duplicateList = children.filter(s => s.entityLink?.sectionId === childId)
                      .sort(this.utils.comparator('createTime'))
                      .map(s => new Object({
                        id: s.id,
                        createTimeStr: this.utils.formatDate(s.createTime, 'yyyy-MM-dd, HH:mm:ss'),
                        createTime: s.createTime,
                        title: s.title,
                        entityLinkId: s.entityLink.sectionId,
                        shortcuts: s.shortcuts,
                        orderIndex: s.orderIndex
                      }));
                    duplicateChildren.push(...duplicateList);
                  }
                  const originIdLinks: {idFrom, idTo, shortcuts}[] = [];
                  if (hasShortcut) {
                    for (const child of children.filter(ch => !isEmpty(ch.shortcuts))) {
                      // we must change current shortcut link to link from first module.
                      if (child.entityLink.entityType === ENTITY_LINK_TYPE.MODULE) {
                        originIdLinks.push({idFrom: child.id, idTo: firstModule.id, shortcuts: child.shortcuts});
                      } else if (child.entityLink.entityType === ENTITY_LINK_TYPE.SECTION) {
                        const firstChild = firstModuleChildren.find(ch => ch.entityLink.sectionId === child.entityLink.sectionId);
                        if (firstChild) {
                          originIdLinks.push({idFrom: child.id, idTo: firstChild.id, shortcuts: child.shortcuts});
                        } else {
                          originIdLinks.push({idFrom: child.id, idTo: 'not found', shortcuts: child.shortcuts});
                        }
                      }
                    }
                  }
                  statistics[event.eventId].competency[competency.id].modules[moduleId].sections
                    .push({
                      sectionId: ms.id,
                      releaseVersion: ms.releaseVersion?.version,
                      createTimeStr: this.utils.formatDate(ms.createTime, 'yyyy-MM-dd, HH:mm:ss'),
                      hasShortcut: hasShortcut,
                      hasCustomContents: hasCustomContents,
                      entityLinkId: ms.entityLink.entityId,
                      duplicateInChildren: duplicateInChildren,
                      duplicateChildren: duplicateChildren,
                      title: ms.title,
                      orderIndex: ms.orderIndex,
                      createTime: ms.createTime,
                      originIdLinks: originIdLinks
                    });
                }
                statistics[event.eventId].competency[competency.id].modules[moduleId].activeShortcuts =
                  statistics[event.eventId].competency[competency.id].modules[moduleId].sections.some(s => s.hasShortcut);
                statistics[event.eventId].competency[competency.id].modules[moduleId].duplicateInModulesChildren =
                  statistics[event.eventId].competency[competency.id].modules[moduleId].sections.some(s => s.duplicateInChildren);
                statistics[event.eventId].competency[competency.id].modules[moduleId].customContentsInModulesChildren =
                  statistics[event.eventId].competency[competency.id].modules[moduleId].sections.some(s => s.hasCustomContents);
                // if module has custom contents ignore delete duplicate
                if (statistics[event.eventId].competency[competency.id].modules[moduleId].customContentsInModulesChildren) {
                  continue;
                }
                // create list for delete module sections
                let deleteModuleSections: {id, eventId, createTime, title, entityLinkId, moduleVersion, eventShortLink, orderIndex}[] = [];
                const moduleHeadSections = statistics[event.eventId].competency[competency.id].modules[moduleId].sections;
                // added sections as module without shortcuts and exclude first section as module
                moduleHeadSections.filter(s => s.sectionId !== firstModule.id && !s.hasShortcut)
                  .forEach(hs => {
                    const hSection = eventSections.find(it => it.id === hs.sectionId);
                    const tree = this.utils.getSectionChildrenTree(hSection, eventSections); // tree includes hs
                    tree.forEach(s => {
                      deleteModuleSections.push({
                        id: s.id,
                        createTime: this.utils.formatDate(s.createTime, 'yyyy-MM-dd, HH:mm:ss'),
                        eventId: event.eventId,
                        title: s.title,
                        entityLinkId: s.entityLink.sectionId ?? s.entityLink.entityId,
                        moduleVersion: s.releaseVersion,
                        eventShortLink: event.shortLink,
                        orderIndex: s.orderIndex
                      });
                    });
                  });
                // prepare first section = module head
                const firstModuleHeadSection = moduleHeadSections.find(s => s.sectionId === firstModule.id);
                if (firstModuleHeadSection.duplicateChildren) {
                  const duplicateIds = firstModuleHeadSection.duplicateChildren.map(s => s.entityLinkId);
                  let noShortcutsInDuplicate = true;
                  for (const linkId of uniq(duplicateIds)) {
                    const duplicate = firstModuleHeadSection.duplicateChildren
                      .filter(ds => ds.entityLinkId === linkId)
                      .sort(this.utils.comparator('createTime'));
                    if (!duplicate.filter(ds => ds.id !== duplicate[0].id).every(ds => !ds.shortcuts)) {
                      noShortcutsInDuplicate = false;
                      break;
                    }
                  }
                  if (noShortcutsInDuplicate) {
                    for (const linkId of uniq(duplicateIds)) {
                      const duplicate = firstModuleHeadSection.duplicateChildren
                        .filter(ds => ds.entityLinkId === linkId)
                        .sort(this.utils.comparator('createTime'));
                      duplicate.filter(ds => ds.id !== duplicate[0].id)
                        .forEach(hs => {
                          const hSection = eventSections.find(it => it.id === hs.id);
                          const tree = this.utils.getSectionChildrenTree(hSection, eventSections); // tree includes hs
                          tree.forEach(s => {
                            deleteModuleSections.push({
                              id: s.id,
                              createTime: this.utils.formatDate(s.createTime, 'yyyy-MM-dd, HH:mm:ss'),
                              eventId: event.eventId,
                              title: s.title,
                              entityLinkId: s.entityLink.sectionId ?? s.entityLink.entityId,
                              moduleVersion: s.releaseVersion,
                              eventShortLink: event.shortLink,
                              orderIndex: s.orderIndex
                            });
                          });
                        });
                    }
                  }
                }
                deleteModuleSections = deleteModuleSections.sort(this.utils.comparator(Constants.ORDERINDEX, 'desc'));
                for (const ds of deleteModuleSections) {
                  if (!deleteSections.find(s => s.id === ds.id)) {
                    deleteSections.push(ds);
                  }
                }
              }
            }
          }
        });
    };

    let statistics: {
      [eventId: string]:
        {
          eventId: string, eventShortLink: string, eventName: string, createDate: string, endDate: string,
          competency: {
            [competencyId: string]: {
              competencyId: string, competencyTitle: string,
              modules: {
                [moduleId: string]: {
                  activeShortcuts: boolean,
                  duplicateInModulesChildren: boolean,
                  customContentsInModulesChildren: boolean,
                  sections: {
                    sectionId: string,
                    releaseVersion: string,
                    title: string,
                    hasShortcut: boolean,
                    hasCustomContents: boolean,
                    entityLinkId: string,
                    duplicateInChildren: boolean,
                    duplicateChildren: {id, createTimeStr, createTime, title, entityLinkId, shortcuts, orderIndex}[],
                    orderIndex: number,
                    createTimeStr: string,
                    createTime: number,
                    originIdLinks: {idFrom, idTo}[];
                  }[]
                }
              }
            }
          }
        }
    } = {};
    if (!await this.commonService.confirmation(!remove ?
      'Run: Check Available Contents for current clientId' : 'Run: Check and Delete Available Contents for current clientId')) {
      return;
    }
    const deleteSections: {id, eventId, createTime, title, entityLinkId, moduleVersion, eventShortLink, orderIndex}[] = [];
    const customSection: any[] = [];
    const customContents: any[] = [];
    statistics = {};
    console.log(`Client ${this.currentClientId} loading`);
    await runCheckAvailableContents(this.currentClientId);
    console.log(`Client ${this.currentClientId} finished`);
    console.log('Custom sections ', customSection);
    console.log('Custom contents ', customContents);
    console.log(cloneDeep(statistics));
    if (remove) {
      console.log(`Client ${this.currentClientId} delete`);
      console.log(deleteSections);
      // delete sections and contents
      let counter = 0;
      for (const section of deleteSections) {
        const contents: [] = await this.dataService.getSectionContentsPromise(section.eventId, section.id, CONTENT_PATH_TYPE.DEFAULT);
        console.log('delete contents of section ', section.id, contents.map((c: any) => c.id));
        for (const content of contents as AbstractContent[]) {
          console.log('delete content:', content.id);
          await this.contentService.deleteContent(content);
        }
        console.log('delete section ', section.id);
        await this.contentService.deleteContent(null, section.eventId, null, section.id, Constants.CONTENT_TYPE_SECTION);
        console.log('delete sections: ', ++counter, ' of ', deleteSections.length);
      }
      console.log(`Client ${this.currentClientId} finished`);
    }
  }

  async markAsDirtyOldDraftContentsTypesInModules(mark: boolean) {

    const request = (collection: string, size: number, startAt) => {
      let queryFn: QueryFn = q => q.where('type', 'in',
        [Constants.CONTENT_TYPE_QUESTIONNAIRE, Constants.CONTENT_TYPE_TASK_DOCUMENT, Constants.CONTENT_TYPE_MODULAR])
        .orderBy('type').limit(size);
      if (startAt) {
        queryFn = q => q.where('type', 'in',
          [Constants.CONTENT_TYPE_QUESTIONNAIRE, Constants.CONTENT_TYPE_TASK_DOCUMENT, Constants.CONTENT_TYPE_MODULAR])
          .orderBy('type').limit(size).startAfter(startAt);
      }
      return this.afs.collectionGroup(collection, queryFn).get();
    };

    if (!await this.commonService.confirmation('Mark as dirty old contents types')) {
      return;
    }

    const cache: IWriteCache[] = [];
    console.log('Start process');

    const chunkSize = 250;

    console.log('query draft contents.');
    let draftCount = 0;
    let draftContents_ = await firstValueFrom(request('draft_contents', chunkSize, null));
    while (draftContents_.docs.length > 0) {
      draftCount += draftContents_.docs.length;
      console.log('loaded', draftCount);
      for (const doc of draftContents_.docs) {
        cache.push({ref: doc.ref, obj: {dirty: true}});
      }
      draftContents_ = await firstValueFrom(request('draft_contents', chunkSize, draftContents_.docs[draftContents_.docs.length - 1]));
    }
    if (mark) {
      this.commitCache(cache).then(() => {
        console.log('End process.');
        console.log(cache.map(o => o.ref.path).sort());
      });
    } else {
      console.log(cache.map(o => o.ref.path).sort());
    }
  }

  async clientEngagedSessionsStatistics() {
    const to0d = (d: number) => d <= 9 ? `0${d}` : `${d}`;

    const runClientEngagedSessionsStatistics = async (clientId) => {
      let eventsLength = 0;
      let counter = 0;
      const clientSessions: {
        [dateTimeKey: string]: {
          dateTime: number,
          count: number | any
        }
      } = {};
      let clientSessionsTotal = 0;
      let clientSessionsTotalDate = 0;
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events')
        .get())
        .then(async snapshot => {
          const events = snapshot.docs; // .filter(d => !d.data().deleted);
          eventsLength = events.length;
          for (const event of events) {
            console.log('process ', ++counter, ' of ', eventsLength);
            await firstValueFrom(this.afDB.list(`client_data/${clientId}/conference/${event.id}/users/ping`).valueChanges())
              .then(async usersPings => {
                for (const pings of (usersPings ?? [])) {
                  for (const pingTime of Object.values(pings)) {
                    const pingDateTime = new Date(pingTime);
                    const dateTime = new Date(pingDateTime.getFullYear(), pingDateTime.getMonth(), pingDateTime.getDate(), 0).getTime();
                    const dateTimeKey = `${pingDateTime.getFullYear()}${to0d(pingDateTime.getMonth())}${to0d(pingDateTime.getDate())}`;
                    if (isEmpty(clientSessions[dateTimeKey])) {
                      clientSessions[dateTimeKey] = {
                        dateTime: dateTime,
                        count: 1
                      };
                    } else {
                      clientSessions[dateTimeKey].count = clientSessions[dateTimeKey].count + 1;
                    }
                    clientSessionsTotal++;
                    clientSessionsTotalDate = max([clientSessionsTotalDate, dateTime]);
                  }
                }
              });
          }
          Object.values(clientSessions).forEach(it => {
            it.count = firebase.database.ServerValue.increment(it.count);
          });
          await this.afDB.database.ref(`client_data/${clientId}/engaged_sessions`).update(clientSessions);
          await this.afDB.database.ref(`client_data/${clientId}/engaged_sessions_total`).update({
            dateTime: clientSessionsTotalDate,
            count: firebase.database.ServerValue.increment(clientSessionsTotal)
          });
          return;
        });
    };

    let clientIds: string[];
    if (isEmpty(clientIds = await this.confirmationBeforeRun('Run: Create client engaged sessions statistics.'))) {
      return;
    }
    for (const clientId of clientIds) {
      console.log(`Client ${clientId} started`);
      await runClientEngagedSessionsStatistics(clientId);
      console.log(`Client ${clientId} finished`);
    }
    console.log('End process.');
  }

  async updateUserStatistics() {
    if (!await this.commonService.confirmation('Run: Update User Statistics.')) {
      return;
    }

    this.statisticsApiServiceService.updateUsersByCreatedAt()
      .subscribe({
        next: () => {
          console.log('finished!');
        },
        error: (err) => {
          console.error('error', err);
        }
      });
  }

  async updateEventParticipantsStatistics() {
    let clientIds: string[];
    if (isEmpty(clientIds = await this.confirmationBeforeRun('Run: Create users online statistics.'))) {
      return;
    }
    console.log('Start process');
    for (const clientId of clientIds) {
      console.log(`Client ${clientId} started`);
      await updateOnlineStatistics(this.afs, this.afDB, clientId);
      console.log(`Client ${clientId} finished`);
    }
    console.log('End process.');
  }

  async updateEventRegisteredStatistics() {
    let clientIds: string[];
    if (isEmpty(clientIds = await this.confirmationBeforeRun('Run: Create users registered statistics.'))) {
      return;
    }
    console.log('Start process');
    for (const clientId of clientIds) {
      console.log(`Client ${clientId} started`);
      await updateRegisteredStatistics(this.afs, this.afDB, clientId);
      console.log(`Client ${clientId} finished`);
    }
    console.log('End process.');
  }

  async fillFullName(fix = false) {
    let clientIds: string[];
    if (isEmpty(clientIds = await this.confirmationBeforeRun('Run: Fill user full name.'))) {
      return;
    }
    const runClientUsersUpdate = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('app_users', q => q.orderBy('fullName').limit(1000))
        .get())
        .then((users) => {
          let counter = 0;
          const total = users.docs.length;
          for (const doc of users.docs) {
            counter ++;
            if (doc.get('email') && (!doc.get('fullName') || !doc.get('userName'))) {
              const obj: {
                userName?: string,
                fullName?: string
              } = {};
              if (!doc.get('fullName')) {
                obj.fullName = this.utils.extractDefaultDisplayNameFromEmail(doc.get('email'));
              }
              if (!doc.get('userName')) {
                obj.userName = this.utils.extractDefaultDisplayNameFromEmail(doc.get('email'));
              }
              if (fix) {
                cache.push({ref: doc.ref, obj});
              }
              console.log(`User ${counter} of ${total} id=${doc.id}, ${doc.get('email')}, name=${doc.get('userName')}, full=${doc.get('fullName')}, fixed=${this.utils.extractDefaultDisplayNameFromEmail(doc.get('email'))}`, obj);
            } else {
              console.log(`User ${counter} of ${total}`);
            }
          }
        });

    };
    const cache: IWriteCache[] = [];
    for (const clientId of clientIds) {
      console.log(`Client ${clientId} started`);
      await runClientUsersUpdate(clientId);
      console.log(`Client ${clientId} finished`);
    }
    await this.commitCache(cache).finally(() => console.log('End process.'));
  }

  getRadioGroupValue() {
    return (document.querySelector('input[name = sectionsType]:checked') as HTMLInputElement)?.value as any;
  }

  async checkTimelineOrderIndex(sectionsCheckType: 'timeline' | 'education' | 'modules',
                                fixType: 'check-only' | 'fix-lost' | 'fix-order', eventId: string) {
    let confirmationTitle;
    switch (fixType) {
      case 'check-only':
        confirmationTitle = `Run: Check sections of type ${sectionsCheckType}`;
        break;
      case 'fix-lost':
        confirmationTitle = `Run: Fix lost sections of type ${sectionsCheckType}`;
        break;
      case 'fix-order':
        confirmationTitle = `Run: Fix order index of type ${sectionsCheckType}`;
        break;
    }

    const childrenCheckOutOfRange = (section: SectionContent,
                                     sectionsList: SectionContent[], rootChildrenTreeIds: string[],  testsOutOfRange: IIndexTest[]) => {
      const childrenTree = this.timeLineService.childTreeInLineList(section.id).filter(s => s.id !== section.id);
      const childrenTreeIds = childrenTree.map(s => s.id);
      const childrenTreeMaxIndex = max([section.orderIndex, ...childrenTree.map(s => s.orderIndex)]);
      const children = this.utils.getSectionChildrenTree(section, sectionsList).filter(s => s.id !== section.id);
      const warning: IChild[] = children.filter(s => !childrenTreeIds.includes(s.id))
        .map(s => new Object({sectionId: s.id, title: s.title, orderIndex: s.orderIndex, parentId: s.parentId,
          level: this.timeLineService.contentLevel(s.id) + 1}) as IChild);
      if (!isEmpty(warning)) {
        testsOutOfRange.push({
          sectionId: section.id, parentId: section.parentId, title: section.title,
          minIndex: section.orderIndex, maxIndex: childrenTreeMaxIndex,
          level: this.timeLineService.contentLevel(section.id) + 1,
          isLost: !rootChildrenTreeIds.includes(section.id),
          childrenOutOfIndex: warning.sort(this.utils.comparator(Constants.ORDERINDEX))
        });
      }
    };

    const checkLostSections = (section: SectionContent, rootChildrenTreeIds: string[], lostSections: IChild[]) => {
      if (!rootChildrenTreeIds.includes(section.id)) {
        lostSections.push({sectionId: section.id, parentId: section.parentId, title: section.title, orderIndex: section.orderIndex});
      }
    };

    const fixLostSections = async (lostSections: IChild[], sectionsList: SectionContent[],
                                   clientId: string, eventId: string, rootId: string,
                                   sectionsDocs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]) => {
      const maxOrderIndex = max(sectionsList.map(s => s.orderIndex)) + 10000000;
      let parentForLost = sectionsList.find(s => s.title === 'Lost sections');
      if (!parentForLost) {
        const sectionId = this.afs.createId();
        const section = new SectionContent({
          eventId: eventId,
          title: 'Lost sections',
          orderIndex: maxOrderIndex,
          parentId: rootId,
          status: Constants.SECTION_STATUS_DRAFT,
          sectionTimeIsNotActive: true,
          education: sectionsCheckType !== 'timeline'
        });
        parentForLost = await this.afs.collection('client_data').doc(clientId)
          .collection(sectionsCheckType === 'modules' ? 'modules' : 'conference')
          .doc(eventId).collection('timeline').doc(sectionId).set(section.toObject())
          .then(() => firstValueFrom(this.afs.collection('client_data').doc(clientId)
            .collection(sectionsCheckType === 'modules' ? 'modules' : 'conference')
            .doc(eventId).collection('timeline').doc(sectionId).get())
            .then(snap => new SectionContent({id: snap.id, ...snap.data()})));
      }
      if (parentForLost) {
        for (const ls of lostSections) {
          if (!sectionsList.find(s => s.id === ls.parentId)) {
            const doc = sectionsDocs.find(d => d.id === ls.sectionId);
            if (doc) {
              cache.push({ref: doc.ref, obj: {parentId: parentForLost.id}});
            }
          }
        }
      }
    };

    const addToSort = (section: SectionContent, sectionsList: SectionContent[], sortedList: SectionContent[]) => {
      const children = sectionsList.filter(s => s.parentId === section.id)
        .sort(this.utils.comparator(Constants.ORDERINDEX));
      sortedList.push(section);
      for (const chl of children) {
        addToSort(chl, sectionsList, sortedList);
      }
    };

    const fixOrderIndex = (sectionsList: SectionContent[],
                           sectionsDocs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]) => {
      const sortedList: SectionContent[] = [];
      const root = sectionsList.find(s => s.isRoot);
      addToSort(root, sectionsList, sortedList);
      let orderIndex = 100000000;
      for (const s of sortedList.filter(s => !s.isRoot)) {
        const doc = sectionsDocs.find(d => d.id === s.id);
        if (doc) {
          cache.push({ref: doc.ref, obj: {orderIndex: orderIndex}});
          orderIndex += 100000000;
        }
      }
    };

    const runCheckOrderIndex = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection(sectionsCheckType === 'modules' ? 'modules' : 'events').get())
        .then(async (eventsSnapshot) => {
          const eventsDocs = eventsSnapshot.docs.filter(d => !d.get('deleted') && (eventId ? d.id === eventId : true));
          let ecounter = 0;
          const brokenEvents: Event[] = [];
          const crashedEventsId: string[] = [];
          const size = eventsDocs.length;
          for (const eventDoc of eventsDocs) {
            this.timeLineService.resetServiceValues();
            const testsOutOfRange: IIndexTest[] = [];
            const lostSections: IChild[] = [];
            if (!eventDoc.get('shortLink')) {
              crashedEventsId.push(eventDoc.id);
              console.log(`processed skip event ${eventDoc.id} ~~~~~ CRASHED EVENT ~~~~~ ` + (++ecounter) + ' of ' + size);
              continue;
            }
            const event = new Event({eventId: eventDoc.id, ...eventDoc.data()});
            console.log(`processed event ${event.eventId} ~~~~~ ${event.shortLink} ` + (++ecounter) + ' of ' + size);
            const sectionsDocs = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection(sectionsCheckType === 'modules' ? 'modules' : 'conference').doc(event.eventId)
              .collection('timeline', q => q.where('type', '==', Constants.CONTENT_TYPE_SECTION)).get())
              .then(sectionsSnapshot => sectionsSnapshot.docs);
            const sections = sectionsDocs.map(d => new SectionContent({id: d.id, ...d.data()}))
              .filter(s => ((sectionsCheckType === 'timeline' ? !s.education : !!s.education) && !s.fixedSectionType) || !s.parentId)
              .sort(this.utils.comparator(Constants.ORDERINDEX));

            event.managers[this.loginService.getAppUser().userId] = this.loginService.getAppUser() as IManager;
            this.timeLineService._event$.next({loaded: LOAD_STATE.LOADED, value: event});
            const root = sections.find(s => s.isRoot);
            this.timeLineService._rootSection$$.next({loaded: LOAD_STATE.LOADED, value: root});
            this.timeLineService._sections$.next({loaded: LOAD_STATE.LOADED, value: sections});
            this.timeLineService.refreshTimeline();

            const rootChildrenTreeIds = this.utils.getSectionChildrenTree(root, sections).map(s => s.id);
            for (const s of sections.filter(s => !s.isRoot)) {
              childrenCheckOutOfRange(s, sections, rootChildrenTreeIds, testsOutOfRange);
              checkLostSections(s, rootChildrenTreeIds, lostSections);
            }

            this.timeLineService.resetServiceValues();
            if (!isEmpty(testsOutOfRange) || !isEmpty(lostSections)) {
              brokenEvents.push(event);
              console.log(`%c~~~~~ check result ${event.eventId} ~~~~~ ${event.shortLink} ~~~~~ broken`, 'color: brown');
              console.log('rootId: ', root.id);
              console.log('~~~~~ all sections ~~~~~');
              console.log(testsOutOfRange);
              console.log('~~~~~ lost out of range sections ~~~~~');
              console.log(testsOutOfRange.filter(o => o.isLost));
              console.log('~~~~~ lost sections ~~~~~');
              console.log(lostSections);
              console.log('~~~~~ alive out of range sections ~~~~~');
              console.log(testsOutOfRange.filter(o => !o.isLost));

              if (fixType === 'fix-lost' && !isEmpty(lostSections)) {
                console.log('~~~~~ fix lost sections ~~~~~');
                await fixLostSections(lostSections, sections, clientId, event.eventId, root.id, sectionsDocs);
              }
              if (fixType === 'fix-order' && !isEmpty(testsOutOfRange)) {
                console.log('~~~~~ fix sections order index ~~~~~');
                fixOrderIndex(sections, sectionsDocs);
              }
            } else {
              console.log(`~~~~~ check result ${event.eventId} ~~~~~ ${event.shortLink} ~~~~~ Ok`);
            }
          }
          if (!isEmpty(brokenEvents)) {
            console.log(`%c~~~~~ broken events ~~~~~`, 'color: red');
            console.log(brokenEvents);
          }
          if (!isEmpty(crashedEventsId)) {
            console.log(`%c~~~~~ crashed events id ~~~~~`, 'color: red');
            console.log(crashedEventsId);
          }
        });
    };

    let cache: IWriteCache[] = [];
    let clientIds: string[];
    if (isEmpty(clientIds = await this.confirmationBeforeRun(confirmationTitle, this.prevChoice))) {
      return;
    }
    this.prevChoice = clientIds.reduce((acc, id) => {
      acc[id] = true;
      return acc;
    }, {});
    for (const clientId of clientIds) {
      console.log(`%cClient ${clientId} started`, 'color: blueviolet');
      cache = [];
      await runCheckOrderIndex(clientId);
      console.log(`%cClient ${clientId} finished`, 'color: blue');
      if (fixType !== 'check-only') {
        await this.commitCache(cache)
          .then(() => console.log(`%ccommit finished for ${clientId}`, 'color: orange'));
      }
    }
  }

  createEventsLastActivityStatistics() {
    createEventsLastActivityStatistics(this);
  }

  createEventsSelfLearningModulesStatistics() {
    createEventsSelfLearningModulesStatistics(this);
  }

  createEventsSelfLearningModulesActivityStatistics() {
    createEventsSelfLearningModulesActivityStatistics(this);
  }
}
